import { Percent } from "@mui/icons-material";
import { IconButton, InputBaseComponentProps, TextField, Tooltip } from "@mui/material";
import { useEffect, useRef } from 'react';
import { NumericFormat } from "react-number-format";
import { limpaRef, limpaString } from "utils/util";
import { TipoDesconto } from "./registra-venda.model";

type DescontoInputProps = {
    item: {
        tipoDesconto: TipoDesconto;
        valorDesconto: number;
        percentualDesconto: number;
        valorTotalBruto: number;
        uuid: string;
        onChangeDesconto: (value: number, uuid: string) => void,
    };
    style?: React.CSSProperties | undefined;
    inputProps: InputBaseComponentProps;
    iconButtonProps: {
        disabled?: boolean;
        onToggleTipoDesconto: (uuid: string) => void,
        percentoProps: {
            color: string;
        },
        valorProps: {
            color: string;
        };
    };
    hotKey?: string;
    onBlur: (value: number, uuid: string) => void;
    onSaveTempDesconto: (value: number, uuid: string) => void;
    isVisualizar?: boolean;
    origem: 'FOOTER' | 'ROW' | 'ROW_TROCA';
};

const DescontoInput = (props: DescontoInputProps) => {
    const descontoRef = useRef<HTMLInputElement>(null);

    const onFocusDesconto = () => {
        if (descontoRef && descontoRef.current?.value) {
            props.onSaveTempDesconto(Number(descontoRef.current.value.replace(",", ".")), props.item.uuid);
            if (Number(limpaRef(descontoRef.current!.value)) === 0) {
                return descontoRef.current?.setSelectionRange(1, 1);
            }
            else if (Number(limpaString(descontoRef.current!.value)) === 0) {
                return descontoRef.current?.setSelectionRange(4, 4);
            }
        }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        switch (e.key) {
            case props.hotKey:
                if (descontoRef.current) {
                    e.preventDefault();
                    descontoRef.current.focus();
                }
                break;
        }
    };

    const isDisableTooltip = () => {
        return props.isVisualizar || (props.origem !== 'ROW' && props.origem !== 'ROW_TROCA');
    };

    useEffect(() => {
        if (props.hotKey) {
            window.addEventListener('keydown', handleKeyPress);
            return () => {
                window.removeEventListener('keydown', handleKeyPress);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tooltip
            disableHoverListener={isDisableTooltip()}
            disableTouchListener={isDisableTooltip()}
            disableFocusListener={isDisableTooltip()}
            disableInteractive={isDisableTooltip()}
            title="O valor do desconto não pode ser igual ou superior ao valor total dos itens" followCursor>
            <NumericFormat
                decimalScale={2}
                inputRef={descontoRef}
                onFocus={onFocusDesconto}
                onBlur={props.onBlur.bind(null, props.item.percentualDesconto, props.item.uuid)}
                fixedDecimalScale
                defaultValue={0}
                style={props.style}
                allowedDecimalSeparators={[',']}
                customInput={TextField}
                decimalSeparator=","
                prefix={props.item.tipoDesconto === TipoDesconto.PERCENTUAL ? '' : 'R$ '}
                thousandsGroupStyle="thousand"
                thousandSeparator="."
                variant="standard"
                disabled={props.iconButtonProps.disabled || props.isVisualizar}
                allowNegative={false}
                isAllowed={({ floatValue }) => {
                    if (!floatValue) return true;
                    if (props.item.tipoDesconto === TipoDesconto.PERCENTUAL) {
                        return floatValue < 100;
                    }
                    return floatValue < props.item.valorTotalBruto;
                }}
                inputProps={props.inputProps}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            disabled={props.iconButtonProps.disabled || props.isVisualizar}
                            onClick={() => { props.iconButtonProps.onToggleTipoDesconto(props.item.uuid); }}
                        >
                            {
                                props.item.tipoDesconto === TipoDesconto.PERCENTUAL
                                    ? <Percent style={{ color: !props.isVisualizar ? props.iconButtonProps.percentoProps.color : '#9F9F9F' }} />
                                    : <i className="fa-solid fa-money-bill-1-wave" style={{ color: props.iconButtonProps.valorProps.color }} />
                            }
                        </IconButton>
                    )
                }}
                value={
                    props.item.tipoDesconto === TipoDesconto.PERCENTUAL
                        ? props.item.percentualDesconto
                        : props.item.valorDesconto
                }
                onValueChange={({ floatValue }, { source }) => {
                    if (source === 'event') {
                        props.item.onChangeDesconto(floatValue ?? 0, props.item.uuid);
                    }
                }}
            />
        </Tooltip>
    );
};

export default DescontoInput;
