import { Grid, Typography, Divider, Icon, Box } from "@mui/material";
import { CustomButton } from "components/roundedbutton/CustomButton";
import HeaderOuBannerDependendoDaInterpretacaoH1 from "layout/header-ou-banner-dependendo-da-interpretacao-h1/HeaderOuBannerDependendoDaInterpretacaoH1";
import { CustomSwitch } from "pages/cadastro-pessoa/CadastroPessoa";
import { useCallback, useEffect, useRef, useState } from "react";
import { ConfiguracoesTitle } from "./Configuracoes.model";
import ConfiguracaoDescontoService from "./Configuracoes.service";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { FlowConfiguracaoPoliticaVendaDTO } from "model/abstract.model";
import CustomPoliticaVenda from "pages/Venda/CustomPoliticaVenda";
import { useAppSelector } from "redux/hooks";

const initialState = {
    obrigaNsu: false as boolean,
    obrigaNumeroControle: false as boolean
}

const ConfigurarPoliticaVenda = (props: any) => {
    const configuracaoService = ConfiguracaoDescontoService();
    const [stateLocal, setStateLocal] = useState(initialState)
    const { addError, addSuccess } = useSnackbarCustom()
    const isFirstRenderRef = useRef<boolean>(true)
    const estabelecimentoSelecionado = useAppSelector(e => e.estabelecimentoSelecionado)

    const onChangeNSU = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, obrigaNsu: !prevState.obrigaNsu }))
    }, [])

    const onChangeNumeroControle = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, obrigaNumeroControle: !prevState.obrigaNumeroControle }))
    }, [])

    const getConfiguracaoPoliticaVenda = useCallback(async () => {
        try {
            const { data: FlowConfiguracaoPoliticaVendaDTO } = await configuracaoService.getConfiguracaoPoliticaVenda()
            if (FlowConfiguracaoPoliticaVendaDTO) {
                setStateLocal(prevState => ({
                    ...prevState,
                    obrigaNsu: FlowConfiguracaoPoliticaVendaDTO.obrigaNsu,
                    obrigaNumeroControle: FlowConfiguracaoPoliticaVendaDTO.obrigaNumeroControle
                }))
            }
        } catch (err) {
            addError({ message: "Não foi possível carregar suas configurações de política de venda", closeable: true })
        }
        //eslint-disable-next-line
    }, [estabelecimentoSelecionado.uuid])

    const updateConfiguracaoPoliticaVenda = useCallback(async (obrigaNsu: boolean, obrigaNumeroControle: boolean) => {
        const toSave: FlowConfiguracaoPoliticaVendaDTO = {
            obrigaNsu,
            obrigaNumeroControle
        }
        try {
            const { data } = await configuracaoService.updateConfiguracaoPoliticaVenda(toSave)
            if (data) {
                addSuccess({ message: 'configuração política de venda atualizada com sucesso.', closeable: false })
                props.onGoBack()
            }
        } catch (err) {
            addError({ message: "Não foi possivel atualizar suas configuracoes de política de venda", closeable: true })
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isFirstRenderRef.current) getConfiguracaoPoliticaVenda()
        isFirstRenderRef.current = false
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        getConfiguracaoPoliticaVenda()
        //eslint-disable-next-line
    }, [estabelecimentoSelecionado])

    return (
        <>
            <HeaderOuBannerDependendoDaInterpretacaoH1
                id='configurar_politica_venda_header_or_banner'
                customIcon={
                    <CustomPoliticaVenda
                        id="configurar_politica_venda_icon_header"
                        color="#666666"
                        fontSize="60px"
                        style={{ marginBottom: '5px' }}
                    />
                }
                label={ConfiguracoesTitle.CONFIGURACAO_POLITICA_VENDA}
            />

            <Grid
                container
                spacing={2}
                alignContent={'flex-start'}
                sx={{
                    height: '85%',
                    position: 'relative'
                }}
            >
                <Grid item xs={12} >
                    <Typography
                        sx={{
                            fontSize: 22,
                            marginBottom: '10px'
                        }}
                    >
                        Configuração obrigatoriedade cartão
                    </Typography>
                    <Divider />
                </Grid>



                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                color: '#666'
                            }}
                        >
                            Obrigar informar NSU
                        </Typography>

                        <CustomSwitch
                            id='permite-parcial-input'
                            checked={stateLocal.obrigaNsu}
                            onChange={onChangeNSU}
                            name="permite-parcial" />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: '20px'
                        }}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                color: '#666'
                            }}
                        >
                            Obrigar informar número de controle
                        </Typography>

                        <CustomSwitch
                            id='permite-parcial-input'
                            checked={stateLocal.obrigaNumeroControle}
                            onChange={onChangeNumeroControle}
                            name="permite-parcial" />
                    </Box>

                </Grid>



                <Grid item
                    sx={{
                        position: 'absolute',
                        bottom: '15px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <CustomButton
                        sx={{ minWidth: '80px' }}
                        id="voltar-configuracao-vale-presente-btn"
                        onClick={props.onGoBack}
                        variant="contained"
                        color="error"
                        startIcon={<Icon className='fa-solid fa-arrow-left' />}>
                        Voltar
                    </CustomButton>

                    <CustomButton
                        sx={{ minWidth: '80px' }}
                        id="voltar-configuracao-vale-presente-btn"
                        onClick={() => updateConfiguracaoPoliticaVenda(stateLocal.obrigaNsu, stateLocal.obrigaNumeroControle)}
                        variant="contained"
                        color="success"
                        startIcon={<Icon className='fa-solid fa-check' />}>
                        Salvar
                    </CustomButton>
                </Grid>
            </Grid>
        </>
    )
}

export default ConfigurarPoliticaVenda