import { AppBar, Avatar, Box, Container, DialogActions, DialogTitle, FormControl, Icon, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableContainer, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect';
import DialogContext from 'components/confirm-dialog-context';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import SideMenu from 'components/sidemenu/SideMenu';
import { IAbstractModel } from 'model/abstract.model';
import { IProduto, IProdutoRow } from 'pages/registra-venda/registra-venda.model';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetLoading } from 'redux/features/blockuiSlice';
import { resetCaixa, setCaixa } from 'redux/features/caixaSlice';
import { resetConfiguracoes, setConfiguracaoPedidoBalcao, setDeposito, setDepositoPadrao, setTabelaPreco, setTabelaPrecoPadrao } from 'redux/features/configuracoes';
import { resetDesconto } from 'redux/features/descontoFixoSlice';
import { resetEsSelecionado, setEsSelecionado } from 'redux/features/esSelecionadoSlice';
import { resetChavesReferenciadas } from 'redux/features/notaReferenciadaSlice';
import { resetPagamento } from 'redux/features/pagamentoSlice';
import { atualizaValoresItensPedido, changeTabelaPrecoPadraoPedido, resetSkusConsultados, resetState, setProdutos } from 'redux/features/stateSlice';
import { resetStepper } from 'redux/features/stepperSlice';
import { resetToken } from 'redux/features/tokenSlice';
import { resetValePresenteState } from 'redux/features/valePresenteSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import useCustomLocation from 'utils/useCustomLocation';
import { deepCopy, sortArray } from 'utils/util';
import peonFlowImage from '../../assets/peonFlowLogo.svg';
import './topBar.css';
import { ConfiguracaoPedidoBalcaoTO } from './topBar.model';
import topBarService from './topbar.service';
import useCatchApiError from 'hooks/useCatchApiError';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { axiosRequest } from '../../../src/axios/axios.config';
import LoginService from "pages/login/login.service"
import CaixaService from 'pages/Caixa/caixa.service';

type topBarProps = {
    showConfiguracoes: boolean;
    onOpen: () => void;
    onCloseConfiguracoesMenu: () => void;
};

type initialStateProps = {
    showConfirmaExclusao: boolean,
    produtosParaExcluir: Array<string>,
    tabelaPrecoTemp: IAbstractModel | null,
    data: Array<IProduto>,
    length: number;
};

const initialState: initialStateProps = {
    data: [],
    length: 0,
    produtosParaExcluir: [],
    showConfirmaExclusao: false,
    tabelaPrecoTemp: null
};

const Topbar = (props: topBarProps) => {
    const token = useAppSelector((state) => state.token);
    const dispatch = useAppDispatch();
    const dialog = useContext(DialogContext);
    const service = topBarService();

    const settings = [
        {
            label: 'Atendimento',
            action: () => { },
        },
        {
            label: 'Sair',
            action: () => {
                handleLogout();
            },
        },
    ];

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const { quantidadeTotal, itens, itensTroca } = useAppSelector(e => e.state.pedido);
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);
    const configuracaoPedidoBalcao = useAppSelector(e => e.configuracoes.configuracaoPedidoBalcao);
    const tabelaPrecoRef = useRef<IAbstractModel | null>(configuracaoPedidoBalcao.tabelaPreco);
    const depositoRef = useRef<IAbstractModel | null>(configuracaoPedidoBalcao.deposito);
    const pedido = useAppSelector(e => e.state.pedido);
    const location = useLocation();
    const {
        isTelaPreVenda,
        isTelaRegistraVenda
    } = useCustomLocation();
    const { throwErrorMessage } = useCatchApiError();
    const { addError, closeAllSnacks } = useSnackbarCustom();
    const loginService = LoginService()
    const caixaService = CaixaService()

    useEffect(() => {
        closeAllSnacks()
        axiosRequest.get(`/flow/existeconfiguracao`, { headers: loginService.getHeaders() })
            .then(() => {
                setStateLocal(prevState => ({ ...prevState, error: false }));
                caixaService.getCaixas()
                    .then(({ data }) => {
                        const caixa = data.find(e => e.caixaAberto && e.operadorAtual === token.USER_INFO?.username);
                        if (!!caixa) {
                            caixaService.getSaldoConta(caixa.uuid)
                                .then(({ data }) => {
                                    dispatch(setCaixa({
                                        caixa: data.caixa,
                                        contaSelecionada: null,
                                        saldo: data.saldo,
                                        dataAbertura: data.dataAbertura,
                                        caixaAberto: data.caixaAberto
                                    }));
                                })
                                .catch(() => {
                                    addError({ message: 'Falha ao pegar o saldo da conta informada.', closeable: true });
                                });
                        }
                        else {
                            dispatch(resetCaixa());
                        }
                    }).catch(() => {
                        addError({ message: 'Falha ao pegar caixas disponiveis.', closeable: true });
                    });
            }).catch((error: any) => {
                setStateLocal(prevState => ({ ...prevState, error: true }));
                dispatch(resetCaixa());
                throwErrorMessage(error)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estabelecimento.uuid]);

    useEffect(() => {
        service.getConfiguracao()
            .then(({ data }: { data: ConfiguracaoPedidoBalcaoTO; }) => {
                dispatch(setConfiguracaoPedidoBalcao(data));
                dispatch(setTabelaPrecoPadrao(data.tabelaPreco));
                dispatch(setDepositoPadrao(data.deposito));
            })
            .catch(() => { });
        //eslint-disable-next-line
    }, [estabelecimento.uuid]);

    useEffect(() => {
        tabelaPrecoRef.current = configuracaoPedidoBalcao.tabelaPreco;
        if (itens || itensTroca) {
            dispatch(changeTabelaPrecoPadraoPedido(configuracaoPedidoBalcao.tabelaPreco));
        }
        //eslint-disable-next-line
    }, [configuracaoPedidoBalcao.tabelaPreco]);

    useEffect(() => {
        depositoRef.current = configuracaoPedidoBalcao.deposito;
        //eslint-disable-next-line
    }, [configuracaoPedidoBalcao.deposito]);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        dispatch(resetToken());
        dispatch(resetEsSelecionado());
        dispatch(resetState());
        dispatch(resetDesconto());
        dispatch(resetStepper());
        dispatch(resetPagamento());
        dispatch(resetLoading());
        dispatch(resetCaixa());
        dispatch(resetConfiguracoes());
        dispatch(resetValePresenteState());
        dispatch(resetChavesReferenciadas());
        navigate('/login');
    };

    const limpaTudo = (value: string) => {
        const found = token.ESTABELECIMENTOS.find((estabelecimento) => estabelecimento.uuid === value)
        if (found) dispatch(setEsSelecionado(found));
        dispatch(resetState());
        dispatch(resetDesconto());
        dispatch(resetPagamento());
        dispatch(resetCaixa());
        dispatch(resetChavesReferenciadas());
    };

    const handleEstabelecimentoSelecionado = (event: SelectChangeEvent) => {
        if (estabelecimento.uuid !== event.target.value) {
            if (quantidadeTotal === 0) {
                return limpaTudo(event.target.value);
            }
            const novoEstabelecimentoDescritivo = token.ESTABELECIMENTOS.filter(e => e.uuid === event.target.value)[0].descritivo;
            return dialog.confirm({
                title: 'Atenção',
                message: `Deseja realmente trocar para o estabelecimento ${novoEstabelecimentoDescritivo}? Todos os dados informados serão perdidos`,
                onConfirm: () => {
                    limpaTudo(event.target.value);
                }
            });
        }
    };

    const toggleConfiguracoes = () => {
        if ((isTelaRegistraVenda() || isTelaPreVenda())) {
            if ((isTelaRegistraVenda() || isTelaPreVenda())) {
                if (props.showConfiguracoes) return props.onCloseConfiguracoesMenu();
                return props.onOpen();
            }
        };
    };

    useEffect(() => {
        if ((!isTelaRegistraVenda() || !isTelaPreVenda()) && props.showConfiguracoes) {
            props.onCloseConfiguracoesMenu();
        }
        //eslint-disable-next-line
    }, [location]);

    const atualizaTabelaPreco = useCallback((tabelaPreco: IAbstractModel) => {
        dispatch(setTabelaPreco(tabelaPreco));
        //eslint-disable-next-line
    }, []);

    const [stateLocal, setStateLocal] = useState<initialStateProps>(initialState);

    const atualizarOuRemoverItensPedido = (tabelaPreco: IAbstractModel, itens: Array<IProdutoRow>, itensTroca: Array<IProdutoRow>) => {
        const array: Array<string> = [];

        itens.forEach(e => {
            if (!array.includes(e.produto.codigoSku)) {
                array.push(e.produto.codigoSku);
            }
        });
        itensTroca.forEach(e => {
            if (!array.includes(e.produto.codigoSku)) {
                array.push(e.produto.codigoSku);
            }
        });

        service.revalidaSkus(array, tabelaPreco.uuid)
            .then(({ data }: { data: Array<IProduto>; }) => {
                const skusPerdidos: Array<string> = array.flatMap(e => data.filter(el => el === null).map(_ => e));
                const produtosParaExcluir: Array<string> = [];

                skusPerdidos.forEach(e => {
                    pedido.itens.forEach(el => {
                        const descricaoProduto = `${el.produto.codigoSku} - ${el.produto.nome} / ${el.produto.cor} / ${el.produto.tamanho}`;
                        if (el.produto.codigoSku === e && !produtosParaExcluir.includes(descricaoProduto)) {
                            produtosParaExcluir.push(descricaoProduto);
                        }
                    });
                });

                if (skusPerdidos.length) {
                    setStateLocal(prevState => ({
                        ...prevState,
                        showConfirmaExclusao: true,
                        produtosParaExcluir: produtosParaExcluir,
                        tabelaPrecoTemp: tabelaPreco,
                        data: data,
                        length: skusPerdidos.length
                    }));
                }
                else {
                    atualizaTabelaPreco(tabelaPreco); // setando tabela Preco;
                    dispatch(atualizaValoresItensPedido(data)); // atualizando os novos valores no pedido
                }
            })
            .catch(() => { });
    };

    const onSelectDeposito = (depositoUuid: string) => {
        const deposito = configuracaoPedidoBalcao.depositos.find(item => item.uuid === depositoUuid)!;
        const revalidateItens = deepCopy(itens);
        revalidateItens.forEach(e => e.estoque = -1);
        dispatch(setDeposito(deposito));
        dispatch(resetSkusConsultados());
        dispatch(setProdutos(revalidateItens));
    };

    const onSelectTabelaPreco = useCallback((tabelaPrecoUuid: string) => {
        const tabelaPreco = configuracaoPedidoBalcao.tabelasPreco.find(s => s.uuid === tabelaPrecoUuid)!;

        if ((itens.length || itensTroca.length) && tabelaPrecoRef.current?.uuid !== tabelaPrecoUuid) {
            return dialog.confirm({
                title: 'Atenção',
                message: `O valor dos produtos serão atualizados de acordo com a tabela de preço informada e, caso não haja preço na nova tabela, os produtos serão removidos do pedido. Deseja continuar?`,
                onConfirm: () => {
                    atualizarOuRemoverItensPedido(tabelaPreco, itens, itensTroca);
                },
                onCancel: () => {
                    props.onCloseConfiguracoesMenu();
                }
            });
        }
        atualizaTabelaPreco(tabelaPreco);
        //eslint-disable-next-line
    }, [configuracaoPedidoBalcao, itens, itensTroca]);

    const onCloseModalSkus = () => {
        setStateLocal(prevState => ({
            ...prevState,
            data: initialState.data,
            length: initialState.length,
            produtosParaExcluir: initialState.produtosParaExcluir,
            showConfirmaExclusao: initialState.showConfirmaExclusao,
            tabelaPrecoTemp: initialState.tabelaPrecoTemp
        }));
    };

    const onConfirmModalSkus = () => {
        dispatch(atualizaValoresItensPedido(stateLocal.data)); // atualizando os novos valores no pedido
        atualizaTabelaPreco(stateLocal.tabelaPrecoTemp!); // setando tabela Preco;
        onCloseModalSkus();
        props.onCloseConfiguracoesMenu();
    };


    return (
        <>
            <AppBar position="static"
                style={{
                    background: 'var(--bg)',
                }}>
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, height: '64px', display: 'flex', alignItems: 'center', gap: '10px', userSelect: 'none' }}>
                            <button
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate('/registra-venda')}
                            >
                                <img
                                    src="flow-icon-white-512.png"
                                    alt="flow-logo-icon"
                                    style={{
                                        width: '15px',
                                        maxHeight: '100%',
                                    }}
                                />
                            </button>
                            <button
                                id='peonFlowButton'
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate('/registra-venda')}
                            >
                                <img
                                    id='peonFlowImage'
                                    src={peonFlowImage}
                                    alt="Background"
                                    style={{
                                        width: '150px',
                                        maxHeight: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </button>

                        </Box>

                        <Box
                            sx={{
                                flexGrow: 0,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '15px',
                                '.MuiInput-underline::before': {
                                    borderBottomColor: '#FFEBDA !important',
                                },
                                '.MuiInput-underline::after': {
                                    borderBottomColor: '#FFEBDA !important',
                                },
                            }}>
                            <Box>
                                <FormControl variant="standard" sx={{ mb: 2 }}>
                                    <InputLabel htmlFor="estabelecimentos" />
                                    <Select variant='standard'
                                        sx={{
                                            color: '#FFEBDA',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            fontFamily: 'Work Sans',
                                            ".MuiSelect-iconStandard": {
                                                fill: '#FFEBDA'
                                            },

                                        }}
                                        value={estabelecimento.uuid}
                                        id="SelecionaEstabelecimento"
                                        inputProps={{
                                            name: 'estabelecimentos',
                                            id: 'estabelecimentos',
                                        }}
                                        onChange={handleEstabelecimentoSelecionado}
                                    >
                                        {token.ESTABELECIMENTOS.map((estabelecimento, index) =>
                                            <MenuItem value={estabelecimento.uuid} id={'estabelecimento' + index} key={estabelecimento.uuid}>
                                                {estabelecimento.descritivo}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box>
                                <Tooltip title="Configurações">
                                    <IconButton onClick={toggleConfiguracoes} sx={{ mr: "10px", mt: "6px" }}>
                                        <Icon className='fa-solid fa-bars' sx={{ color: "#FFEBDA" }} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Opções">

                                    <>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar id="user_image" alt={`Foto ${token.USER_INFO?.nome ?? 'usuário'}`} src={token.USER_INFO?.avatar ?? '/static/images/avatar/1.jpg'} />
                                        </IconButton>
                                        {props.showConfiguracoes &&
                                            <Box
                                                sx={{
                                                    borderRadius: '3px',
                                                    background: '#fff',
                                                    position: 'absolute',
                                                    top: '60px',
                                                    right: '0',
                                                    width: '100%',
                                                    maxWidth: '400px',
                                                    height: 'auto',
                                                    zIndex: 102,
                                                    padding: '20px',
                                                    boxShadow: '1px 1px 5px 2px rgba(0,0,0,.06)'
                                                }}
                                            >
                                                <CustomSelect
                                                    id='tabela-preco'
                                                    label='Tabela preço'
                                                    onSelect={(e) => onSelectTabelaPreco(e as string)}
                                                    optionLabel={'descritivo'}
                                                    optionValue={'uuid'}
                                                    options={sortArray(configuracaoPedidoBalcao.tabelasPreco, { fieldToSort: 'descritivo' })}
                                                    value={tabelaPrecoRef.current?.uuid}
                                                />

                                                <CustomSelect
                                                    id='deposito'
                                                    label='Depósito'
                                                    onSelect={(e) => onSelectDeposito(e as string)}
                                                    optionLabel={'descritivo'}
                                                    optionValue={'uuid'}
                                                    options={sortArray(configuracaoPedidoBalcao.depositos, { fieldToSort: 'descritivo' })}
                                                    value={configuracaoPedidoBalcao.deposito.uuid}
                                                    marginBottom='0'
                                                />

                                            </Box>
                                        }
                                    </>
                                </Tooltip>

                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {
                                        settings.map((setting) => (
                                            <MenuItem key={setting.label} onClick={setting.action}>
                                                <Typography textAlign="center">{setting.label}</Typography>
                                            </MenuItem>
                                        ))
                                    }
                                </Menu >

                            </Box >
                        </Box >
                    </Toolbar >
                </Container >
            </AppBar >
            <SideMenu />
            {
                stateLocal.showConfirmaExclusao &&
                <CustomDialog open={true} fullWidth onClose={onCloseModalSkus}>
                    <DialogTitle sx={{ border: 0, margin: 0, padding: 0 }} align='center'>
                        PRODUTOS NÃO ENCONTRADO NA TABELA
                    </DialogTitle>
                    <DialogTitle sx={{ border: 0, margin: 0, padding: 0, fontWeight: 'bold' }} align='center'>
                        {`${stateLocal.tabelaPrecoTemp?.descritivo}`}
                    </DialogTitle>
                    <TableContainer id="modal-show-confirmacao-exclusao-table-container"
                        sx={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: 1,
                            position: 'relative',
                            boxSizing: 'border-box',
                            maxHeight: '400px',
                            padding: '5px'
                        }}
                        className="color-orange">
                        <Table id="modal-show-confirmacao-exclusao-table">
                            <TableBody sx={{ textAlign: 'center' }} id="modal-show-confirmacao-exclusao-table-body">
                                {stateLocal.produtosParaExcluir.map((e, index) => {
                                    return (
                                        <TableRow sx={{ color: '#000' }} id={`modal-show-confirmacao-exclusao-table-row_${index}`}>
                                            {e}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DialogActions
                        sx={{
                            justifyContent: 'space-between', marginTop: '20px'
                        }}>
                        <CustomButton onClick={onCloseModalSkus} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                            Cancelar
                        </CustomButton>
                        <CustomButton onClick={onConfirmModalSkus} id="confirm_button" type="submit" variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                            Confirmar
                        </CustomButton>
                    </DialogActions>
                </CustomDialog>
            }
        </>
    );
}

export default Topbar;
