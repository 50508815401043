import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, AutocompleteInputChangeReason, Checkbox, Chip, DialogActions, DialogContent, DialogTitle, Grid, Icon, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import CustomDialog from "components/custom/CustomDialog";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { IAbstractModel } from "model/abstract.model";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { setOutrosVendedores } from 'redux/features/stateSlice';
import { useAppDispatch, useAppSelector } from "redux/hooks";
import RegistraVendaService from "./registra-venda.service";
import useCatchApiError from 'hooks/useCatchApiError';

type TOutrosComissionadosProps = {
    onClose: () => void;
};

export interface FlowVendedorDTO extends IAbstractModel {
    outrosComissionados?: string[];
    tipoAdicao?: TipoAdicaoVendedor;
}

export enum TipoAdicaoVendedor {
    REPRESENTANTE = 'REPRESENTANTE',
    OUTRO_COMISSIONADO = 'OUTRO_COMISSIONADO',
    MANUAL = 'MANUAL',
}

type TOutrosComissionadosInitialState = {
    vendedoresSelected: Array<FlowVendedorDTO>,
    vendedoresOptions: Array<FlowVendedorDTO>;
};

const initialState: TOutrosComissionadosInitialState = {
    vendedoresSelected: [],
    vendedoresOptions: []
};

const OutrosComissionados = (props: TOutrosComissionadosProps) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const service = RegistraVendaService();
    const { vendedorPrincipal, outrosVendedores } = useAppSelector(e => e.state.pedido);
    const dispatch = useAppDispatch();
    const { closeAllSnacks } = useSnackbarCustom();
    const { throwErrorMessage } = useCatchApiError()

    const getOptions = (option: string, reason: AutocompleteInputChangeReason | null) => {
        closeAllSnacks()
        if (reason && reason === "clear") return setStateLocal(prevState => ({ ...prevState, vendedoresSelected: [] }));

        service.getVendedoresAc(option)
            .then(({ data }) => {
                const result = data.filter(option => vendedorPrincipal?.uuid !== option.uuid);
                setStateLocal(prevState => ({ ...prevState, vendedoresOptions: result }));
            }).catch(error => {
                throwErrorMessage(error)
            });
    };

    useEffect(() => {
        setStateLocal(prevState => ({ ...prevState, vendedoresSelected: outrosVendedores }));
        getOptions("", null);
        //eslint-disable-next-line
    }, []);

    const renderOptions = (option: IAbstractModel) => {
        return (
            <MenuItem
                onClick={() =>
                    setStateLocal(prev => {
                        const vendedoresSelected = !!prev.vendedoresSelected.find(s => s.uuid === option.uuid)
                            ? prev.vendedoresSelected.filter(s => s.uuid !== option.uuid)
                            : [...prev.vendedoresSelected, option];
                        return { ...prev, vendedoresSelected };
                    })
                }
                key={option.uuid}
                value={option.descritivo}>
                <Checkbox
                    id={`item-${option.descritivo?.toLowerCase()}-input`}
                    sx={{
                        '&.MuiCheckbox-root.Mui-checked': {
                            color: 'var(--laranja)'
                        }
                    }}
                    checked={stateLocal.vendedoresSelected.some(e => e.uuid === option.uuid)}
                />
                <ListItemText primary={option.descritivo} />
            </MenuItem>
        );
    };

    const onConfirm = useCallback((e: FormEvent) => {
        e.preventDefault();
        dispatch(setOutrosVendedores(stateLocal.vendedoresSelected));
        props.onClose();
        //eslint-disable-next-line
    }, [stateLocal.vendedoresSelected]);

    const onRemoveChip = useCallback((uuid: string) => {
        setStateLocal(prevState => ({ ...prevState, vendedoresSelected: prevState.vendedoresSelected.filter(e => e.uuid !== uuid) }));
        //eslint-disable-next-line
    }, []);

    return (
        <CustomDialog
            id="cadastro-pessoa-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'lg'}
            onClose={props.onClose}
        >
            <form onSubmit={onConfirm} style={{ gap: 0 }}>
                <DialogTitle id="dialog-title" textAlign={'center'}>
                    <Typography fontSize={30}>Outros comissionados</Typography>
                </DialogTitle>

                <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center', maxHeight: "500px" }}>
                    <Grid container spacing={2}>
                        <Autocomplete
                            sx={{ overflowX: 'hidden', overflowY: 'auto', width: '100%' }}
                            multiple
                            value={stateLocal.vendedoresSelected}
                            options={stateLocal.vendedoresOptions}
                            getOptionLabel={(option) => option.descritivo}
                            onInputChange={(_, value, reason) => getOptions(value, reason)}
                            onChange={(_, value) => setStateLocal(prev => ({ ...prev, vendedoresSelected: value }))}
                            noOptionsText="Nenhum valor encontrado"
                            // getOptionDisabled={(option)=>true}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Vendedores"
                                />)}
                            renderOption={(_, option) => renderOptions(option)}
                            renderTags={(p) => p.map((e, i) => {
                                return (
                                    <Chip
                                        key={e.uuid}
                                        sx={{
                                            color: '#fff',
                                            background: 'var(--laranja)',
                                            marginRight: '3px',
                                        }}
                                        onDelete={() => onRemoveChip(e.uuid)}
                                        label={e.descritivo.split(" - ")[1]}
                                        deleteIcon={<CloseIcon sx={{ color: '#fff !important' }} />}
                                        size='small'
                                    />
                                );
                            })}
                        />
                    </Grid>
                </DialogContent >

                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <CustomButton onClick={props.onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Cancelar
                    </CustomButton>
                    <CustomButton type="submit" disabled={false} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                        Confirmar
                    </CustomButton>
                </DialogActions>
            </form >
        </CustomDialog >
    );
};

export default OutrosComissionados;
