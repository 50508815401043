export enum FlowEventoNotaEmitidaTypeDTO {
  ENTRADA_REJEITADA = "ENTRADA_REJEITADA", // rejeicao direta na entrada
  SAIDA_REJEITADA = "SAIDA_REJEITADA", // rejeicao direta sem troca
  SAIDA_PROCESSAMENTO = "SAIDA_PROCESSAMENTO", // saida em processamento sem troca
  SAIDA_AUTORIZADA = "SAIDA_AUTORIZADA", // saida autorizada sem troca
  ENTRADA_PROCESSAMENTO_SAIDA_PROCESSAMENTO = "ENTRADA_PROCESSAMENTO_SAIDA_PROCESSAMENTO",
  ENTRADA_PROCESSAMENTO_SAIDA_AUTORIZADA = "ENTRADA_PROCESSAMENTO_SAIDA_AUTORIZADA",
  ENTRADA_PROCESSAMENTO_SAIDA_REJEITADA = "ENTRADA_PROCESSAMENTO_SAIDA_REJEITADA",
  ENTRADA_AUTORIZADA_SAIDA_PROCESSAMENTO = "ENTRADA_AUTORIZADA_SAIDA_PROCESSAMENTO",
  ENTRADA_AUTORIZADA_SAIDA_AUTORIZADA = "ENTRADA_AUTORIZADA_SAIDA_AUTORIZADA",
  ENTRADA_AUTORIZADA_SAIDA_REJEITADA = "ENTRADA_AUTORIZADA_SAIDA_REJEITADA",
  ENTRADA_REJEITADA_SAIDA_PROCESSAMENTO = "ENTRADA_REJEITADA_SAIDA_PROCESSAMENTO",
  ENTRADA_REJEITADA_SAIDA_AUTORIZADA = "ENTRADA_REJEITADA_SAIDA_AUTORIZADA",
  ENTRADA_REJEITADA_SAIDA_REJEITADA = "ENTRADA_REJEITADA_SAIDA_REJEITADA",
  SEM_EVENTO = "SEM_EVENTO",
}

export const isQualquerTipoSaidaAutorizada = (
  eventoNotaType: FlowEventoNotaEmitidaTypeDTO
): boolean => {
  return eventoNotaType.includes("SAIDA_AUTORIZADA");
};

export const isQualquerTipoSaidaEmProcessamento = (
  eventoNotaType: FlowEventoNotaEmitidaTypeDTO
): boolean => {
  return eventoNotaType.includes("SAIDA_PROCESSAMENTO");
};

export const isQualquerTipoSaidaRejeitada = (
  eventoNotaType: FlowEventoNotaEmitidaTypeDTO
): boolean => {
  return eventoNotaType.includes("SAIDA_REJEITADA");
};

export const isQualquerTipoEntradaRejeitada = (
  eventoNotaType: FlowEventoNotaEmitidaTypeDTO
): boolean => {
  return eventoNotaType.includes("ENTRADA_REJEITADA");
};

export const possuiRejeicao = (
  eventoNotaType: FlowEventoNotaEmitidaTypeDTO
): boolean => {
  return (
    isQualquerTipoSaidaRejeitada(eventoNotaType) ||
    isQualquerTipoEntradaRejeitada(eventoNotaType)
  );
};

export const shouldShowDialogEventoNota = (
  eventoNotaType: FlowEventoNotaEmitidaTypeDTO
): boolean => {
  return (
    isQualquerTipoSaidaRejeitada(eventoNotaType) ||
    isQualquerTipoEntradaRejeitada(eventoNotaType) ||
    isQualquerTipoSaidaEmProcessamento(eventoNotaType)
  );
};
