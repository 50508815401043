import { useSnackbarCustom } from "./useSnackCustom";

export type Problem = {
	statusCode: number;
	timestamp: Date;
	title: string;
	detail: string;
	userMessage: string;
	objects: any[];
};

const useCatchApiError = () => {
	const { addStack } = useSnackbarCustom();

	return {
		throwErrorMessage: (erro?: { response?: { data?: Problem } }) => {
			const erros: string[] = [];
			const problema = erro?.response?.data;

			if (problema) {
				if (!problema.objects?.length) {
					erros.push(problema.userMessage);
				}
				problema.objects?.forEach((err) => {
					erros.push(err.userMessage ?? err.title ?? err);
				});
			}

			if (!erros.length) {
				erros.push(
					"Ocorreu um erro inesperado. Se o erro persistir, entre em contato com o suporte"
				);
			}

			setTimeout(() => {
				addStack(
					erros.map((message) => ({
						message,
						persist: true,
						severity: "error",
						preventDuplicate: true,
						closeable: true,
						id: message
					}))
				);
			}, 200);
		},
	};
};

export default useCatchApiError;
