import { ColumnsTable } from "utils/models";

export type PropsRelatorioCaixa = {
    state: StateRelatorioCaixa;
    setState: React.Dispatch<React.SetStateAction<StateRelatorioCaixa>>;
    onClose?: () => void;
};

export type StateRelatorioCaixa = {
    loading: boolean;
    open: boolean;
    info: TRelatorioCaixa;
};

export type TRelatorioCaixa = {
    estabelecimento: string;
    hora: string;
    itens: Array<ItemRelatorioCaixa>;
    totalizadores: TotalizadoresRelatorioCaixa;
    totalizadoresFormaPagamento: Array<TotalizadoresFormaPagamentoRelatorioCaixa>;
    caixa: DadosCaixaRelatorioCaixa;
};

export type ItemRelatorioCaixa = {
    pedido: number;
    serie: string;
    numeroNota: number;
    modelo: string;
    cliente: string;
    vendedor: string;
    emissao: Date;
    valorBruto: number;
    troca: number;
    descontoPercentual: number;
    descontoValor: number;
    valorLiquido: number;
    formaPagamento: string;
    infoAdicional?: any;
};

export type TotalizadoresRelatorioCaixa = {
    valorBruto: number;
    troca: number;
    descontoValor: number;
    valorLiquido: number;
};

export type TotalizadoresFormaPagamentoRelatorioCaixa = {
    nome: string;
    valor: number;
};

export type DadosCaixaRelatorioCaixa = {
    saldoInicial: number;
    abertura: number;
    reforco: number;
    sangria: number;
    valePresente: number;
    historicoReforco: Array<DadosSangriaReforco>;
    historicoSangria: Array<DadosSangriaReforco>;
    historicoValePresente: Array<DadosSangriaReforco>;
    saldoFinalDinheiro: number;
};

export type DadosSangriaReforco = {
    descricao: string;
    observacao: string;
    contaApropriacao: string;
    valor: number;
};

export type ColumnsRelatorioPDF<T> = ColumnsTable<T>;
export const initialSateRelatorioCaixa: StateRelatorioCaixa = {
    loading: true,
    open: false,
    info: {
        estabelecimento: '',
        hora: '',
        caixa: {
            saldoInicial: 0,
            abertura: 0,
            reforco: 0,
            sangria: 0,
            valePresente: 0,
            saldoFinalDinheiro: 0,
            historicoReforco: [],
            historicoSangria: [],
            historicoValePresente: []
        },
        itens: [],
        totalizadores: {
            descontoValor: 0,
            troca: 0,
            valorBruto: 0,
            valorLiquido: 0,
        },
        totalizadoresFormaPagamento: []
    }
};
