import {
    GridColDef,
} from '@mui/x-data-grid';

export type TRowConfiguraDesconto = {
    id: number;
    usuario: string;
    descontoMaximoNoItem: string;
    descontoMaximoNoRodape: string;
    autorizaDesconto: boolean,
};

export type TDataGrid = {
    rows: Array<TRowConfiguraDesconto>;
    columns: Array<GridColDef>;
};

export type TConfigurarDescontosProps = {
    dataGridData: TDataGrid;
};


export enum ConfiguracoesTelas {
    TELA_PRINCIPAL = "Tela_principal",
    CONFIGURAR_DESCONTOS = "Configurar_descontos",
    CONFIGURAR_MOVIMENTACOES_FISCAIS = "Configurar_movimentacoes_fiscais",
    CONFIGURAR_COBRANCA_TABELAS_PRECO_E_DEPOSITO = "Configurar_cobranca_tabelas_preco_e_deposito",
    CONFIGURAR_POLITICA_DEVOLUCAO = "Configurar_politica_devolucao",
    CONFIGURAR_POLITICA_VENDA = "Configurar_politica_venda"
}


export type TConfiguracoesInitialState = {
    telaAtiva: ConfiguracoesTelas;
};

export type UsuarioTO = {
    username: string;
    uuid: string;
};

export enum ConfiguracoesTitle {
    DESCONTOS = "Configurar descontos",
    MOVIMENTACOES_FISCAIS = "Configurar movimentações fiscais",
    POLITICA_DEVOLUCAO = "Configurar política de devolução e crédito cliente",
    COBRANCA_TABELAS_PRECO_E_DEPOSITO = "Configurar cobrança", //, tabelas de preço e depósitos", futuramente será implementado,
    CONFIGURACAO_POLITICA_VENDA = "Configurar política de venda"
}

export type rowGrid = {
    id: number;
    uuid: string | null;
    usuario: string;
    descontoMaximoItem: number;
    descontoMaximoPedido: number;
    autorizaDesconto: boolean;
};


export type ConfigurarDescontosInitialState = {
    showAddConfiguracao: boolean,
    rows: Array<rowGrid>,
    usuarios: Array<UsuarioTO>,
    usuariosSelecionados: Array<UsuarioTO>,
    descontoMaximoNoItem: number,
    descontoMaximoPedido: number,
    autorizaDesconto: boolean;
};


export type ConfiguracaoDescontoTO = {
    uuid: string | null;
    usuario: string;
    descontoMaximoItem: number;
    descontoMaximoPedido: number;
    autorizaDesconto: boolean;
};

export enum DescontoMaximo {
    ITEM = "ITEM",
    PEDIDO = "PEDIDO"
}
