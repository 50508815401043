import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { useGenerateUuid } from "hooks/useGenerateUuid";
import { ICaixasDisponivel } from "pages/Caixa/caixa.model";

type TCaixaAutoComplete = {
    caixas: Array<string>;
    caixasOptions: Array<ICaixasDisponivel>;
    onSetCaixas: (value: Array<string>) => void;
    onRemoveAll: () => void;
    onAddAll: () => void;
    onAddUsuario: (caixas: Array<string>) => void;
};


const CaixaAutoComplete = (props: TCaixaAutoComplete) => {
    const [randomUuid] = useGenerateUuid();

    const handleMenuItemClick = (str: string) => {
        if (str.match("SELECIONAR TODOS")) {
            if (props.caixas.length === props.caixasOptions.length) {
                return props.onRemoveAll();
            }
            return props.onAddAll();
        }
        if (!props.caixas.includes(str)) {
            return props.onAddUsuario([...props.caixas, str]);
        }
        return props.onAddUsuario([...props.caixas.filter(e => e !== str)]);
    };

    return (
        <FormControl fullWidth variant="standard" sx={{ width: '40%' }} error={false}>
            <InputLabel id="papel-label" htmlFor="papel-input">Caixa</InputLabel>
            <Select
                id="papel-component"
                multiple
                inputProps={{ id: "papel-input" }}
                value={props.caixas}
                renderValue={(selected) => (
                    <>
                        {selected.map((value: any) => {
                            return (<Chip key={value} sx={{ height: '1.2rem', marginRight: '2px', backgroundColor: 'var(--laranja)', color: '#fff' }} label={value} />
                            );
                        })}
                    </>
                )}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 100
                        },
                    },
                }}
            >
                {props.caixasOptions.length === 0 &&
                    <MenuItem
                        key={randomUuid()}
                        value={"NENHUM CAIXA DISPONIVEL"}
                        disabled
                    >

                        <ListItemText primary={"NENHUM CAIXA DISPONIVEL"} />
                    </MenuItem>
                }
                {props.caixasOptions.length !== 0 &&
                    <MenuItem
                        onClick={handleMenuItemClick.bind(null, "SELECIONAR TODOS")}
                        key={randomUuid()}
                        value={"SELECIONAR TODOS"}>
                        <Checkbox
                            id={"SELECIONAR TODOS"}
                            sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--laranja)'
                                }
                            }}
                            checked={props.caixas.length === props.caixasOptions.length}
                        />
                        <ListItemText primary={"SELECIONAR TODOS"} />
                    </MenuItem>
                }
                {props.caixasOptions.map(p => (
                    <MenuItem
                        key={p.uuid}
                        onClick={handleMenuItemClick.bind(null, p.descritivo)}
                        value={p.descritivo}>
                        <Checkbox
                            id={`item-${p.descritivo}-input`}
                            sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                    color: 'var(--laranja)'
                                }
                            }}
                            checked={props.caixas.includes(p.descritivo)}
                        />
                        <ListItemText primary={p.descritivo} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CaixaAutoComplete;
