import { Box, Button } from "@mui/material"
import { ItemRelatorioVendas } from "./vendas.model";
import { memo } from 'react'

export interface IActionsButtonsProps {
    index: number,
    onClick: (event: React.MouseEvent<HTMLButtonElement>, item: ItemRelatorioVendas) => void;
    item: ItemRelatorioVendas
    isValidButton: (item: ItemRelatorioVendas) => boolean
}
const Ellipsis = (props: IActionsButtonsProps) => {
    return (
        <>
            {!props.isValidButton(props.item) ?
                <Box sx={{ minWidth: 'auto', width: '10px', height: '27px' }}></Box>
                :
                <Button onClick={(e: any) => props.onClick(e, props.item)} sx={{ minWidth: 'auto', width: '10px' }}>
                    <i id={`ellipsis-button-${props.index}`} style={{ width: '10px', color: '#000', fontSize: '15px' }} className="fa-solid fa-ellipsis-vertical"></i>
                </Button>
            }
        </>
    )
}
export default memo(Ellipsis)