import { Percent } from "@mui/icons-material";
import BackspaceIcon from '@mui/icons-material/Backspace';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Autocomplete, Backdrop, Badge, Box, Button, Container, DialogActions, DialogContent, DialogTitle, Icon, IconButton, TableCell, TableFooter, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import CustomToolTips from "components/CustomToolTips";
import InfiniteScrollVirtuoso from "components/InfiniteScrollVirtuoso";
import ItemRegistraVenda from "components/ItemRegistraVenda";
import ItemRegistraVendaTroca from "components/ItemRegistraVendaTroca";
import CustomDialog from 'components/custom/CustomDialog';
import { FloatingActionsProps } from "components/floatingactions/FloatingActions";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useGenerateUuid } from "hooks/useGenerateUuid";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import Footer from "layout/footer/Footer";
import HeaderOuBannerDependendoDaInterpretacaoH1 from "layout/header-ou-banner-dependendo-da-interpretacao-h1/HeaderOuBannerDependendoDaInterpretacaoH1";
import { IConsumidor, TipoPessoa } from "model/cliente.model";
import { IToken } from "model/token.model";
import CaixaService from "pages/Caixa/caixa.service";
import AutorizaDesconto from "pages/Configuracoes/AutorizaDesconto";
import AutorizaDescontoItemError from "pages/Configuracoes/AutorizaDescontoItemError";
import { ConfiguracaoDescontoTO, DescontoMaximo } from "pages/Configuracoes/Configuracoes.model";
import ConfiguracaoDescontoService from "pages/Configuracoes/Configuracoes.service";
import CustomLoading from "pages/Venda/Header/CustomLoading";
import CadastroPessoa from "pages/cadastro-pessoa/CadastroPessoa";
import CpfNota, { mascaraCpfCnpj } from "pages/cpfNota/CpfNota";
import ConsultaEstoque from "pages/estoque/ConsultaEstoque";
import LoginService from "pages/login/login.service";
import NotaReferenciadaService from "pages/nota-referenciada/NotaReferenciada.service";
import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetCaixa, setCaixa } from "redux/features/caixaSlice";
import { resetDescontoAutorizador, setConfiguracaoDescontoAutorizador, setDeposito, setTabelaPreco } from "redux/features/configuracoes";
import { persistDescontoFixo, resetDesconto, roolBackDescontoFixo, setDescontoFixoTemp } from "redux/features/descontoFixoSlice";
import { resetChavesReferenciadas } from "redux/features/notaReferenciadaSlice";
import { resetPagamento, setValorBrinde } from "redux/features/pagamentoSlice";
import { addEstoqueItens, addItemDesconto, addSku, addSkuTroca, atualizarEstoqueItens, removeEstoqueItens, removeSku, removeSkuTrocas, resetDescontoFooter, resetSkusConsultados, resetState, setAbstractModel, setClientePossuiEnderecoForaEstado, setCpfNota, setDescontoItem, setDescontoItemTroca, setOutrosVendedores, setProdutos, setProdutosTroca, setQuantidadeItem, setQuantidadeItemTroca, setVendedorPrincipal, toggleTipoDescontoItem, toggleTipoDescontoItemTroca } from "redux/features/stateSlice";
import { setActiveStep } from "redux/features/stepperSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { globalStyles } from "styles/global";
import useCustomLocation from "utils/useCustomLocation";
import { currencyOf, deepCopy, hasDuplicateObjects, roundHalfToEven, roundToTwoDecimalPlaces } from "utils/util";
import { axiosRequest } from "../../../src/axios/axios.config";
import Sound from '../../assets/barcode_error.mp3';
import DialogContext from "../../components/confirm-dialog-context";
import OutrosComissionados, { FlowVendedorDTO, TipoAdicaoVendedor } from "./OutrosComissionados";
import { headerWidths, IProduto, IProdutoRow, ITrocaRow, TipoDesconto } from "./registra-venda.model";
import RegistraVendaService from "./registra-venda.service";
import './registra-venda.style.css';
import useCatchApiError from "hooks/useCatchApiError";

const initialState = {
    consumidores: [] as Array<IConsumidor>,
    consumidoresFiltro: '' as string,
    skus: [] as Array<IProduto>,
    skusFiltro: '' as string,
    vendedores: [] as Array<FlowVendedorDTO>,
    vendedoresFiltro: '' as string,
    showCadastrarPessoaModal: false as boolean,
    showModalDescontoItem: false as boolean,
    showModalDescontoNaoAutorizado: false as boolean,
    showLoading: false as boolean,
    showToolTipMultVendedores: false as boolean,
    openMultVendedoresDialog: false as boolean,
    isBrinde: false as boolean,
    isAdding: true as boolean
};

export const BEEPSOUND = new Audio(Sound);

export default function RegistraVenda() {
    const service = RegistraVendaService();
    const notaReferenciadaService = NotaReferenciadaService();
    const dialog = useContext(DialogContext);
    const { addSuccess, addError, closeAllSnacks } = useSnackbarCustom();
    const { throwErrorMessage } = useCatchApiError()
    const [stateLocal, setStateLocal] = useState(initialState);

    // Refs
    const codigoBarrasRef = useRef<HTMLInputElement>(null);
    const clienteRef = useRef<HTMLInputElement>(null);
    const vendedorRef = useRef<HTMLInputElement>(null);
    const descontoFixoTempRef = useRef<HTMLInputElement>(null);
    const skuRef = useRef<HTMLInputElement>(null);

    const skusRef = useRef<boolean>(false);
    const vendedoresRef = useRef<boolean>(false);


    useEffect(() => {
        if (vendedoresRef.current || !stateLocal.vendedoresFiltro.length) {
            vendedoresRef.current = false;
            getVendedoresAc(stateLocal.vendedoresFiltro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendedoresRef.current, stateLocal.vendedoresFiltro]);

    const skusOptions = () => {
        const skus = stateLocal.skus;
        const filtro = stateLocal.skusFiltro;

        if (!filtro) { return skus; }

        const toReturn = skus.filter(e => {
            let skuFormat = `${e.codigoSku}/${e.nome}/${e.cor}/${e.tamanho}`;
            return skuFormat.toLowerCase().includes(filtro.toLowerCase());
        });

        if (!toReturn.length && filtro) {
            skusRef.current = true;
            return skus;
        }

        return toReturn;
    };

    useEffect(() => {
        if (skusRef.current || !stateLocal.skusFiltro.length) {
            skusRef.current = false;
            getSkusAc(stateLocal.skusFiltro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skusRef.current, stateLocal.skusFiltro]);

    // State
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();

    const {
        quantidadeTotal,
        valorTotalBruto,
        itens: produtos,
        itensTroca: produtosTroca,
        cliente: consumidor,
        vendedorPrincipal,
        outrosVendedores,
        cpfNota,
    } = useAppSelector(s => s.state.pedido);

    const [randomUuid] = useGenerateUuid();
    const configuracaoBalcao = useAppSelector(e => e.configuracoes.configuracaoPedidoBalcao);

    const {
        percentualTemp,
        percentual
    } = useAppSelector(s => s.descontoFixo);


    const [openFixarDescontoDialog, setOpenFixarDescontoDialog] = useState(false);
    const [openConsultaEstoqueDialog, setOpenConsultaEstoqueDialog] = useState(false);
    const navigate = useNavigate();
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);
    const estabelecimentoRef = useRef<string>(estabelecimento.uuid);
    const trocaRef = useRef<{ isVisible: boolean; }>({ isVisible: false });
    const [trocaState, setTrocaState] = useState<{ isVisible: boolean; }>(trocaRef.current);
    const loginService = LoginService();
    const caixaService = CaixaService();
    const token = useAppSelector(e => e.token);
    const location = useLocation();
    const receivedParams: boolean = location.state;
    const { tableRows } = useAppSelector(e => e.pagamento);
    const valorTableRows = (tableRows.filter(e => e.formaPagamento.descritivo !== 'Nota de crédito' && e.formaPagamento.descritivo !== "OUTROS DEVOLUÇÃO").reduce((prev, current) => prev += current.valor, 0));
    const canUserAddPessoaRef = useRef<boolean>(token.NEXUS_ROLES.find(e => e === "ROLE_PESSOA") !== undefined);
    const caixa = useAppSelector(s => s.caixa);
    const { descontoMaximoItem, uuid: userConfiguracaoDescontoUUID } = useAppSelector(e => e.configuracoes.configuracaoDesconto);
    const { descontoMaximoItem: descontoMaximoItemAutorizador, descontoMaximoPedido: descontoMaximoPedidoAutorizador, autorizaDesconto: autorizaDescontoAutorizador } = useAppSelector(e => e.configuracoes.configuracaoDescontoAutorizador.configuracaoDesconto);
    const rowRef = useRef<string | null>(null);
    const descontoSolicitadoRef = useRef<number | null>(null);
    const configuracaoDescontoService = ConfiguracaoDescontoService();
    const configuracaoDescontoUsuario = useAppSelector(e => e.configuracoes.configuracaoDesconto);
    const { isTelaRegistraVenda } = useCustomLocation();
    const resetRef = useRef<boolean>(false);

    const isVisualizar = useCallback(() => {
        return pedido.isVisualizar;
        //eslint-disable-next-line
    }, []);

    const handleAddOrRemove = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, isAdding: !prevState.isAdding }));
    }, []);


    const fixedHeaderContent = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.produtoVenda,
                            maxWidth: headerWidths.produtoVenda,
                            width: headerWidths.produtoVenda,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px',
                            textAlign: 'left',
                            padding: 0,
                            paddingLeft: '15px'
                        }}
                    >
                        Produto da venda{produtosTroca.length > 0 ? "/troca" : ""}
                    </TableCell>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.estoque,
                            maxWidth: headerWidths.estoque,
                            width: headerWidths.estoque,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px',
                            padding: 0,
                            paddingLeft: '10px'
                        }}
                        align="center"
                    >
                        Estoque
                    </TableCell>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.quantidade,
                            maxWidth: headerWidths.quantidade,
                            width: headerWidths.quantidade,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px',
                            padding: 0
                        }}
                        align="center"
                    >
                        Quantidade
                    </TableCell>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.valorUnitario,
                            maxWidth: headerWidths.valorUnitario,
                            width: headerWidths.valorUnitario,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px',
                            padding: 0
                        }}
                        align="center"
                    >
                        Valor unitário
                    </TableCell>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.desconto,
                            maxWidth: headerWidths.desconto,
                            width: headerWidths.desconto,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px',
                            padding: 0
                        }}
                        align="center"
                    >
                        Desconto
                    </TableCell>

                    <TableCell
                        sx={{
                            minWidth: headerWidths.total,
                            maxWidth: headerWidths.total,
                            width: headerWidths.total,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px',
                            padding: 0
                        }}
                        align="center"
                    >
                        Total
                    </TableCell>

                    <TableCell
                        sx={{
                            minWidth: headerWidths.brinde,
                            maxWidth: headerWidths.brinde,
                            width: headerWidths.brinde,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px'
                        }}
                        align="right"
                    >
                    </TableCell>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.lixeira,
                            maxWidth: headerWidths.lixeira,
                            width: headerWidths.lixeira,
                            bgcolor: '#ededed',
                            fontWeight: '600',
                            fontSize: '14px'
                        }}
                        align="right"
                    >
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const fixedFooterContent = () => {
        return (
            <TableFooter sx={{
                height: '50px'
            }}
                className="glassMorphism"
            >
                <TableRow>
                    <TableCell
                        sx={{
                            minWidth: headerWidths.produtoVenda,
                            maxWidth: headerWidths.produtoVenda,
                            width: headerWidths.produtoVenda,
                            padding: 0,
                            paddingLeft: '15px'
                        }}
                    >
                        <span className="footerText">Total da venda</span>
                    </TableCell>

                    <TableCell
                        sx={{
                            minWidth: headerWidths.estoque,
                            maxWidth: headerWidths.estoque,
                            width: headerWidths.estoque,
                            padding: 0
                        }}
                        align="center"
                    />

                    <TableCell
                        sx={{
                            minWidth: headerWidths.quantidade,
                            maxWidth: headerWidths.quantidade,
                            width: headerWidths.quantidade,
                            padding: 0
                        }}
                        align="center"
                    >
                        <span className="footerText">{quantidadeTotal + getQuantidadeBrindes()}</span>
                    </TableCell>

                    <TableCell
                        sx={{
                            minWidth: headerWidths.valorUnitario,
                            maxWidth: headerWidths.valorUnitario,
                            width: headerWidths.valorUnitario,
                            padding: 0
                        }}
                        align="center"
                    >
                        <span className="footerText">{currencyOf(getValorTotalSemDesconto())}</span>
                    </TableCell>

                    <TableCell
                        sx={{
                            minWidth: headerWidths.desconto,
                            maxWidth: headerWidths.desconto,
                            width: headerWidths.desconto,
                            padding: 0
                        }}
                        align="center"
                    />

                    <TableCell
                        sx={{
                            minWidth: headerWidths.total,
                            maxWidth: headerWidths.total,
                            width: headerWidths.total,
                            padding: 0
                        }}
                        align="center"
                    >
                        <span className="footerText">{currencyOf(valorTotalBruto)}</span>
                    </TableCell>

                    <TableCell
                        sx={{
                            width: '214px',
                            minWidth: '214px',
                            maxWidth: '214px',
                            padding: 0
                        }}
                        align="center"
                    />
                </TableRow>
            </TableFooter>
        );
    };

    useEffect(() => {
        if (estabelecimentoRef.current !== estabelecimento.uuid) {
            estabelecimentoRef.current = estabelecimento.uuid;
            axiosRequest.get(`/flow/existeconfiguracao`, { headers: loginService.getHeaders() })
                .then(() => {
                    caixaService.getCaixas()
                        .then(({ data }) => {
                            const caixa = data.find(e => e.caixaAberto && e.operadorAtual === token.USER_INFO?.username);
                            if (!caixa) {
                                addError({ message: 'Sem caixa aberto neste estabelecimento, redirecionando para tela de caixas para abrir', closeable: true });
                                appDispatch(resetCaixa());
                                return navigate('/caixa');
                            }
                            caixaService.getSaldoConta(caixa.uuid)
                                .then(({ data }) => {
                                    dispatch(setCaixa({
                                        caixa: data.caixa,
                                        contaSelecionada: null,
                                        saldo: data.saldo,
                                        dataAbertura: data.dataAbertura,
                                        caixaAberto: data.caixaAberto
                                    }));
                                })
                                .catch(() => {
                                    addError({ message: 'Falha ao pegar o saldo da conta informada.', closeable: true });
                                });
                        }).catch(() => {
                            addError({ message: 'Falha ao pegar caixas disponiveis.', closeable: true });
                            appDispatch(resetCaixa());
                            navigate('/caixa');
                        });
                }).catch(() => {
                    appDispatch(resetCaixa());
                    navigate('/caixa');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estabelecimento.uuid]);

    const procurandoPorProdutoRef = useRef(false);
    const [procurandoPorProduto, setProcurandoPorProduto] = useState(procurandoPorProdutoRef.current);

    const toggleAndResetProcurandoPorProduto = useCallback((conditionToToggle: boolean) => {
        if (conditionToToggle) {
            procurandoPorProdutoRef.current = !procurandoPorProdutoRef.current;
            setProcurandoPorProduto(procurandoPorProdutoRef.current);
        };
        resetFocus();
        //eslint-disable-next-line
    }, []);

    const resetFocus = useCallback(() => {
        setTimeout(() => {
            if (procurandoPorProdutoRef.current) {
                skuRef.current?.focus();
            } else {
                codigoBarrasRef.current?.focus();
            }
        },);
    }, []);

    const { pedido, clientePossuiEnderecoForaEstado } = useAppSelector(s => s.state);

    const onClickAvancar = () => {
        if (pedido.itensTroca.length && !pedido.cliente?.uuid) {
            clienteRef.current?.focus();
            return addError({ id: "erro-troca-sem-cliente", message: "Para realizar troca, é obrigatório informar cliente!", closeable: true, preventDuplicate: true });
        }
        if (!pedido.cliente && !configuracaoBalcao.serieFiscalNFCe) {
            clienteRef.current?.focus();
            return addError({ id: "erro-sem-serie-nfce", message: "Série padrão para NFC-e não configurada. Obrigatório informar cliente!", closeable: true, preventDuplicate: true });
        }
        if (pedido.itens.some(e => e.quantidade > e.estoque)) {
            return addError({ id: "erro_estoque_quantidade", message: "A quantidade do produto não pode ser maior que o estoque!", closeable: true, preventDuplicate: true });
        }
        if (!pedido.itens.length) {
            return addError({ id: 'erro-semproduto', message: 'Selecione ao menos um produto para continuar', closeable: true, preventDuplicate: true });
        }
        if (pedido.valorTotalBruto < pedido.valorTotalLiquidoTroca) {
            return addError({ id: 'erro-valor-venda-inferior-troca', message: 'Valor da venda não pode ser inferior a devolução', closeable: true, preventDuplicate: true });
        }
        if ((pedido.valorTotalLiquido - pedido.valorTotalLiquidoTroca - valorTableRows) < 0) {
            return addError({ id: 'erro-valor-venda-inferior-troca', message: 'Valor do total à pagar não pode ser inferior a 0', closeable: true, preventDuplicate: true });
        }
        if (!pedido.valorTotalLiquidoTroca && pedido.pedidoDesconto.percentualDesconto === 100) {
            return addError({ id: 'erro-pedido-valor-deve-ser-maior-0', message: 'Valor minimo de pedido somente venda deve ser R$ 0,01', closeable: true, preventDuplicate: true });
        }
        if (isCaixaObrigaVendedor() && !pedido.vendedorPrincipal) {
            return addError({ id: 'erro-vendedor-brigatorio', message: 'Para este caixa, vendedor é obrigatório', closeable: true, preventDuplicate: true });
        }
        if (pedido.itens.some(e => e.isBrinde) && pedido.itensTroca.length > 0) {
            const valorBrindes = pedido.itens.filter(e => e.isBrinde).reduce((prev, act) => prev + act.valorTotal, 0);
            const total = roundToTwoDecimalPlaces(roundHalfToEven(pedido.valorTotalLiquido - valorBrindes - pedido.valorTotalLiquidoTroca - valorTableRows, 2));
            if (total < 0) return addError({ id: 'erro-valor-venda-inferior-troca-com-brinde', message: 'Valor da venda não pode ser inferior a devolução', closeable: true, preventDuplicate: true });
        }
        if (clientePossuiEnderecoForaEstado && pedido.itensTroca.length && !pedido.itensTroca.some(s => !!s.notaReferenciadaInfo?.notas.length)) {
            return addError({ id: 'erro-troca-referenciada', message: 'Nenhum dos itens de troca possui nota referenciada', closeable: true, preventDuplicate: true });
        }

        if (receivedParams) {
            return navigate('/pagamento', {
                state: {
                    receivedParams,
                    isVisualizar: isVisualizar()
                }
            });
        }
        return navigate('/pagamento');
    };

    const onClickCancelar = () => {
        if (receivedParams && isVisualizar()) {
            dispatch(resetDescontoAutorizador());
            dispatch(resetDesconto());
            dispatch(resetState());
            dispatch(resetPagamento());
            dispatch(setTabelaPreco(configuracaoBalcao.tabelaPrecoPadrao));
            dispatch(setDeposito(configuracaoBalcao.depositoPadrao));
            return navigate('/venda');
        }
        if (pedido.itens.length) {
            dialog.confirm({
                title: 'Atenção',
                message: `Ao cancelar, todos os dados serão perdidos`,
                onConfirm: () => {
                    dispatch(resetDescontoAutorizador());
                    dispatch(resetDesconto());
                    dispatch(resetState());
                    dispatch(resetPagamento());
                    dispatch(setTabelaPreco(configuracaoBalcao.tabelaPrecoPadrao));
                    dispatch(setDeposito(configuracaoBalcao.depositoPadrao));
                }
            });
        }
        else {
            dispatch(resetDescontoAutorizador());
            dispatch(resetDesconto());
            dispatch(resetState());
            dispatch(resetPagamento());
            dispatch(setTabelaPreco(configuracaoBalcao.tabelaPrecoPadrao));
            dispatch(setDeposito(configuracaoBalcao.depositoPadrao));
        }
    };

    const floatingActions: FloatingActionsProps = {
        actions: [
            {
                label: 'Fixar desconto (Alt + M)',
                icon: 'fa-regular fa-percent',
                onClick: () => {
                    setOpenFixarDescontoDialog(true);
                }
            },
            {
                label: 'Consulta estoque (Alt + E)',
                icon: 'fa-solid fa-boxes-stacked',
                onClick: () => {
                    setOpenConsultaEstoqueDialog(true);
                }
            },
            {
                label: 'Brinde (Alt + B)',
                icon: 'fa-solid fa-gifts',
                onClick: () => {
                    !isVisualizar() && clickBrinde();
                }
            },
        ]
    };


    const handleF4 = useCallback((e: KeyboardEvent) => {
        switch (e.key) {
            case 'F4':
                e.preventDefault();
                const conditionToToggle = document.activeElement?.id.indexOf('acSku') !== -1;
                toggleAndResetProcurandoPorProduto(conditionToToggle);
                break;
        }
        //eslint-disable-next-line
    }, []);

    const handleKeyNavigate = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Escape':
                if (trocaRef.current.isVisible) {
                    trocaRef.current.isVisible = false;
                    toogleTroca(false);
                } else {
                    onClickCancelar();
                }
                break;
            case 'Enter':
                if (e.ctrlKey) {
                    e.preventDefault();
                    onClickAvancar();
                }
                break;
        }
    };


    const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'F2':
                e.preventDefault();
                clienteRef.current && clienteRef.current.focus();
                break;
            case 'F3':
                e.preventDefault();
                if (!isVisualizar()) {
                    handleClickOpenDialog();
                }
                break;
            case 'F6':
                e.preventDefault();
                vendedorRef.current && vendedorRef.current.focus();
                break;
            case "m":
            case "M":
                if (e.altKey) {
                    e.preventDefault();
                    setOpenFixarDescontoDialog(true);
                }
                break;
            case 't':
            case "T":
                if (isTelaRegistraVenda() && e.altKey && !isVisualizar()) {
                    e.preventDefault();
                    toogleTroca(!trocaRef.current.isVisible);
                    trocaRef.current.isVisible = !trocaRef.current.isVisible;
                    resetFocus();
                }
                break;
            case "e":
            case "E":
                if (e.altKey) {
                    e.preventDefault();
                    setOpenConsultaEstoqueDialog(() => !openConsultaEstoqueDialog);
                }
                break;
            case "b":
            case "B":
                if (isTelaRegistraVenda() && e.altKey && !isVisualizar()) {
                    e.preventDefault();
                    setStateLocal(prevState => ({ ...prevState, isBrinde: !prevState.isBrinde }));
                }
                break;
            case "+":
            case "=":
                if (e.altKey) setStateLocal(prevState => ({ ...prevState, isAdding: true }));
                break;
            case "-":
                if (e.altKey) setStateLocal(prevState => ({ ...prevState, isAdding: false }));
                break;
        }
    };

    // Consumidor
    const getConsumidoresAc = useCallback((toSearch: string = '') => {
        closeAllSnacks()
        service.getConsumidoresAc(toSearch)
            .then(({ data }) => {
                setStateLocal(prevState => ({ ...prevState, consumidores: data }));
            }).catch(error => {
                throwErrorMessage(error)
                navigate('/caixa');
            });
        //eslint-disable-next-line
    }, []);

    const allVendedores = useRef([] as FlowVendedorDTO[]);

    // Vendedor
    const getVendedoresAc = useCallback((toSearch: string = '') => {
        closeAllSnacks()
        const isGetAll = !toSearch;
        service.getVendedoresAc(toSearch)
            .then(({ data }) => {
                setStateLocal(prevState => ({ ...prevState, vendedores: data, }));
                if (isGetAll) {
                    allVendedores.current = data;
                }
            }).catch(error => {
                throwErrorMessage(error)
                navigate('/caixa');
            });
        //eslint-disable-next-line
    }, []);

    // Skus
    const getSkusAc = useCallback((toSearch: string = '') => {
        closeAllSnacks()
        service.getSkusAc(toSearch, configuracaoBalcao.tabelaPreco?.uuid ?? null)
            .then(({ data }) => {
                setStateLocal(prevState => ({ ...prevState, skus: data }));
            }).catch(error => {
                throwErrorMessage(error)
                navigate('/caixa');
            });
        //eslint-disable-next-line
    }, [configuracaoBalcao.tabelaPreco]);

    useEffect(() => {
        window.addEventListener('keydown', handleF4);
        return () => {
            window.removeEventListener('keydown', handleF4);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procurandoPorProduto]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyNavigate);
        return () => {
            window.removeEventListener('keydown', handleKeyNavigate);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedido.itens]);

    useEffect(() => {
        dispatch(setActiveStep(0));
        resetFocus();

        getConsumidoresAc();
        getVendedoresAc();
        getSkusAc();

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getConsumidoresAc();
        getVendedoresAc();
        getSkusAc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuracaoBalcao.tabelaPreco]);

    const fazerAsParadasDeNotaReferenciada = (
        value: IConsumidor,
        produtosTroca: ITrocaRow[]
    ) => {
        const itens = deepCopy(produtosTroca);
        const toPost: Record<string, number> = {};
        itens.forEach(s => {
            const skuUUID = s.produto.uuid;
            if (!toPost[skuUUID]) {
                toPost[skuUUID] = 0;
            }
            toPost[skuUUID] = roundHalfToEven(toPost[skuUUID] + s.quantidade, 0);
        });
        notaReferenciadaService
            .getNotas(value.uuid, toPost)
            .then(({ data }) => {
                if (!data) return limparNotasReferenciadas(produtosTroca);
                const copyResponse = deepCopy(data);
                const copyItens = deepCopy(itens);
                copyItens.forEach(item => {
                    const skuUUID = item.produto.uuid;
                    const notaInfo = copyResponse[skuUUID];
                    item.notaReferenciadaInfo = {
                        notas: [],
                        quantidadeNaoReferenciada: 0,
                    };
                    let qtdFaltante = item.quantidade;
                    if (!notaInfo) {
                        item.notaReferenciadaInfo!.quantidadeNaoReferenciada = qtdFaltante;
                        return;
                    };
                    notaInfo.notas.forEach(nota => {
                        if (!nota) return;
                        const notaSaldo = nota.saldo;
                        if (notaSaldo <= 0 || qtdFaltante <= 0) return;
                        if (item.notaReferenciadaInfo!.notas.every(s => s.chaveAcesso !== nota.chaveAcesso)) {
                            item.notaReferenciadaInfo!.notas.push(deepCopy(nota));

                        }
                        nota.saldo -= qtdFaltante;
                        qtdFaltante -= notaSaldo;
                    });
                    if (qtdFaltante > 0) {
                        item.notaReferenciadaInfo!.quantidadeNaoReferenciada = qtdFaltante;
                    }
                });

                dispatch(setProdutosTroca(copyItens));
            }).finally(() => {
                shouldConsultaNotaReferenciada.current = true;
            });
    };

    const limparNotasReferenciadas = (produtosTroca: ITrocaRow[]) => {
        if (!produtosTroca.length) return;
        const copyTroca = deepCopy(produtosTroca);
        copyTroca.forEach(s => s.notaReferenciadaInfo = undefined);
        dispatch(setProdutosTroca(copyTroca));
        dispatch(resetChavesReferenciadas());
    };

    const onSelectConsumidor = useCallback((e: SyntheticEvent<Element, Event>, value: IConsumidor | null) => {
        dispatch(setAbstractModel({ propertieName: 'cliente', obj: value }));
        if (value?.identificacao && value.tipoPessoa !== TipoPessoa.ESTRANGEIRA) {
            dispatch(setCpfNota({ identificacao: value?.identificacao, nome: value?.nome }));
            handleClickOpenDialog();
        }
        if (isCaixaObrigaVendedor()) {
            setOutrosComissionados(value?.representantes ?? [], TipoAdicaoVendedor.REPRESENTANTE);
        }
        //eslint-disable-next-line
    }, [vendedorPrincipal, outrosVendedores, configuracaoBalcao.caixasObrigaVendedor, caixa]);

    const shouldConsultaNotaReferenciada = useRef<boolean>(true);
    const { chavesAcesso } = useAppSelector(s => s.notaReferenciada);

    useEffect(() => {

        if (shouldConsultaNotaReferenciada.current && !consumidor) {
            shouldConsultaNotaReferenciada.current = false;
            limparNotasReferenciadas(produtosTroca);
            setTimeout(() => {
                shouldConsultaNotaReferenciada.current = true;
            },);
        }

        if (shouldConsultaNotaReferenciada.current && consumidor && chavesAcesso.length) {
            shouldConsultaNotaReferenciada.current = false;
            const copyItensTroca = deepCopy(produtosTroca);
            copyItensTroca.forEach(s => {
                if (!s.notaReferenciadaInfo) {
                    s.notaReferenciadaInfo = {
                        notas: [],
                        quantidadeNaoReferenciada: 0
                    };
                }

                s.notaReferenciadaInfo!.notas = chavesAcesso.map(n => ({
                    chaveAcesso: n.chaveAcesso,
                    serie: n.serie,
                    numeroNota: n.numero,
                    saldo: 0,
                    skuUUID: s.produto.uuid,
                }));
                s.notaReferenciadaInfo!.quantidadeNaoReferenciada = s.quantidade;
            });

            dispatch(setProdutosTroca(copyItensTroca));
            setTimeout(() => {
                shouldConsultaNotaReferenciada.current = true;
            },);
            return;
        }

        if (shouldConsultaNotaReferenciada.current && produtosTroca.length && consumidor) {
            shouldConsultaNotaReferenciada.current = false;
            setTimeout(() => {
                fazerAsParadasDeNotaReferenciada(consumidor, produtosTroca);
            },);
        }
        //eslint-disable-next-line
    }, [produtosTroca, consumidor, chavesAcesso]);


    const onInputChangeVendedoresAc = useCallback((e: SyntheticEvent<Element, Event>, value: string) => {
        getVendedoresAc(value);
        setStateLocal(prevState => ({ ...prevState, vendedoresFiltro: value }));
        //eslint-disable-next-line
    }, []);

    const onSelectVendedor = useCallback((e: SyntheticEvent<Element, Event>, value: FlowVendedorDTO | null) => {
        dispatch(setVendedorPrincipal(value));
        if (!!value) setOutrosComissionados(value.outrosComissionados ?? [], TipoAdicaoVendedor.OUTRO_COMISSIONADO, value);
        else dispatch(setOutrosVendedores(outrosVendedores.filter(s => s.tipoAdicao === TipoAdicaoVendedor.REPRESENTANTE)));
        //eslint-disable-next-line
    }, [outrosVendedores]);

    const setOutrosComissionados = (uuids: string[], tipoAdicao: TipoAdicaoVendedor, vendedor: FlowVendedorDTO | null = vendedorPrincipal) => {
        const toAdd = uuids.map(uuid => ({
            ...allVendedores.current.filter(v => v.uuid === uuid)[0],
            tipoAdicao,
        }));
        const { itensChecados } = hasDuplicateObjects(
            [...toAdd, ...outrosVendedores],
            (a, b) => a.uuid === b.uuid
        );
        const toSet = itensChecados.filter(s => s.uuid !== vendedor?.uuid);
        dispatch(setOutrosVendedores(toSet));
    };

    // Search codigo de barras | nome do SKU
    const onInputChangeSkuAc = useCallback((e: SyntheticEvent<Element, Event>, value: string) => {
        setStateLocal(prevState => ({ ...prevState, skusFiltro: value }));
        //eslint-disable-next-line
    }, []);

    const onSelectProduto = useCallback((e: SyntheticEvent<Element, Event>, value: IProduto | null) => {
        if (!value) return;
        if (!stateLocal.isAdding && !trocaState.isVisible) return dispatch(removeSku(produtoRowOf(value, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
        if (!stateLocal.isAdding && trocaState.isVisible) return dispatch(removeSkuTrocas(produtoRowOf(value, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
        if (!trocaState.isVisible) return dispatch(addSku(produtoRowOf(value, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
        return dispatch(addSkuTroca(produtoRowOf(value, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
        //eslint-disable-next-line
    }, [trocaState, stateLocal.isAdding, percentualTemp, percentual, stateLocal.isBrinde]);

    // Produto
    useEffect(() => {
        if (produtos.length) return atualizarEstoque();
        dispatch(resetSkusConsultados());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtos.length]);

    useEffect(() => {
        if (produtos.length) atualizarEstoque();
        // eslint-disable-next-line
    }, [configuracaoBalcao.deposito]);

    useEffect(() => {
        if (!consumidor && cpfNota !== null) dispatch(setCpfNota(null));

        if (consumidor) {
            notaReferenciadaService.clientePossuiEnderecoPrincipalOuEntregaForaDoEstado(consumidor.uuid)
                .then(({ data }) => {
                    dispatch(setClientePossuiEnderecoForaEstado(data.possuiEnderecoForaDoEstado));
                });
        } else {
            dispatch(setClientePossuiEnderecoForaEstado(false));
        }
        //eslint-disable-next-line
    }, [consumidor]);


    const getValorBrindes = useCallback(() => {
        return produtos.filter(e => e.isBrinde).reduce((prev, act) => prev += act.valorTotal, 0);
        //eslint-disable-next-line
    }, [produtos]);

    useEffect(() => {
        dispatch(setValorBrinde(getValorBrindes()));

        if (!resetRef.current && produtos.every(e => e.isBrinde)) {
            resetRef.current = true;
            dispatch(resetDescontoFooter());
        }
        else resetRef.current = false;

        //eslint-disable-next-line
    }, [produtos]);

    const atualizarEstoque = () => {
        const uuidsNaoConsultados = produtos
            .filter((produto) => !pedido.consultados.includes(produto.produto.uuid))
            .map((produto) => produto.produto.uuid).join(",");
        if (uuidsNaoConsultados) {
            service.getEstoque(uuidsNaoConsultados)
                .then(({ data }) => {
                    dispatch(addEstoqueItens({ itens: data }));
                })
                .catch(() => { });
        } else {
            dispatch(atualizarEstoqueItens());
        }
    };

    const produtoRowOf = useCallback((p: IProduto, percentualTemp: number, percentual: number, isBrinde: boolean, isTroca: boolean): IProdutoRow => {
        return {
            produto: p,
            quantidade: 1,
            estoque: -1,
            valorTotal: p.preco - (percentual * p.preco / 100),
            itemDesconto: {
                percentualDesconto: percentualTemp ?? percentual,
                tipoDesconto: TipoDesconto.PERCENTUAL,
                uuidAutorizador: null,
                valorDesconto: (percentual * p.preco / 100)
            },
            isBrinde: isBrinde,
            isTroca: isTroca,
            uuid: randomUuid()
        };
        //eslint-disable-next-line
    }, []);

    const onAddSku = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        const inputValue = codigoBarrasRef.current?.value;
        if (e.key === 'Enter' && inputValue) {
            service.getProdutoByCodigo(inputValue, configuracaoBalcao.tabelaPreco?.uuid ?? null)
                .then(({ data }) => {
                    if (!stateLocal.isAdding && !trocaState.isVisible) return dispatch(removeSku(produtoRowOf(data, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
                    if (!stateLocal.isAdding && trocaState.isVisible) return dispatch(removeSkuTrocas(produtoRowOf(data, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
                    if (trocaState.isVisible) return dispatch(addSkuTroca(produtoRowOf(data, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
                    return dispatch(addSku(produtoRowOf(data, percentualTemp, percentual, stateLocal.isBrinde, trocaState.isVisible)));
                }).catch(error => {
                    BEEPSOUND.play();
                    dialog.confirm({
                        error: true,
                        title: 'Atenção',
                        message: `CÓDIGO DE BARRAS ${inputValue} NÃO ENCONTRADO NA TABELA DE PREÇO ${configuracaoBalcao.tabelaPreco?.descritivo ?? ""}`,
                        onConfirm: () => { }
                    });
                });
            codigoBarrasRef.current.value = '';
            resetFocus();
        }
        //eslint-disable-next-line
    }, [trocaState.isVisible, configuracaoBalcao.tabelaPreco, stateLocal.isAdding, percentualTemp, percentual, stateLocal.isBrinde]);

    const toggleTipoDesconto = useCallback((uuid: string) => {
        dispatch(toggleTipoDescontoItem({ uuid }));
        //eslint-disable-next-line
    }, []);

    const toggleTipoDescontoTroca = useCallback((uuid: string) => {
        dispatch(toggleTipoDescontoItemTroca({ uuid }));
        //eslint-disable-next-line
    }, []);

    const tempDescontoItemRef = useRef<number>(0);
    const tempDescIndexRef = useRef<string | null>(null);

    const saveTempDesconto = useCallback((prevDesconto: number, uuid: string) => {
        tempDescontoItemRef.current = prevDesconto;
        tempDescIndexRef.current = uuid;
    }, []);

    const isDescontoLinhaRef = useRef<boolean>(true);

    const onBlurDescontoItem = useCallback((value: number, uuid: string) => {
        if (configuracaoDescontoUsuario.uuid == null) return;

        isDescontoLinhaRef.current = true;
        rowRef.current = uuid;
        descontoSolicitadoRef.current = value;

        if (value <= descontoMaximoItem) {
            dispatch(addItemDesconto({
                uuid: uuid,
                itemDesconto: {
                    uuidAutorizador: userConfiguracaoDescontoUUID,
                    percentualDesconto: descontoSolicitadoRef.current,
                    tipoDesconto: produtos.find(e => e.uuid === uuid)!.itemDesconto.tipoDesconto,
                    valorDesconto: produtos.find(e => e.uuid === uuid)!.itemDesconto.valorDesconto
                }
            }));
        }

        if (descontoMaximoItemAutorizador && value <= descontoMaximoItemAutorizador && autorizaDescontoAutorizador) return;
        if (value <= tempDescontoItemRef.current) {
            tempDescontoItemRef.current = 0;
            return;
        }
        if ((value > descontoMaximoItem)) {
            configuracaoDescontoService.validaDescontoSolicitado(value, DescontoMaximo.ITEM)
                .then(({ data }: { data: boolean; }) => {
                    if (!data) return setStateLocal(prevState => ({
                        ...prevState,
                        showModalDescontoItem: false,
                        showModalDescontoNaoAutorizado: true
                    }));
                    return setStateLocal(prevState => ({ ...prevState, showModalDescontoItem: true }));
                })
                .catch(() => { });
        }
        //eslint-disable-next-line
    }, [produtos.length]);

    const changeDesconto = useCallback((value: number, uuid: string) => {
        dispatch(setDescontoItem({ uuid, value }));
        //eslint-disable-next-line
    }, []);

    const changeDescontoTroca = useCallback((value: number, uuid: string) => {
        dispatch(setDescontoItemTroca({ uuid, value }));
        //eslint-disable-next-line
    }, []);


    const onChangeQuantidade = useCallback((uuid: string, value: string) => {
        if (value.startsWith('-')) value = value.substring(1);
        if (!value || value === '0') value = '1';

        dispatch(setQuantidadeItem({ uuid, quantidade: Math.trunc(Number(value)) }));
        //eslint-disable-next-line
    }, []);

    const onChangeQuantidadeTroca = useCallback((uuid: string, value: string) => {
        if (value.startsWith('-')) value = value.substring(1);
        if (!value) value = '1';
        dispatch(setQuantidadeItemTroca({ uuid, quantidade: Math.trunc(Number(value)) }));
        //eslint-disable-next-line
    }, []);

    const clickBrinde = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, isBrinde: !prevState.isBrinde }));
    }, []);

    const toDeleteRowIndex = useRef<string | null>(null);

    const onClickDelete = useCallback((uuid: string, row: IProdutoRow) => {
        dialog.confirm({
            title: 'Atenção',
            message: `Deseja excluir o produto ${row.produto.nome} ?`,
            onConfirm: () => {
                toDeleteRowIndex.current = uuid;
                const rows = produtos.filter(e => e.uuid !== uuid);
                dispatch(removeEstoqueItens(row));
                dispatch(setProdutos(rows));
            }
        });
        //eslint-disable-next-line
    }, [produtos]);

    const onClickDeleteTroca = useCallback((uuid: string, nomeProduto: string) => {
        dialog.confirm({
            title: 'Atenção',
            message: `Deseja excluir o produto ${nomeProduto} ?`,
            onConfirm: () => {
                const rows = produtosTroca.filter((row) => row.uuid !== uuid);
                dispatch(setProdutosTroca(rows));
            }
        });
        //eslint-disable-next-line
    }, [produtosTroca.length]);

    const getValorTotalSemDesconto = () => {
        return produtos.map(s => {
            return {
                qtd: s.quantidade,
                valor: s.produto.preco
            };
        }).reduce((prv, crr) => prv += (crr.qtd * crr.valor), 0);
    };

    // Dialog fixar desconto
    const onFocusDescontoFixoDialog = () => {
        descontoFixoTempRef.current?.focus();
    };

    const getHasSomeoneCanGiveDiscount = useCallback((value: number) => {
        configuracaoDescontoService.validaDescontoSolicitado(descontoSolicitadoRef.current!, DescontoMaximo.ITEM)
            .then(({ data }: { data: Boolean; }) => {
                isDescontoLinhaRef.current = false;
                setOpenFixarDescontoDialog(false);
                if (!data) {
                    return setStateLocal(prevState => ({ ...prevState, showModalDescontoNaoAutorizado: true }));
                }
                descontoSolicitadoRef.current = value;
                return setStateLocal(prevState => ({ ...prevState, showModalDescontoItem: true }));
            })
            .catch(err => {
                addError({ id: "", message: "Não foi possivel validar o desconto, tente novamente. Se o erro persistir, contate o suporte.", closeable: true, persist: false, preventDuplicate: false });
            });
        // eslint-disable-next-line
    }, []);

    const onChangeDescontoFixo = useCallback((value: number = 0) => {
        descontoSolicitadoRef.current = value;
        dispatch(setDescontoFixoTemp(value));
        //eslint-disable-next-line
    }, []);

    const onClearDescontoFixo = useCallback(() => {
        dispatch(setDescontoFixoTemp(0));
        //eslint-disable-next-line
    }, []);

    const onCloseFixarDescontoDialog = useCallback(() => {
        dispatch(roolBackDescontoFixo());
        setOpenFixarDescontoDialog(false);
        resetFocus();
        //eslint-disable-next-line
    }, []);

    const onSaveDescontFixo = (e: React.FormEvent) => {
        e.preventDefault();
        if ((descontoMaximoItemAutorizador && descontoSolicitadoRef.current! > descontoMaximoItemAutorizador) ||
            (!descontoMaximoItemAutorizador && descontoMaximoItem && descontoSolicitadoRef.current! > descontoMaximoItem)) {
            return getHasSomeoneCanGiveDiscount(descontoSolicitadoRef.current!);
        }
        dispatch(persistDescontoFixo());
        setOpenFixarDescontoDialog(false);
        resetFocus();
    };

    //Cpf na nota
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickOpenDialog = () => {
        return setOpenDialog(true);
    };

    const handleClickCloseDialog = useCallback(() => {
        return setOpenDialog(false);
        //eslint-disable-next-line
    }, []);

    // Cadastro pessoa dialog 
    const [openCadastroPessoaDialog, setOpenCadastroPessoaDialog] = useState(false);

    const switchOptions = () => {
        toogleTroca(!trocaState.isVisible);
        trocaRef.current.isVisible = !trocaRef.current.isVisible;
    };


    const onInputChangeConsumidoresAc = (e: React.ChangeEvent<{}>, value: string) => {
        getConsumidoresAc(value);
        setStateLocal(prevState => ({ ...prevState, consumidoresFiltro: value }));
    };

    const onBlurConsumidores = () => {
        if (!stateLocal.consumidoresFiltro && !consumidor) {
            getConsumidoresAc();
        }
    };

    const isCaixaObrigaVendedor = useCallback((): boolean => {
        return configuracaoBalcao.caixasObrigaVendedor?.some(s => s.uuid === caixa.caixaAtual.caixa?.uuid) ?? false;
    }, [configuracaoBalcao.caixasObrigaVendedor, caixa]);

    const onBlurSkus = useCallback(() => {
        if (!stateLocal.skusFiltro) {
            getSkusAc();
        }
        //eslint-disable-next-line
    }, [stateLocal.skusFiltro]);

    const toogleTroca = useCallback((isVisible: boolean) => {
        setTrocaState(prev => ({ ...prev, isVisible }));
    }, []);

    const closeAutorizaDesconto = () => {
        if (isDescontoLinhaRef.current) {
            dispatch(setDescontoItem({ uuid: rowRef.current!, value: 0 }));
            rowRef.current = null;
        }
        setStateLocal(prevState => ({ ...prevState, showModalDescontoItem: false, showModalDescontoNaoAutorizado: false }));

        if (!isDescontoLinhaRef.current) {
            dispatch(resetDesconto());
            setOpenFixarDescontoDialog(false);
        }
    };

    const handleAutorizaDesconto = (username: string, password: string, switchBoolean: boolean) => {
        closeAllSnacks()
        if (username === token.USER_INFO?.username) {
            return addError({ id: "same-user-exception", message: "Seu usuário não permite este desconto, solicite à um responsável", closeable: true, persist: false });
        }

        setStateLocal(prevState => ({ ...prevState, showLoading: true }));
        let tokenAutorizador = "";

        loginService.loga({ user: username, password: password })
            .then(({ data }: { data: IToken; }) => {
                tokenAutorizador = data.access_token!;
                return configuracaoDescontoService.getConfiguracaoDescontoAutorizador(username, tokenAutorizador, estabelecimento.uuid);
            })
            .then(({ data }: { data: ConfiguracaoDescontoTO; }) => {
                if (isDescontoLinhaRef.current) {
                    dispatch(addItemDesconto({
                        uuid: rowRef.current!,
                        itemDesconto: {
                            uuidAutorizador: data.uuid,
                            percentualDesconto: descontoSolicitadoRef.current!,
                            tipoDesconto: produtos.find(e => e.uuid === rowRef.current!)!.itemDesconto.tipoDesconto,
                            valorDesconto: produtos.find(e => e.uuid === rowRef.current!)!.itemDesconto.valorDesconto

                        }
                    }));
                }
                if (switchBoolean) {
                    dispatch(setConfiguracaoDescontoAutorizador({
                        token: tokenAutorizador,
                        configuracaoDesconto: {
                            uuid: data.uuid,
                            usuario: data.usuario,
                            descontoMaximoItem: descontoSolicitadoRef.current ?? 0,
                            descontoMaximoPedido: descontoMaximoPedidoAutorizador ?? data.descontoMaximoPedido,
                            autorizaDesconto: data.autorizaDesconto,
                        }
                    }));
                }
                else {
                    dispatch(setConfiguracaoDescontoAutorizador({
                        token: tokenAutorizador,
                        configuracaoDesconto: {
                            uuid: data.uuid,
                            usuario: data.usuario,
                            descontoMaximoItem: descontoSolicitadoRef.current ?? descontoMaximoItemAutorizador ?? 0,
                            descontoMaximoPedido: descontoMaximoPedidoAutorizador ?? 0,
                            autorizaDesconto: false,
                        }
                    }));
                }
                if (!isDescontoLinhaRef.current) {
                    setOpenFixarDescontoDialog(false);
                    dispatch(setDescontoFixoTemp(descontoSolicitadoRef.current!));
                }
                setStateLocal(prevState => ({ ...prevState, showLoading: false, showModalDescontoItem: false }));
                addSuccess({ id: "desconto-aplicado-com-sucesso", message: "Desconto aplicado", closeable: true, persist: false, hideDuration: 2000 });
            })
            .catch((err) => {
                if (err.response.data.description === "SemConfiguracaoDesconto") {
                    throwErrorMessage(err)
                } else {
                    addError({ id: "usuario-senha-invalido", message: "Usuário ou senha inválidos", closeable: true, persist: false });
                }
                setStateLocal(prevState => ({ ...prevState, showLoading: false }));
            });

        //eslint-disable-next-line
    };

    const closeMultVendedoresDialog = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, openMultVendedoresDialog: false }));
        //eslint-disable-next-line
    }, []);



    const getQuantidadeBrindes = useCallback(() => {
        return pedido.itens.filter(e => e.isBrinde).reduce((prev, act) => prev + act.quantidade, 0);
    }, [pedido.itens]);

    const renderItem = useCallback((row: IProdutoRow, index: number): JSX.Element => {
        if (!row.isTroca) {
            return (
                <ItemRegistraVenda
                    row={row}
                    onChangeQuantidade={onChangeQuantidade}
                    changeDesconto={changeDesconto}
                    onBlurDescontoItem={onBlurDescontoItem}
                    onClickDelete={onClickDelete}
                    saveTempDesconto={saveTempDesconto}
                    toggleTipoDesconto={toggleTipoDesconto}
                    key={`${row.produto.codigoSku}-${index}`}
                    isVisualizar={isVisualizar()}
                />
            );
        }
        return (
            <ItemRegistraVendaTroca
                row={row}
                onChangeQuantidadeTroca={onChangeQuantidadeTroca}
                changeDescontoTroca={changeDescontoTroca}
                onBlurDescontoItemTroca={onBlurDescontoItem}
                onClickDeleteTroca={onClickDeleteTroca}
                saveTempDescontoTroca={saveTempDesconto}
                toggleTipoDescontoTroca={toggleTipoDescontoTroca}
                isVisualizar={isVisualizar()}
                key={`ItemRegistraVendaTroca-${index}`}
            />
        );
        //eslint-disable-next-line
    }, [produtos, produtosTroca]);

    return (
        <>
            {trocaState.isVisible &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: 100 }}
                    open={trocaState.isVisible}
                >
                </Backdrop>
            }
            <Container
                maxWidth="xl"
                sx={{
                    marginTop: '20px',
                }}>

                <Box
                    maxHeight="xl"
                    sx={{
                        height: 'calc(100vh - 64px - 76px - 20px - 20px)',
                        display: 'grid',
                        gridTemplateRows: { md: `50px 50px auto 100px 50px`, xs: `auto auto 450px auto auto` },
                        gap: '20px',
                    }}>
                    <Grid container spacing={2}>
                        {trocaState.isVisible &&
                            <Box sx={{ backgroundColor: "#FFFFFF", zIndex: 100, width: '100%', padding: '10px', marginLeft: '9px', fontFamily: 'Work Sans', fontWeight: '600', fontSize: '14px', borderRadius: '10px' }}>
                                <Typography sx={{ color: 'var(--laranja)', fontSize: '2rem' }}>
                                    Informe o produto a ser devolvido...
                                </Typography>
                            </Box>
                        }

                        {!trocaState.isVisible &&
                            <>
                                <Grid xs={12} sm={6} lg={6}>
                                    <Autocomplete
                                        disabled={isVisualizar()}
                                        onBlur={onBlurConsumidores}
                                        value={consumidor}
                                        disablePortal
                                        id="acCliente"
                                        options={stateLocal.consumidores}
                                        getOptionLabel={(option) => option.descritivo}
                                        onInputChange={onInputChangeConsumidoresAc}
                                        filterOptions={(x) => x}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        onChange={onSelectConsumidor}
                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.uuid}>
                                                    {option.descritivo}
                                                </li>
                                            );
                                        }}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                label="Consumidor"
                                                variant="standard"
                                                inputRef={clienteRef}
                                                InputLabelProps={{
                                                    sx: {
                                                        marginLeft: '5px'
                                                    }
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            <h4 className="h4-formatado">(F2)</h4>
                                                            <Button
                                                                disabled={!canUserAddPessoaRef.current || (isVisualizar())}
                                                                sx={{
                                                                    width: 'fit-content',
                                                                    minWidth: 'auto',
                                                                    marginRight: '-10px'
                                                                }}
                                                                onClick={() => {
                                                                    setOpenCadastroPessoaDialog(true);
                                                                }}
                                                            >
                                                                {!consumidor ? <Icon
                                                                    className="fa-solid fa-user-plus"
                                                                    sx={{
                                                                        width: 'fit-content',
                                                                        fontSize: '18px',
                                                                        color: !canUserAddPessoaRef.current || isVisualizar() ? 'grey' : 'var(--laranja)'
                                                                    }}
                                                                /> : <Icon
                                                                    className="fa-solid fa-pencil"
                                                                    sx={{
                                                                        width: 'fit-content',
                                                                        fontSize: '18px',
                                                                        color: (!canUserAddPessoaRef.current || isVisualizar()) ? 'grey' : 'var(--laranja)'
                                                                    }}
                                                                />
                                                                }
                                                                {stateLocal.showCadastrarPessoaModal &&
                                                                    <CustomToolTips
                                                                        id={`alt-escape-id`}
                                                                        position='absolute'
                                                                        bottom='30px'
                                                                        left='-100px'
                                                                        bgColor='#808080'
                                                                        color='#FFF'
                                                                        height='auto'
                                                                        width='250px'
                                                                        zIndex={100}
                                                                        display='flex'
                                                                        justifyContent='center'
                                                                        alignItems='center'
                                                                        borderRadius='10px'
                                                                        isVisible={true}
                                                                        trueText='Usuário sem acesso ao Cadastro de pessoas'
                                                                        falseText=''
                                                                        autoCapitalize={false}
                                                                    />
                                                                }
                                                            </Button>
                                                            {!canUserAddPessoaRef.current &&
                                                                <Button
                                                                    style={{
                                                                        cursor: 'text',
                                                                        position: 'absolute',
                                                                        bottom: "0px",
                                                                        right: '20px',
                                                                        height: '30px',
                                                                    }}
                                                                    onMouseOver={() => setStateLocal(prevState => ({ ...prevState, showCadastrarPessoaModal: true }))}
                                                                    onMouseLeave={() => setStateLocal(prevState => ({ ...prevState, showCadastrarPessoaModal: false }))}
                                                                />
                                                            }
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} lg={6}>
                                    <TextField
                                        fullWidth={true}
                                        id="cpfDescritivo"
                                        label="CPF na nota?"
                                        variant="standard"
                                        disabled={true}
                                        value={cpfNota?.identificacao ? mascaraCpfCnpj(cpfNota.identificacao) : ''}
                                        InputLabelProps={{
                                            sx: {
                                                marginLeft: '5px'
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <React.Fragment>
                                                    <h4 className="h4-formatado">(F3)</h4>
                                                    <IconButton sx={{ height: 0, padding: 0, paddingLeft: '5px' }}
                                                        onClick={handleClickOpenDialog}
                                                        disabled={isVisualizar()}
                                                    >
                                                        <i className="fa-regular fa-file-lines" style={{ color: isVisualizar() ? 'grey' : "var(--laranja)" }} />
                                                    </IconButton>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                    <CpfNota
                                        onClose={handleClickCloseDialog}
                                        open={openDialog}
                                        data={cpfNota}
                                    />
                                </Grid>
                            </>
                        }

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} lg={6}>
                            {!procurandoPorProduto &&
                                <TextField
                                    disabled={isVisualizar()}
                                    fullWidth={true}
                                    variant="standard"
                                    // placeholder={configuracaoBalcao.tabelaPreco?.descritivo ?? "NOME DA TABELA DE PREÇO PADRÃO"}
                                    label='Código de barras | Código do sku'
                                    InputLabelProps={{
                                        sx: {
                                            marginLeft: '5px'
                                        }
                                    }}
                                    id="acSku-input"
                                    sx={{
                                        bgcolor: trocaState.isVisible ? '#FFF' : '',
                                        zIndex: trocaState.isVisible ? 100 : 1,
                                        borderRadius: '5px',
                                        maxHeight: "70px"
                                    }}

                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <Tooltip
                                                    title={`${!stateLocal.isAdding ? "Adicionar item ( Alt + '+' )" : "Remover item ( Alt + '-' )"}`}>
                                                    <IconButton
                                                        disabled={isVisualizar()}
                                                        onClick={handleAddOrRemove}
                                                        sx={{ height: 0, padding: 0, paddingLeft: '5px', paddingRight: '5px' }}
                                                    >
                                                        <Icon className={`${stateLocal.isAdding ? "fa-solid fa-plus" : "fa-solid fa-minus"}`}
                                                            sx={{
                                                                color: isVisualizar() ? globalStyles.DISABLED_GREY : globalStyles.LARANJA,
                                                                paddingBottom: '5px'
                                                            }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                                <h4 className="h4-formatado">(F4)</h4>
                                                <IconButton
                                                    disabled={isVisualizar()}
                                                    onClick={() => {
                                                        toggleAndResetProcurandoPorProduto(true);
                                                    }}
                                                    sx={{ height: 0, padding: '0 5px' }}
                                                >
                                                    <Icon className="fa-solid fa-barcode" />
                                                </IconButton>
                                            </React.Fragment>
                                        )
                                    }}
                                    inputRef={codigoBarrasRef}
                                    onKeyUp={onAddSku}
                                />}
                            {procurandoPorProduto &&
                                <Autocomplete
                                    disabled={isVisualizar()}
                                    onBlur={onBlurSkus}
                                    disablePortal
                                    id="acSkuProcurandoProduto-input"
                                    options={skusOptions()}
                                    getOptionLabel={o => `${o.codigoSku}/${o.nome}/${o.cor}/${o.tamanho}`}
                                    onInputChange={onInputChangeSkuAc}
                                    onChange={onSelectProduto}
                                    value={null}
                                    blurOnSelect={true}
                                    filterOptions={(x) => x}
                                    noOptionsText={'Nenhum resultado encontrado'}
                                    renderInput={
                                        (params) => <TextField {...params}
                                            sx={{ zIndex: trocaState.isVisible ? 100 : 1, backgroundColor: trocaState.isVisible ? '#FFFFFF' : '', borderRadius: trocaState.isVisible ? '5px' : '' }}
                                            label="Nome tamanho cor do sku"
                                            variant="standard"
                                            placeholder={configuracaoBalcao.tabelaPreco?.descritivo ?? "NOME DA TABELA DE PREÇO PADRÃO"}
                                            inputRef={skuRef}
                                            InputLabelProps={{
                                                sx: {
                                                    marginLeft: '5px'
                                                }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        <Tooltip title={`${!stateLocal.isAdding ? "Adicionar item ( Alt + '+' )" : "Remover item ( Alt + '-' )"}`}>
                                                            <IconButton
                                                                disabled={isVisualizar()}
                                                                onClick={handleAddOrRemove}
                                                                sx={{ height: 0, padding: 0, paddingLeft: '5px', paddingRight: '5px' }}
                                                            >
                                                                <Icon className={`${stateLocal.isAdding ? "fa-solid fa-plus" : "fa-solid fa-minus"}`}
                                                                    sx={{
                                                                        color: 'var(--laranja)',
                                                                        paddingBottom: '5px'
                                                                    }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                ),
                                                endAdornment: (
                                                    <React.Fragment>
                                                        <h4 className="h4-formatado">(F4)</h4>
                                                        <IconButton
                                                            disabled={isVisualizar()}
                                                            onClick={() => {
                                                                toggleAndResetProcurandoPorProduto(true);
                                                            }}
                                                            sx={{ height: 0, padding: 0, paddingLeft: '5px' }}
                                                        >
                                                            <LocalOfferIcon />
                                                        </IconButton>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />}
                                />}
                        </Grid>
                        <Grid xs={12} sm={6} lg={6} >
                            <Autocomplete
                                disabled={isVisualizar()}
                                onBlur={() => getVendedoresAc()}
                                value={vendedorPrincipal}
                                disablePortal
                                id="acCacliente"
                                options={stateLocal.vendedores}
                                getOptionLabel={(option) => option.descritivo}
                                onInputChange={onInputChangeVendedoresAc}
                                onChange={onSelectVendedor}
                                noOptionsText={'Nenhum resultado encontrado'}
                                isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.uuid}>
                                            {option.descritivo}
                                        </li>
                                    );
                                }}
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            label="Vendedor Principal"
                                            variant="standard"
                                            inputRef={vendedorRef}
                                            InputLabelProps={{
                                                sx: {
                                                    marginLeft: '5px'
                                                }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        <h4 className="h4-formatado">(F6)</h4>
                                                        <Button
                                                            disabled={isVisualizar() || !vendedorPrincipal}
                                                            onMouseOver={() => setStateLocal(prevState => ({ ...prevState, showToolTipMultVendedores: true }))}
                                                            onMouseLeave={() => setStateLocal(prevState => ({ ...prevState, showToolTipMultVendedores: false }))}
                                                            sx={{
                                                                width: 'fit-content',
                                                                minWidth: 'auto',
                                                                height: '20px'
                                                            }}
                                                            onClick={() => {
                                                                setStateLocal(prevState => ({ ...prevState, openMultVendedoresDialog: !stateLocal.openMultVendedoresDialog }));
                                                            }}
                                                        >

                                                            <Badge color="success" badgeContent={outrosVendedores.length}>
                                                                <Icon
                                                                    className="fa-solid fa-users-between-lines"
                                                                    sx={{
                                                                        width: 'fit-content',
                                                                        fontSize: '18px',
                                                                        color: isVisualizar() || !vendedorPrincipal ? 'gray' : 'var(--laranja)',
                                                                        paddingLeft: '5px'
                                                                    }}
                                                                />
                                                            </Badge>
                                                            {stateLocal.showToolTipMultVendedores &&
                                                                <CustomToolTips
                                                                    id={`alt-multvend-id`}
                                                                    position='absolute'
                                                                    bottom='30px'
                                                                    left='-100px'
                                                                    bgColor='#808080'
                                                                    color='#FFF'
                                                                    height='auto'
                                                                    width='250px'
                                                                    zIndex={100}
                                                                    display='flex'
                                                                    justifyContent='center'
                                                                    alignItems='center'
                                                                    borderRadius='10px'
                                                                    isVisible={true}
                                                                    trueText='Clique para gerenciar outros comissionados'
                                                                    falseText=''
                                                                    autoCapitalize={false}
                                                                />
                                                            }
                                                        </Button>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />}
                            />
                        </Grid>
                    </Grid>

                    <InfiniteScrollVirtuoso
                        data={[...produtos, ...produtosTroca]}
                        renderItem={renderItem}
                        height={440}
                        isVisualizar={isVisualizar()}
                        fixedHeaderContent={fixedHeaderContent}
                        fixedFooterContent={fixedFooterContent}
                    />

                    <Footer
                        onClickAvancar={onClickAvancar}
                        onClickCancelar={onClickCancelar}
                        floatingActionsProps={floatingActions}
                        onSwitchOptions={switchOptions}
                        troca={trocaState}
                        isDialogVisible={openFixarDescontoDialog}
                        brinde={stateLocal.isBrinde}
                        onClickBrinde={clickBrinde}
                        isVisualizar={isVisualizar()}
                    />
                </Box >

                <CustomDialog
                    id="desconto-fixo-dialog"
                    open={openFixarDescontoDialog}
                    onClose={onCloseFixarDescontoDialog}
                    onFocus={onFocusDescontoFixoDialog}
                >

                    <form onSubmit={onSaveDescontFixo}>
                        <DialogTitle id="alert-dialog-title" textAlign={'center'}>
                            <HeaderOuBannerDependendoDaInterpretacaoH1
                                id='consulta-estoque'
                                iconClassName='fa-solid fa-percent'
                                label={"Fixar desconto no item"}
                                small
                            />
                        </DialogTitle>

                        <DialogContent sx={{ height: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                            <NumericFormat
                                onFocus={() => descontoFixoTempRef.current?.setSelectionRange(1, 1)}
                                style={{ width: '13rem', }}
                                decimalScale={2}
                                fixedDecimalScale
                                defaultValue={0}
                                allowedDecimalSeparators={[',']}
                                customInput={TextField}
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                variant="standard"
                                allowNegative={false}
                                inputProps={{ style: { textAlign: 'center', fontSize: '24px', color: '#666', fontWeight: 700, fontFamily: 'Work Sans, sans-serif' } }}
                                isAllowed={({ floatValue }) => !floatValue || floatValue <= 100}
                                value={percentualTemp}
                                inputRef={descontoFixoTempRef}
                                onValueChange={({ floatValue }) => onChangeDescontoFixo(floatValue)}

                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <Percent style={{ color: "#df6e0b" }} />
                                        </IconButton>
                                    )
                                }}
                            />
                        </DialogContent>

                        <DialogActions
                            sx={{
                                justifyContent: 'space-between'
                            }}>
                            <CustomButton onClick={onClearDescontoFixo} variant="contained" color="warning" startIcon={<BackspaceIcon />} >
                                Limpar
                            </CustomButton>

                            <CustomButton type="submit" variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                                Salvar
                            </CustomButton>
                        </DialogActions>
                    </form>
                </CustomDialog>

                {stateLocal.showModalDescontoItem &&
                    <AutorizaDesconto
                        onClose={closeAutorizaDesconto}
                        descontoSolicitado={descontoSolicitadoRef}
                        onAutorizaDesconto={handleAutorizaDesconto}
                        descontoMaximo={DescontoMaximo.ITEM}

                    />
                }
                {stateLocal.showLoading &&
                    <CustomLoading />
                }
                {stateLocal.showModalDescontoNaoAutorizado &&
                    <AutorizaDescontoItemError
                        descontoSolicitado={descontoSolicitadoRef}
                        closeModalDescontoItemError={closeAutorizaDesconto}
                        descontoMaximo={DescontoMaximo.ITEM}
                    />
                }
                <CadastroPessoa
                    open={openCadastroPessoaDialog}
                    setOpen={setOpenCadastroPessoaDialog}
                />
                {stateLocal.openMultVendedoresDialog &&
                    <OutrosComissionados
                        onClose={closeMultVendedoresDialog}
                    />
                }
            </Container >
            <ConsultaEstoque open={openConsultaEstoqueDialog} setOpen={setOpenConsultaEstoqueDialog} />
        </>
    );
}
