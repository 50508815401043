import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AbstractModel, IAbstractModel } from "model/abstract.model";
import { IConsumidor } from "model/cliente.model";
import { ICpfNota } from "pages/cpfNota/CpfNota";
import { TDadosTransportador } from "pages/DadosTransportador";
import { FlowVendedorDTO } from "pages/registra-venda/OutrosComissionados";
import { EstoqueTO, FlowVendaTO, IProduto, IProdutoRow, PedidoItemDesconto, StatusPedido, TipoDesconto } from "pages/registra-venda/registra-venda.model";
import { SourceType } from "react-number-format/types/types";
import { deepReset, roundHalfToEven, roundToTwoDecimalPlaces } from "utils/util";

const initialState: { pedido: FlowVendaTO; consultados: string[]; clientePossuiEnderecoForaEstado: boolean } = {
    pedido: {
        uuid: "",
        quantidadeTotal: 0,
        quantidadeTotalTroca: 0,
        valorTotalBruto: 0,
        valorTotalLiquido: 0,
        valorTotalLiquidoTroca: 0,
        finalidadePedido: null,
        emissao: '',
        entrega: '',
        numero: '',
        numeroEntrega: 1,
        statusPedido: StatusPedido.ABERTO,
        cliente: null,
        vendedorPrincipal: null,
        outrosVendedores: [],
        formaComissao: null,
        parcelas: [],
        condicoesPagamento: [],
        itens: [],
        itensTroca: [],
        cpfNota: null,
        itensPagamento: [],
        itensPagamentoDevolucao: [],
        consultados: [],
        tabelaPreco: null,
        pedidoDesconto: {
            uuidAutorizador: null,
            valorDesconto: 0,
            percentualDesconto: 0,
            tipoDesconto: TipoDesconto.PERCENTUAL
        },
        deposito: null,
        estabelecimento: {
            nomeFantasia: "",
            telefone: "",
            endereco: "",
            inscricaoEstadual: "",
            complemento: "",
            municipio: "",
            email: "",
            cep: "",
        },
        observacao: "",
        flowItens: {
            produtos: [],
            condicaoPagamentos: [],
            dadosCartaoTOS: [],
            dadosChequeTOS: []
        },
        transportador: {
            frete: null,
            transportadora: null,
            placa: null,
            uf: null,
            pesoLiquido: 0,
            pesoBruto: 0,
            quantidade: 0,
            especie: null,
            marca: null,
            numeroVolumes: null,
            valorFrete: 0,
            valorSeguro: 0,
        },
        parcelasCrediario: [],
        isVisualizar: false,
        troco: null
    },
    consultados: [],
    clientePossuiEnderecoForaEstado: false,
};

export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setPedido: (s, { payload }: PayloadAction<FlowVendaTO>) => {
            s.pedido = payload;
            updateFooter(s.pedido);
        },
        setStateByProps: ({ pedido }, { payload }: PayloadAction<FlowVendaTO>) => {
            payload.itensTroca.forEach(e => e.isTroca = true)
            pedido.uuid = payload.uuid;
            pedido.cliente = payload.cliente;
            pedido.vendedorPrincipal = payload.vendedorPrincipal;
            pedido.outrosVendedores = payload.outrosVendedores;
            pedido.itens = payload.itens;
            pedido.itensTroca = payload.itensTroca;
            pedido.pedidoDesconto = payload.pedidoDesconto;
            pedido.cpfNota = payload.cpfNota;
            pedido.isVisualizar = payload.isVisualizar;

            recalculaValorDescontoFooter(pedido);
        },
        resetState: s => {
            deepReset(s, initialState);
        },
        setAbstractModel: ({ pedido }, { payload }: PayloadAction<{ propertieName: AbstractModel<FlowVendaTO, IAbstractModel | null>; obj: IAbstractModel | null; }>) => {
            pedido[payload.propertieName] = payload.obj;
        },
        resetAbstractModel: ({ pedido }, { payload }: PayloadAction<AbstractModel<FlowVendaTO, IAbstractModel | null>>) => {
            pedido[payload] = null;
        },
        setCpfNota: ({ pedido }, { payload }: PayloadAction<ICpfNota | null>) => {
            pedido.cpfNota = payload;
        },
        setCliente: ({ pedido }, { payload }: PayloadAction<IConsumidor>) => {
            pedido.cliente = payload;
        },
        addEstoqueItens: (s, { payload }: PayloadAction<{ itens: Array<EstoqueTO> }>) => {
            s.pedido.itens.forEach(e => {
                const foundedItemInResponse = payload.itens.find(el => e.produto.uuid === el.sku.uuid);
                if (e.estoque === -1) e.estoque = 0;
                if (foundedItemInResponse && parseFloat(foundedItemInResponse.saldo) >= 0) e.estoque = Number(foundedItemInResponse.saldo.replace(',', '.'));
                if (!s.pedido.consultados.includes(e.produto.uuid)) s.pedido.consultados.push(e.produto.uuid);
            });
        },
        atualizarEstoqueItens: s => {
            const adicionadosAnteriormente = s.pedido.itens.filter(p => p.estoque !== -1);
            s.pedido.itens.forEach(p => {
                const newItem = p.estoque === -1;
                if (newItem) {
                    const findedItemJaAdicionado = adicionadosAnteriormente.find(a => a.produto.uuid === p.produto.uuid);
                    p.estoque = findedItemJaAdicionado?.estoque ?? 0;
                }
            });
        },
        removeEstoqueItens: (s, { payload }: PayloadAction<IProdutoRow>) => {
            s.pedido.itens.forEach(e => {
                s.pedido.consultados.splice(s.pedido.consultados.indexOf(payload.produto.uuid), 1);
            });
        },
        addSku: ({ pedido }, { payload }: PayloadAction<IProdutoRow>) => {
            payload.valorTotal = roundHalfToEven(roundToTwoDecimalPlaces(payload.valorTotal), 2);
            pedido.itens.unshift(payload);
            recalculaValorDescontoFooter(pedido);
        },
        addSkuTroca: ({ pedido }, { payload }: PayloadAction<IProdutoRow>) => {
            payload.valorTotal = roundHalfToEven(roundToTwoDecimalPlaces(payload.valorTotal), 2);
            pedido.itensTroca.unshift(payload);
            recalculaValorDescontoFooter(pedido);
        },
        // removeSku
        setProdutos: ({ pedido }, { payload }: PayloadAction<Array<IProdutoRow>>) => {
            pedido.itens = payload;
            recalculaValorDescontoFooter(pedido);
        },
        // removeSkuTroca
        setProdutosTroca: ({ pedido }, { payload }: PayloadAction<Array<IProdutoRow>>) => {
            pedido.itensTroca = payload;
            recalculaValorDescontoFooter(pedido);
        },
        setQuantidadeItem({ pedido }, { payload }: PayloadAction<{ uuid: string, quantidade: number; }>) {
            const find = pedido.itens.find(e => e.uuid === payload.uuid);
            if (find) {
                find.quantidade = payload.quantidade;
                recalculaValorDescontoRow(find);
                recalculaValorDescontoFooter(pedido);
            }
        },
        setQuantidadeItemTroca({ pedido }, { payload }: PayloadAction<{ uuid: string, quantidade: number; }>) {
            const find = pedido.itensTroca.find(e => e.uuid === payload.uuid);
            if (find) {
                find.quantidade = payload.quantidade;
                recalculaValorDescontoRow(find);
                recalculaValorDescontoFooter(pedido);
            }
        },
        setDescontoItem: ({ pedido }, { payload }: PayloadAction<{ uuid: string; value: number; }>) => {
            const find = pedido.itens.find(e => e.uuid === payload.uuid);
            if (find) {
                setDescItem(find, payload.value);
                recalculaValorDescontoFooter(pedido);
            }
        },
        setDescontoItemTroca: ({ pedido }, { payload }: PayloadAction<{ uuid: string; value: number; }>) => {
            const find = pedido.itensTroca.find(e => e.uuid === payload.uuid);
            if (find) {
                setDescItem(find, payload.value);
                updateFooterTroca(pedido);
            }
        },
        toggleTipoDescontoItem: ({ pedido }, { payload }: PayloadAction<{ uuid: string }>) => {
            const find = pedido.itens.find(e => e.uuid === payload.uuid);
            if (find) {
                find.itemDesconto.tipoDesconto = find.itemDesconto.tipoDesconto === TipoDesconto.PERCENTUAL ? TipoDesconto.VALOR : TipoDesconto.PERCENTUAL;
            }
        },
        toggleTipoDescontoItemTroca: ({ pedido }, { payload }: PayloadAction<{ uuid: string }>) => {
            const find = pedido.itensTroca.find(e => e.uuid === payload.uuid);
            if (find) {
                find.itemDesconto.tipoDesconto = find.itemDesconto.tipoDesconto === TipoDesconto.PERCENTUAL ? TipoDesconto.VALOR : TipoDesconto.PERCENTUAL;
            }
        },
        setDescontoFooter: ({ pedido }, { payload }: PayloadAction<{ value: number; }>) => {
            setDescFooter(pedido, payload.value);
        },
        resetDescontoFooter: (s) => {
            s.pedido.pedidoDesconto.valorDesconto = initialState.pedido.pedidoDesconto.valorDesconto;
            s.pedido.pedidoDesconto.percentualDesconto = initialState.pedido.pedidoDesconto.percentualDesconto;
            s.pedido.valorTotalLiquido = s.pedido.valorTotalBruto;
        },
        toogleTipoDescontFooter: ({ pedido }) => {
            pedido.pedidoDesconto.tipoDesconto = pedido.pedidoDesconto.tipoDesconto === TipoDesconto.PERCENTUAL ? TipoDesconto.VALOR : TipoDesconto.PERCENTUAL;
        },
        setValorLiquido: (s, { payload }: PayloadAction<{ value: number; sourceEvent: SourceType; }>) => {
            const { pedido } = s;
            if (payload.sourceEvent === 'event') {
                const valorDesconto = (pedido.valorTotalBruto - payload.value) - pedido.valorTotalLiquidoTroca;
                pedido.pedidoDesconto.percentualDesconto = (valorDesconto / pedido.valorTotalBruto) * 100;
                pedido.pedidoDesconto.valorDesconto = valorDesconto;
                updateFooter(pedido);
            }
        },
        changeTabelaPrecoPadraoPedido: ({ pedido }, { payload }: PayloadAction<IAbstractModel>) => {
            pedido.tabelaPreco = payload;
            pedido.tabelaPreco = payload;
        },
        atualizaValoresItensPedido: ({ pedido }, { payload }: PayloadAction<Array<IProduto | null>>) => {
            for (let i = pedido.itens.length - 1; i >= 0; i--) {
                const foundItem = payload.find(el => pedido.itens[i].produto.uuid === el?.uuid);
                if (!foundItem) {
                    pedido.itens.splice(i, 1);
                }
                else {
                    pedido.itens[i].produto.preco = foundItem.preco;
                    pedido.itens[i].valorTotal = foundItem.preco * pedido.itens[i].quantidade;
                }
            }
            for (let i = pedido.itensTroca.length - 1; i >= 0; i--) {
                const foundItem = payload.find(el => pedido.itensTroca[i].produto.uuid === el?.uuid);
                if (!foundItem) {
                    pedido.itensTroca.splice(i, 1);
                }
                else {
                    pedido.itensTroca[i].produto.preco = foundItem.preco;
                    pedido.itensTroca[i].valorTotal = foundItem.preco * pedido.itensTroca[i].quantidade;
                }
            }
            recalculaValorDescontoFooter(pedido);
            updateFooter(pedido);
            updateFooterTroca(pedido);
        },
        resetSkusConsultados: (s) => {
            s.pedido.consultados = [];
        },
        addItemDesconto({ pedido }, { payload }: PayloadAction<{ uuid: string, itemDesconto: PedidoItemDesconto; }>) {
            const find = pedido.itens.find(e => e.uuid === payload.uuid)!;
            find.itemDesconto = payload.itemDesconto;
        },
        addPedidoDesconto({ pedido }, { payload }: PayloadAction<{ itemDesconto: PedidoItemDesconto; }>) {
            pedido.pedidoDesconto = payload.itemDesconto;
            updateFooter(pedido);
        },
        setTransportador({ pedido }, { payload }: PayloadAction<TDadosTransportador>) {
            pedido.transportador = payload;
        },
        setVendedorPrincipal({ pedido }, { payload }: PayloadAction<FlowVendedorDTO | null>) {
            pedido.vendedorPrincipal = payload;
        },
        setOutrosVendedores({ pedido }, { payload }: PayloadAction<FlowVendedorDTO[]>) {
            pedido.outrosVendedores = payload;
        },
        setBrinde({ pedido }, { payload }: PayloadAction<string>) {
            pedido.itens = pedido.itens.map((item) =>
                item.uuid === payload ? { ...item, isBrinde: !item.isBrinde } : item
            );
            updateFooter(pedido);
            recalculaValorDescontoFooter(pedido);
        },
        removeSku({ pedido }, { payload }: PayloadAction<IProdutoRow>) {
            const index = pedido.itens.findIndex(e => e.produto.codigoSku === payload.produto.codigoSku)
            if (index !== -1) {
                const item = pedido.itens.find((_, i) => i === index)
                if (item) {
                    if (item.quantidade > 1) {
                        item.quantidade -= 1
                    } else {
                        pedido.itens = pedido.itens.filter((_, i) => i !== index)
                    }
                }
                recalculaValorDescontoFooter(pedido)
                updateFooter(pedido)
            }
        },
        removeSkuTrocas({ pedido }, { payload }: PayloadAction<IProdutoRow>) {
            const index = pedido.itensTroca.findIndex(e => e.produto.codigoSku === payload.produto.codigoSku)
            if (index !== -1) {
                const item = pedido.itensTroca.find((_, i) => i === index)
                if (item) {
                    if (item.quantidade > 1) {
                        item.quantidade -= 1
                    } else {
                        pedido.itensTroca = pedido.itensTroca.filter((_, i) => i !== index)
                    }
                }
                recalculaValorDescontoFooter(pedido)
                updateFooterTroca(pedido)
            }
        },
        setClientePossuiEnderecoForaEstado(state, { payload }: PayloadAction<boolean>) {
            state.clientePossuiEnderecoForaEstado = payload;
        },
    }
});

export const {
    setPedido,
    setStateByProps,
    resetState,
    setAbstractModel,
    resetAbstractModel,
    addEstoqueItens,
    removeEstoqueItens,
    addSku,
    addSkuTroca,
    setProdutos,
    setProdutosTroca,
    toggleTipoDescontoItem,
    toggleTipoDescontoItemTroca,
    setQuantidadeItem,
    setQuantidadeItemTroca,
    setDescontoItem,
    setDescontoItemTroca,
    toogleTipoDescontFooter,
    setDescontoFooter,
    setValorLiquido,
    setCpfNota,
    setCliente,
    resetDescontoFooter,
    changeTabelaPrecoPadraoPedido,
    atualizaValoresItensPedido,
    resetSkusConsultados,
    atualizarEstoqueItens,
    addItemDesconto,
    addPedidoDesconto,
    setTransportador,
    setVendedorPrincipal,
    setOutrosVendedores,
    setBrinde,
    removeSku,
    removeSkuTrocas,
    setClientePossuiEnderecoForaEstado,
} = stateSlice.actions;
export const stateReducer = stateSlice.reducer;


// FAVOR MANTER MÉTODOS ORGANIZADOS POR SEÇÃO
//================================ FOOTER ================================//
/**
 * Atualiza: 
 * quantidadeTotal
 * valorTotalBruto
 * valorTotalLiquido
 */

const updateFooter = (pedido: FlowVendaTO): void => {
    const initial = {
        quantidadeTotal: 0,
        valorTotalBruto: 0,
        valorTotalLiquido: 0,
        valorDesconto: pedido.pedidoDesconto.valorDesconto,
    };

    const novoPedido = pedido.itens.reduce((acc, row) => {
        acc.quantidadeTotal += !row.isBrinde ? row.quantidade : 0;
        acc.valorTotalBruto += roundHalfToEven(roundToTwoDecimalPlaces(row.valorTotal), 2);
        acc.valorTotalLiquido = acc.valorTotalBruto - acc.valorDesconto;
        return acc;
    }, initial);

    novoPedido.valorTotalLiquido = roundHalfToEven(roundToTwoDecimalPlaces(novoPedido.valorTotalLiquido), 2);
    Object.assign(pedido, novoPedido);
};

const updateFooterTroca = (pedido: FlowVendaTO): void => {
    const initial = {
        quantidadeTotalTroca: 0,
        valorTotalLiquidoTroca: 0
    };

    const novoPedido = pedido.itensTroca.reduce((acc, row) => {
        acc.quantidadeTotalTroca += row.quantidade;
        acc.valorTotalLiquidoTroca += roundHalfToEven(roundToTwoDecimalPlaces(row.valorTotal), 2);
        return acc;
    }, initial);
    Object.assign(pedido, novoPedido);
};

const setDescFooter = (pedido: FlowVendaTO, valor: number) => {
    if (pedido.pedidoDesconto.tipoDesconto === TipoDesconto.PERCENTUAL) {
        setPorcentagemDescontoFooter(pedido, valor);
    } else {
        setValorDescontoFooter(pedido, valor);
    }
};

const setPorcentagemDescontoFooter = (pedido: FlowVendaTO, percentualDesconto: number) => {
    pedido.pedidoDesconto.percentualDesconto = percentualDesconto;
    const valorBrindes = pedido.itens.filter(e => e.isBrinde).reduce((prev, act) => prev + act.valorTotal, 0);
    pedido.pedidoDesconto.valorDesconto = calcularValor((pedido.valorTotalBruto - valorBrindes), percentualDesconto);
    updateFooter(pedido);
    updateFooterTroca(pedido);
};

const setValorDescontoFooter = (pedido: FlowVendaTO, valorDesconto: number) => {
    pedido.pedidoDesconto.valorDesconto = valorDesconto;
    const valorBrindes = pedido.itens.filter(e => e.isBrinde).reduce((prev, act) => prev + act.valorTotal, 0);
    pedido.pedidoDesconto.percentualDesconto = calcularPorcentagem((pedido.valorTotalBruto - valorBrindes), valorDesconto);
    updateFooter(pedido);
    updateFooterTroca(pedido);
};

/**
 * mantem o percentualDesconto
 * atualiza o valorDesconto
 * atualiza o valorTotalLiquido
 */
const recalculaValorDescontoFooter = (pedido: FlowVendaTO) => {
    recalculaValorTotalBruto(pedido);
    setPorcentagemDescontoFooter(pedido, pedido.pedidoDesconto.percentualDesconto);
};

const recalculaValorTotalBruto = (pedido: FlowVendaTO) => {
    const valorTotalBruto = pedido.itens.map(s => (s.produto.preco * s.quantidade) - s.itemDesconto.valorDesconto).reduce((acc, row) => acc += row, 0);
    pedido.valorTotalBruto = roundHalfToEven(valorTotalBruto, 2);
};

//================================ ROW ================================//
const setDescItem = (produto: IProdutoRow, valor: number) => {
    if (produto.itemDesconto.tipoDesconto === TipoDesconto.PERCENTUAL) {
        setPorcentagemDescontoRow(produto, valor);
    } else {
        setValorDescontoRow(produto, valor);
    }
};


const setPorcentagemDescontoRow = (produto: IProdutoRow, percentualDesconto: number) => {
    //Novo
    produto.itemDesconto.percentualDesconto = percentualDesconto;
    produto.itemDesconto.valorDesconto = calcularValor(getValorBrutoSku(produto), percentualDesconto);
    updateRow(produto);
};

const setValorDescontoRow = (produto: IProdutoRow, valorDesconto: number) => {
    produto.itemDesconto.valorDesconto = valorDesconto;
    produto.itemDesconto.percentualDesconto = calcularPorcentagem(getValorBrutoSku(produto), valorDesconto);
    updateRow(produto);
};

const updateRow = (produto: IProdutoRow): void => {
    const desconto = roundHalfToEven(roundToTwoDecimalPlaces((produto.produto.preco * (produto.itemDesconto.percentualDesconto / 100))), 2);
    produto.valorTotal = getValorBrutoSku(produto) - roundToTwoDecimalPlaces(desconto * produto.quantidade);
};

const getValorBrutoSku = (produto: IProdutoRow) => {
    return roundToTwoDecimalPlaces(produto.quantidade * produto.produto.preco);
};

/**
 * mantem o percentualDesconto do sku
 * atualiza o valorDesconto do sku
 * atualiza o valorTotal do sku
 */
const recalculaValorDescontoRow = (produto: IProdutoRow) => {
    produto.valorTotal = roundToTwoDecimalPlaces(getValorBrutoSku(produto));
    setPorcentagemDescontoRow(produto, produto.itemDesconto.percentualDesconto);
};

//================================ GERAL ================================//

const calcularPorcentagem = (valorTotal: number, valor: number) => {
    let toReturn = 0;
    if (valorTotal > 0) {
        const valorCalculado = valor * 100 / valorTotal;
        toReturn = roundHalfToEven(valorCalculado, 2);
    }
    return toReturn;
};

const calcularValor = (valorTotal: number, porcentagem: number) => {
    const toReturn = valorTotal * porcentagem / 100;
    return roundHalfToEven(toReturn, 2);
};
