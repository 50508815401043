import { AxiosResponse } from "axios";
import { IAbstractModel } from "model/abstract.model";
import LoginService from "pages/login/login.service";
import { ICaixaSlice } from "redux/features/caixaSlice";
import { axiosRequest } from "../../axios/axios.config";
import { ICaixasDisponivel, IMovimentoCaixa, TRelatorioFechamentoCaixa, TResgateRelatorioTO, TipoGrupoConta } from "./caixa.model";
import { TRelatorioCaixa } from "./relatorio-caixa/relatorio-caixa.model";

const CaixaService = () => {
    const path = '/flow';
    const loginService = LoginService();

    return {
        getCaixas(): Promise<AxiosResponse<Array<ICaixasDisponivel>>> {
            return axiosRequest.get(`${path}/caixadisponivel`, { headers: loginService.getHeaders() });
        },
        getContas(toSearch: string, tiposGrupoConta: Array<TipoGrupoConta>): Promise<AxiosResponse<Array<IAbstractModel>>> {
            return axiosRequest.get(`${path}/contas`, { headers: loginService.getHeaders(), params: { toSearch, tiposGrupoConta: tiposGrupoConta.join(",") } });
        },
        getSaldoConta(uuid: string): Promise<AxiosResponse<ICaixaSlice>> {
            return axiosRequest.get(`${path}/saldocaixa/${uuid}`, { headers: loginService.getHeaders() });
        },
        movimentoCaixa(caixa: IMovimentoCaixa): Promise<AxiosResponse<IMovimentoCaixa>> {
            return axiosRequest.post(`${path}/movimentocaixa`, caixa, { headers: loginService.getHeaders() });
        },
        getRelatorioCaixa(): Promise<AxiosResponse<TRelatorioCaixa>> {
            return axiosRequest.post(`${path}/relatoriocaixa`, null, { headers: loginService.getHeaders() });
        },
        getFechamentosCaixa(data: TResgateRelatorioTO): Promise<AxiosResponse<Array<TRelatorioFechamentoCaixa>>> {
            return axiosRequest.post(`${path}/relatorio/fechamentocaixa`, data, { headers: loginService.getHeaders() });
        },
        getResgataFechamento(data: TRelatorioFechamentoCaixa): Promise<AxiosResponse<TRelatorioCaixa>> {
            return axiosRequest.post(`${path}/resgaterelatoriofechamento`, data, { headers: loginService.getHeaders() });
        }
    };
};

export default CaixaService;
