import BaseLayoutConfiguracoes from "./BaseLayoutConfiguracoes";
import { Autocomplete, AutocompleteRenderOptionState, FormControl, ListItemText, MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { IAbstractModel } from "model/abstract.model";
import ConfiguracaoDescontoService from "./Configuracoes.service";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { useAppSelector } from "redux/hooks";
import CustomLoading from "pages/Venda/Header/CustomLoading";
import CustomTableDollar from "pages/Venda/CustomTableDollar";
import HeaderOuBannerDependendoDaInterpretacaoH1 from "layout/header-ou-banner-dependendo-da-interpretacao-h1/HeaderOuBannerDependendoDaInterpretacaoH1";

type TConfigurarCobrancaTabelaPreco = {
    onGoBack: () => void;
};

type TConfiguracaoState = {
    cobrancas: Array<IAbstractModel>;
    cobranca: IAbstractModel | null;
    refreshing: boolean;
};

const initialState: TConfiguracaoState = {
    cobrancas: [],
    cobranca: null,
    refreshing: false
};

const ConfigurarCobrancaTabelaDeposito = (props: TConfigurarCobrancaTabelaPreco) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const configuracaoService = ConfiguracaoDescontoService();
    const { addError, addInfo, addSuccess, closeAllSnacks } = useSnackbarCustom();
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);

    const getCobrancas = () => {
        closeAllSnacks();
        setStateLocal(prevState => ({ ...prevState, refreshing: true }));
        configuracaoService.getCobrancas()
            .then(({ data }: { data: Array<IAbstractModel>; }) => {
                if (data.length > 0) {
                    return setStateLocal(prevState => ({ ...prevState, cobrancas: data, refreshing: false }));
                }
                setStateLocal(prevState => ({ ...prevState, cobrancas: data, refreshing: false }));
                addInfo({ message: `Não existem cobranças cadastradas para o estabelecimento ${estabelecimento.descritivo}`, closeable: true, id: "get-cobrancas-info" });
            })
            .catch(err => {
                setStateLocal(prevState => ({ ...prevState, refreshing: false }));
                addError({ message: "Não foi possivel carregar suas cobranças. Se o erro persistir, contate o suporte", closeable: true, id: "get-cobrancas-error" });
            });

        configuracaoService.getCobrancaPadrao()
            .then(({ data }: { data: IAbstractModel; }) => {
                if (data.descritivo && data.uuid) {
                    setStateLocal(prevState => ({ ...prevState, cobranca: data }));
                }
            })
            .catch((err) => {
                addError({ message: "Não foi possivel carregar sua cobrança padrão. Se o erro persistir, contate o suporte", closeable: true, id: "get-cobranca-padrao-error" });
            });
    };

    const firstRenderRef = useRef<boolean>(true);

    useEffect(() => {
        getCobrancas();
        firstRenderRef.current = false;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!firstRenderRef.current) {
            getCobrancas();
        }
        // eslint-disable-next-line
    }, [estabelecimento.uuid]);

    const handleMenuItemClick = (descritivo: string) => {
        const found = stateLocal.cobrancas.find(e => e.descritivo === descritivo);
        if (found) {
            setStateLocal(prevState => ({ ...prevState, cobranca: found }));
        }

    };

    const clickSalvar = () => {
        const cobrancaTO = stateLocal.cobrancas.find(e => e.uuid === stateLocal.cobranca?.uuid);
        configuracaoService.updateCobranca(cobrancaTO ?? { uuid: "", descritivo: "" })
            .then(({ data }: { data: IAbstractModel; }) => {
                if (data.uuid) {
                    addSuccess({ message: `Cobrança ${data.descritivo} atualizada com sucesso` });
                } else {
                    setStateLocal(prevState => ({ ...prevState, cobranca: initialState.cobranca }));
                    addSuccess({ message: `Cobrança removida com sucesso` });
                }
                return props.onGoBack();

            })
            .catch(err => {
                addError({ message: "Não foi possivel atualizar sua cobrança. Se o erro persistir, contate o suporte", closeable: true, id: "save-cobranca-padrao-error" });
            });
    };

    const onClear = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, cobranca: initialState.cobranca }));
    }, []);

    const renderOptions = (option: IAbstractModel, state: AutocompleteRenderOptionState) => {
        return (
            <MenuItem
                onClick={handleMenuItemClick.bind(null, option.descritivo)}
                key={Math.random()}
                value={option.descritivo}>

                <ListItemText primary={option.descritivo} />
            </MenuItem>
        );
    };

    return (
        <>
            <HeaderOuBannerDependendoDaInterpretacaoH1
                id='configuracoes-cobranca'
                customIcon={
                    <CustomTableDollar id="custom_table_dollar_icon_configuracoes" color="#666666" fontSize="60px" style={{ marginBottom: '5px' }} />
                }
                label="Configurar cobrança"
            />

            <BaseLayoutConfiguracoes
                onGoBack={props.onGoBack}
                onClickSalvar={clickSalvar}
                title="Definir cobrança bancária"
                showCancelConfirm
            >
                <FormControl>
                    <Autocomplete
                        sx={{ maxHeight: '200px', width: "600px", overflowX: 'hidden' }}
                        filterSelectedOptions
                        value={stateLocal.cobranca}
                        options={stateLocal.cobrancas}
                        getOptionLabel={(option) => option.descritivo}
                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                        onInputChange={(e) => {
                            if (e && e.type === "click") { onClear(); }
                        }}
                        noOptionsText="Nenhum valor encontrado"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Cobrança bancária"
                            />
                        )}
                        renderOption={(_props, option, state, _ownerState) => renderOptions(option, state)}
                    />
                </FormControl>
            </BaseLayoutConfiguracoes>
            {stateLocal.refreshing &&
                <CustomLoading />
            }
        </>
    );
};

export default ConfigurarCobrancaTabelaDeposito;
