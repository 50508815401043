import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import { ICaixasDisponivel, IMovimentoCaixa } from "./caixa.model";

export type FlowTransferenciaCaixaPayload = {
  caixaDestino: string;
  valor: number;
  descricao?: string | undefined | null;
};

const CaixaTransferenciaService = () => {
  const path = "/flow/caixa/transferencia";
  const loginService = LoginService();

  return {
    transferir(
      payload: FlowTransferenciaCaixaPayload
    ): Promise<AxiosResponse<IMovimentoCaixa>> {
      return axiosRequest.post(`${path}`, payload, {
        headers: loginService.getHeaders(),
      });
    },
    getTodosCaixas(): Promise<AxiosResponse<ICaixasDisponivel[]>> {
      return axiosRequest.get(`${path}/listcaixas`, {
        headers: loginService.getHeaders(),
      });
    },
  };
};

export default CaixaTransferenciaService;
