import { Document, Image, Line, Page, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { ColumnsRelatorioPDF, ItemRelatorioCaixa, TRelatorioCaixa } from 'pages/Caixa/relatorio-caixa/relatorio-caixa.model';
import { currencyOf } from 'utils/util';
import { TablePDF } from './TablePdf';

const s = StyleSheet.create({
    pdfView: {
        width: '100%',
        height: '100vh',
        border: 0,
    },
    page: {
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        padding: '30px 0'
    },
    wrapLogo: {
        width: '100px',
        height: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FF7F01',
        paddingHorizontal: 5,
        borderRadius: '5px'
    },
    wrapPageNumber: {
        width: '95%',
        position: 'absolute',
        bottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10'
    },
    pageNumber: {
        fontSize: 10,
        color: 'grey',
    },
    title: {
        fontSize: 28,
        marginBottom: '10px'
    },
    info: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '95%',
        marginBottom: '10px',
        gap: '5px'
    },
    wrapInfoItem: {
        width: '24%',
        marginTop: '6px'
    },
    infoItem: {
        fontSize: '10px'
    },
    containerFormaPgt: {
        width: '95%',
        marginTop: '20px'
    },
    wrapFormaPgt: {
        alignSelf: 'flex-end',
        width: '40%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    formaPgt: {
        fontSize: '10px'
    }
});

const tableHeadersSangriaReforco: ColumnsRelatorioPDF<any> = [
    {
        key: 'descricao',
        sizePercent: '30',
        label: 'Descrição'
    },
    {
        key: 'observacao',
        sizePercent: '30',
        label: 'Observação'
    },
    {
        key: 'contaApropriacao',
        sizePercent: '30',
        label: 'Conta'
    },
    {
        key: 'valor',
        sizePercent: '10',
        type: 'currency',
        label: 'Valor'
    },
];

type Props = {
    headers: ColumnsRelatorioPDF<ItemRelatorioCaixa>;
    relatorioCaixa: TRelatorioCaixa;
    caixaName: string;
    dadosUser: {
        estabelecimento: string,
        username: string,
        hora: string;
    };
};

const PdfTemplate = (props: Props) => {
    return (<>
        {/* Descomentar para ter o visualizador de pdf */}
        {/* <PDFViewer
            style={s.pdfView}
            showToolbar={true}
        > */}
        <Document
            title='Relatorio de caixa'
            author={props.dadosUser.username}
            language='pt-BR'
        >
            <Page
                size={'A4'}
                orientation='portrait'
                wrap={true}
                style={s.page}
            >

                {/* TITLE */}

                <Text
                    style={s.title}
                    fixed
                >
                    Relatório de caixa
                </Text>

                <Svg fixed height={1}>
                    <Line x1={10} x2={585} y1={0} y2={0} strokeWidth={1}
                        stroke="#999" />
                </Svg>

                {/* INFO */}

                <View style={s.info}>
                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Estabelecimento:
                        </Text>
                        <Text style={s.infoItem}>
                            {props.dadosUser.estabelecimento}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Usuario:
                        </Text>
                        <Text style={s.infoItem}>
                            {props.dadosUser.username}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Data e hora:
                        </Text>
                        <Text style={s.infoItem}>
                            {new Date(props.dadosUser.hora).toLocaleString('pt-BR')}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Abertura:
                        </Text>
                        <Text style={s.infoItem}>
                            {currencyOf(props.relatorioCaixa.caixa.abertura)}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Saldo inicial:
                        </Text>
                        <Text style={s.infoItem}>
                            {currencyOf(props.relatorioCaixa.caixa.saldoInicial)}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Saldo final:
                        </Text>
                        <Text style={s.infoItem}>
                            {currencyOf(props.relatorioCaixa.caixa.saldoFinalDinheiro)}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Reforço:
                        </Text>
                        <Text style={s.infoItem}>
                            {currencyOf(props.relatorioCaixa.caixa.reforco)}
                        </Text>
                    </View>

                    <View style={s.wrapInfoItem}>
                        <Text style={s.infoItem}>
                            Sangria:
                        </Text>
                        <Text style={s.infoItem}>
                            {currencyOf(props.relatorioCaixa.caixa.sangria)}
                        </Text>
                    </View>
                </View>

                {/* TABELA */}
                <TablePDF
                    headerSectionText='Vendas'
                    headers={props.headers}
                    itens={props.relatorioCaixa.itens}
                    strippedRow={true}
                    totalizadores={props.relatorioCaixa.totalizadores}
                    marginTop={10}
                />

                <TablePDF
                    headerSectionText='Sangria'
                    itens={props.relatorioCaixa.caixa.historicoSangria}
                    strippedRow={true}
                    headers={tableHeadersSangriaReforco}
                    marginTop={20}
                />

                <TablePDF
                    headerSectionText='Reforço'
                    itens={props.relatorioCaixa.caixa.historicoReforco}
                    strippedRow={true}
                    headers={tableHeadersSangriaReforco}
                    marginTop={20}
                />

                <TablePDF
                    headerSectionText='Vale Presente'
                    itens={props.relatorioCaixa.caixa.historicoValePresente}
                    strippedRow={true}
                    headers={tableHeadersSangriaReforco}
                    marginTop={20}
                />

                {/* FORMAS PGT */}

                <View wrap={false} style={s.containerFormaPgt}>
                    {props.relatorioCaixa.totalizadoresFormaPagamento.map((forma, idx) => {
                        return <View style={s.wrapFormaPgt} key={idx}>
                            <Text style={s.formaPgt}>{forma.nome}:</Text>;
                            <Text style={s.formaPgt}>{currencyOf(forma.valor)}</Text>;
                        </View>;
                    })}
                </View>

                <View
                    fixed
                    style={s.wrapPageNumber}>
                    <View style={s.wrapLogo}>
                        <Image
                            src={'assets/logoFlow.png'}
                        />
                    </View>
                    <Text
                        style={s.pageNumber}
                        render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )}
                    />
                </View>
            </Page>
        </Document>
        {/* </PDFViewer> */}
    </>
    );
};

export default PdfTemplate;
