import { Box, TextField } from "@mui/material"

export type NSuNuControleProps = {
    nsu: string,
    numControle: string,
    onChangeNsu: (value: string) => void,
    onChangeNumControle: (value: string) => void
    obrigaNSU: boolean,
    obrigaNumeroControle: boolean
}

const NsuNumControle = (props: NSuNuControleProps) => {
    return (
        <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', marginTop: '25px', marginBottom: '25px', width: '100%' }}>
            <TextField
                multiline
                id="nsu-textField"
                variant="standard"
                value={props.nsu}
                onChange={(e) => props.onChangeNsu(e.target.value)}
                label="NSU"
                inputProps={{ maxLength: 30 }}
                required={props.obrigaNSU}
            />

            <TextField
                multiline
                id="numero-controle-textField"
                variant="standard"
                value={props.numControle}
                onChange={(e) => props.onChangeNumControle(e.target.value)}
                label="Número de controle"
                inputProps={{ maxLength: 30 }}
                required={props.obrigaNumeroControle}
            />
        </Box>
    )
}

export default NsuNumControle