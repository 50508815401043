export interface IAbstractEntity {
    uuid: string;
    id?: number;
    version?: number | null;
    status?: string;
}

export interface IAbstractModel extends IAbstractEntity, IDescritivo { };

export type AbstractModel<T, R> = {
    [K in keyof T]: T[K] extends R ? K : never;
}[keyof T];

export interface IAbstractEnum extends IDescritivo {
    name: string;
}

export interface IDescritivo {
    descritivo: string;
}

export function getEmptyAbstractModel(): IAbstractModel {
    return { uuid: '', descritivo: '' };
}
export function resetAbstractModel(model: IAbstractModel): void {
    delete model.id;
    delete model.version;
    Object.assign(model, getEmptyAbstractModel());
}

export enum TipoRestricaoPessoa {
    TELEFONE = "TELEFONE",
    ENDERECO_ELETRONICO = "ENDERECO_ELETRONICO"
}

export type ConfiguracaoPessoaDTO = {
    tipoRestricao: TipoRestricaoPessoa,
    restricoes: Array<string>
}

export type FlowConfiguracaoPoliticaVendaDTO = {
    version?: number;
    obrigaNsu: boolean,
    obrigaNumeroControle: boolean
}