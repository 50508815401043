import DialogContext from "components/confirm-dialog-context";
import { FloatingActionsProps } from "components/floatingactions/FloatingActions";
import TelaFormasPagamentoComponent, { getFormaPagamentoAbstracModel } from "components/telaformaspagamento/TelaFormasPagamentoComponent";
import useCatchApiError from "hooks/useCatchApiError";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { IAbstractModel } from "model/abstract.model";
import { TipoPessoa } from "model/cliente.model";
import PdfComprovanteCrediario from "pages/Comprovante-nota-fiscal-outros/PdfComprovanteCrediarios";
import PdfComprovanteTemplate from "pages/Comprovante-nota-fiscal-outros/PdfComprovanteTemplate";
import CpfNota from "pages/cpfNota/CpfNota";
import DadosTransportador from "pages/DadosTransportador";
import { FlowEventoNotaEmitidaTypeDTO, isQualquerTipoSaidaAutorizada, possuiRejeicao, shouldShowDialogEventoNota } from "pages/evento-nota-fiscal/evento-nota-fiscal.model";
import EventoNotaFiscal, { StateEventoNotaDialog } from "pages/evento-nota-fiscal/EventoNotaFiscal";
import { FlowVendaTO, StatusPedido, TPagamentoReceivedProps } from "pages/registra-venda/registra-venda.model";
import RegistraVendaService from "pages/registra-venda/registra-venda.service";
import CustomIFrame from "pages/Venda/Header/CustomIFrame";
import VendaService from "pages/Venda/venda.service";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { changeLinearFinalizaVenda, completeLoading, loadingWithOpt, resetLoading, Tipo } from "redux/features/blockuiSlice";
import { resetDescontoAutorizador, setDeposito, setTabelaPreco } from "redux/features/configuracoes";
import { resetDesconto } from "redux/features/descontoFixoSlice";
import { addRow, addRowDevolucao, resetPagamento, resetRows, SelectedRow } from "redux/features/pagamentoSlice";
import { resetState } from "redux/features/stateSlice";
import { setActiveStep } from "redux/features/stepperSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PlanoContaService from "service/plano-conta.service";
import { isConsumidor } from "utils/util";
import { FlowPedidoCieloIsPagoTO } from "./formapagamento/formapagamento.model";
import ModalTroco from "./ModalTroco";
import { AcaoFinalizarFluxo, FinalizarFluxoParam, IPagamentoRow, RespostaFinalizarVenda, TipoInformacaoAdicional } from "./pagamento.model";

const Pagamento = () => {
    const dispatch = useAppDispatch();
    const { pedido } = useAppSelector(s => s.state);
    const isSalvandoPedidoRef = useRef<boolean>(false);
    const { descritivo: descritivoEstabelecimento } = useAppSelector(e => e.estabelecimentoSelecionado);
    const location = useLocation();
    const { throwErrorMessage } = useCatchApiError();
    const receivedParams: TPagamentoReceivedProps = location.state;
    const { token: tokenAutorizador } = useAppSelector(e => e.configuracoes.configuracaoDescontoAutorizador);
    const configuracaoBalcao = useAppSelector(e => e.configuracoes.configuracaoPedidoBalcao);

    const { quantidadeTotal } = useAppSelector(e => e.state.pedido);
    const { faltaReceber, tableRows, troco } = useAppSelector(s => s.pagamento);
    const faltaReceberRef = useRef<number>(faltaReceber);
    const {
        valorTotalLiquido,
    } = pedido;

    const initialState = {
        showDadosTransportador: false as boolean,
        showComprovanteNFOutros: {
            open: false as boolean,
            pedido: null as FlowVendaTO | null
        },
        showComprovanteCrediario: {
            open: false as boolean,
            pedido: null as FlowVendaTO | null
        },
        frameDanfeState: {
            open: false as boolean,
            url: '' as string
        },
        frameBoletoState: false as boolean,
        editRow: null as SelectedRow | null,
        showTrocaModal: false as boolean
    };

    const [stateLocal, setStateLocal] = useState(initialState);
    const { cliente } = useAppSelector(e => e.state.pedido);
    const finalizarVendasQtdRef = useRef<number>(0);

    const closeDadosTransportador = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showDadosTransportador: false }));
    }, []);

    const confirmDadosTransportador = () => {
        closeDadosTransportador();
        onFinalizarFluxo({
            acao: AcaoFinalizarFluxo.FINALIZAR_VENDA_NFE,
            request: () => registraVenda.finalizarVendaNFE(true),
            toCallAfter: openDanfe,
        });
    };

    const canEscape = useRef(true);

    const onInit = () => {
        dispatch(setActiveStep(1));
    };

    const navigate = useNavigate();
    const { addError, addSuccess, addInfo, closeAllSnacks } = useSnackbarCustom();
    const handleNavigate = useCallback((url: string) => {
        navigate(`/${url}`);
        //eslint-disable-next-line
    }, []);

    const totalRef = useRef<HTMLInputElement>(null);
    const planoContaService = PlanoContaService();

    const getContaTroco = useCallback(() => {
        closeAllSnacks()
        return planoContaService.getContaTroco()
            .then(({ data }) => {
                if (!data) {
                    addError({
                        message: "Configure uma conta de apropriação para troco para poder finalizar à venda",
                        closeable: true,
                        persist: true,
                        id: "sem_conta_apropriacao_troco"
                    });
                    return false;
                }
                return true;
            })
            .catch((err) => {
                throwErrorMessage(err)
                return false;
            });
        //eslint-disable-next-line
    }, []);

    const floatingActions: FloatingActionsProps = {
        actions: [
            {
                label: 'Salvar pedido (Alt + E)',
                icon: 'fa-solid fa-cart-plus',
                onClick: () => {
                    onClickSalvarVenda();
                }
            },
            {
                label: 'Finalizar venda NF-e',
                icon: 'fa-solid fa-handshake',
                onClick: () => {
                    if (faltaReceberRef.current > 0) {
                        setTimeout(() => {
                            isSalvandoPedidoRef.current = false;
                        }, 1000);
                        return addError({ message: 'Existe saldo a receber, verifique!', closeable: true, persist: false });
                    }
                    setStateLocal(prevState => ({ ...prevState, showDadosTransportador: true }));
                }
            },
            {
                label: 'Finalizar venda Outros',
                icon: 'fa-solid fa-handshake',
                onClick: () => {
                    const onComplete = (data: any) => {
                        resetAll(false);
                        setTimeout(() => {
                            pedidoFinalizacaoRef.current.boletoURLRef = data.boletoURL ?? "";
                            pedidoFinalizacaoRef.current.erroGerarBoletoRef = data.erroGerarBoleto;
                            pedidoFinalizacaoRef.current.erroImprimirBoletoRef = data.erroImprimirBoleto;
                            setStateLocal(prevState => ({
                                ...prevState, showComprovanteNFOutros: {
                                    open: true,
                                    pedido: data.pedido
                                }
                            }));
                        }, 300);
                    };

                    onFinalizarFluxo({
                        acao: AcaoFinalizarFluxo.FINALIZAR_VENDA_OUTROS,
                        request: () => registraVenda.finalizarVendaOutros(false),
                        toCallAfter: onComplete,
                        msgFinal: 'Concluindo venda',
                    });
                }
            },
            {
                label: 'CPF na nota? (F10)',
                icon: 'fa-regular fa-file-lines',
                onClick: () => {
                    setOpenCpf(true);
                }
            }
        ]
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'F9':
                e.preventDefault();
                totalRef.current && totalRef.current.focus();
                break;
            case 'e':
                if (e.altKey) {
                    e.preventDefault();
                    onClickSalvarVenda();
                }
                break;
            case 'F10':
                e.preventDefault();
                setOpenCpf(true);
                break;
        }
    };

    const setFormasPagmentoInicialDevolucao = (formasAceitas?: Array<IAbstractModel>) => {
        if (formasAceitas && pedido.itensTroca.length > 0) {
            const trocas = pedido.itensTroca;
            const valorTroca = trocas.reduce((prev, act) => prev += act.valorTotal, 0);
            const trocaToAdd: IPagamentoRow = {
                name: 'NOTA',
                descritivoFormaPagamento: 'Nota de crédito',
                formaPagamento: {
                    descritivo: 'Nota de crédito',
                    uuid: getFormaPagamentoAbstracModel('NOTA', formasAceitas).uuid,
                },
                infoAdicional: {
                    descritivo: 'DEVOLUÇÃO',
                    numeroParcelas: 1,
                    tipo: TipoInformacaoAdicional.OUTROS,
                },
                valor: valorTroca,
                isEditavel: true,
                isPago: true
            };
            const outrosToAdd: IPagamentoRow = {
                name: 'OUTROS',
                descritivoFormaPagamento: 'Outros',
                formaPagamento: {
                    descritivo: 'OUTROS DEVOLUÇÃO',
                    uuid: getFormaPagamentoAbstracModel('OUTROS', formasAceitas).uuid,
                },
                infoAdicional: {
                    descritivo: 'VENDA',
                    numeroParcelas: 1,
                    tipo: TipoInformacaoAdicional.OUTROS,
                },
                valor: valorTroca,
                isEditavel: true,
                isPago: true
            };
            dispatch(addRowDevolucao(trocaToAdd));
            dispatch(addRow(outrosToAdd));
        }
    };


    const [openCpf, setOpenCpf] = useState(false);

    const handleClickCloseDialog = () => {
        setOpenCpf(!openCpf);
    };

    const registraVenda = RegistraVendaService();

    // Salvar
    const onClickSalvarVenda = () => {
        if (tableRows.some(e => e.uuidOrigem)) {
            return addError({
                message: "Não é possível salvar esse pedido pois há pagamentos efetuados na maquininha.",
                closeable: true,
                persist: true,
                id: ""
            });
        }
        if (!isSalvandoPedidoRef.current) {
            isSalvandoPedidoRef.current = true;
            if (!receivedParams) {
                onFinalizarFluxo({
                    acao: AcaoFinalizarFluxo.SALVAR_PEDIDO,
                    request: registraVenda.salvarVenda,
                    msgFinal: ' ',
                    hasFullLoading: false,
                });
            } else {
                onFinalizarFluxo({
                    acao: AcaoFinalizarFluxo.ATUALIZAR_PEDIDO,
                    request: registraVenda.salvarVenda,
                    msgFinal: 'Pedido atualizado com sucesso',
                    hasFullLoading: false,
                });
            }
        }
    };

    const hasCrediario = (formasPagamento: IPagamentoRow[] | undefined) => {
        return formasPagamento?.some(e => e.formaPagamento.descritivo.toUpperCase().includes("CREDIÁRIO"));
    };

    const closeComprovante = useCallback(() => {
        if (hasCrediario(stateLocal.showComprovanteNFOutros.pedido?.itensPagamento) && stateLocal.showComprovanteNFOutros.pedido) {
            return vendaService.carregaPedido(stateLocal.showComprovanteNFOutros.pedido.uuid)
                .then(({ data }) => {
                    setStateLocal(prevState => ({
                        ...prevState, showComprovanteCrediario: {
                            open: true,
                            pedido: data
                        },
                        showComprovanteNFOutros: {
                            open: false,
                            pedido: null
                        }
                    }));
                })
                .catch(() => {
                    addError({
                        message: "Sistema temporariamente indisponivel. Se o erro persistir, contate o suporte",
                        closeable: true,
                        persist: true,
                        id: "sistema-indisponivel-error-carrega-pedido",
                        preventDuplicate: true
                    });
                    setStateLocal(prevState => ({
                        ...prevState, ...prevState, showComprovanteNFOutros: {
                            open: false,
                            pedido: null
                        }
                    }));
                });
        }
        setStateLocal(prevState => ({
            ...prevState, showComprovanteNFOutros: {
                open: false,
                pedido: null
            }
        }));
        dispatch(resetLoading());
        if (pedidoFinalizacaoRef.current.boletoURLRef) {
            return setStateLocal(prevState => ({ ...prevState, frameBoletoState: true }));
        }
        resetAll(true);
        showBoletoMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateLocal.showComprovanteNFOutros.pedido]);

    const onCloseComprovanteCrediario = () => {
        setStateLocal(prevState => ({
            ...prevState, showComprovanteCrediario: {
                open: false,
                pedido: null
            }
        }));
        dispatch(resetLoading());
        if (pedidoFinalizacaoRef.current.boletoURLRef) {
            return setStateLocal(prevState => ({ ...prevState, frameBoletoState: true }));
        }
        resetAll(true);
        showBoletoMessage();
    };

    useEffect(() => {
        if (stateLocal.frameDanfeState.open) {
            document.body.style.overflow = 'hidden !important';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }
    }, [stateLocal.frameDanfeState.open]);

    const initialRef = {
        boletoURLRef: "" as string,
        erroGerarBoletoRef: false as boolean,
        erroImprimirBoletoRef: false as boolean,
        isVendaBoletoRef: false as boolean,
        isCrediarioRef: false as boolean,
        numero: 0,
    };

    const pedidoFinalizacaoRef = useRef(initialRef);

    useEffect(() => {
        pedidoFinalizacaoRef.current.isVendaBoletoRef = tableRows.some(e => e.name === "BOLETO");
    }, [tableRows]);


    const openDanfe = (e: RespostaFinalizarVenda) => {
        pedidoFinalizacaoRef.current.boletoURLRef = e.boletoURL ?? "";
        pedidoFinalizacaoRef.current.erroGerarBoletoRef = e.erroGerarBoleto;
        pedidoFinalizacaoRef.current.erroImprimirBoletoRef = e.erroImprimirBoleto;
        pedidoFinalizacaoRef.current.isCrediarioRef = e.isCrediario;
        pedidoFinalizacaoRef.current.numero = e.pedido.numero;
        const eventoType = e.eventoNotaType;

        setStateLocal(prevState => ({
            ...prevState, showComprovanteCrediario: {
                open: false,
                pedido: e.pedido
            }
        }));

        if (isQualquerTipoSaidaAutorizada(eventoType) && e.urlDanfe?.startsWith('http')) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
            return setStateLocal(prevState => ({
                ...prevState,
                frameDanfeState: {
                    open: true,
                    url: e.urlDanfe ?? ""
                },
                frameBoletoState: true
            }));
            // setStateLocal(prevState => ({
            //     ...prevState, frameDanfeState: {
            //         open: true,
            //         url: e.urlDanfe ?? ""
            //     },
            // }))
        }

        if (shouldShowDialogEventoNota(eventoType) && (!!e.eventoNotaFiscalEntrada || !!e.eventoNotaFiscalSaida)) {
            return setStateEventoNotaDialog(prev => ({
                open: true,
                info: {
                    eventoType,
                    eventoEntrada: e.eventoNotaFiscalEntrada,
                    eventoSaida: e.eventoNotaFiscalSaida,
                }
            }));
            // return addInfo({ id: 'warning-rejeicao', message: "Pedido salvo com sucesso.  Vá no menu 'Pedidos' para utilizá-lo!", persist: true, closeable: true, preventDuplicate: true });
        };

        addError({ message: 'Não foi possivel obter retorno da Sefaz, consulte a situação na gestão de DFE.', closeable: true, persist: false });
    };

    const showBoletoMessage = useCallback(() => {
        setTimeout(() => {
            addSuccess({ id: 'success-pedidosalvo', message: 'Venda concluída com sucesso', severity: 'success', persist: true, closeable: true });
            if (pedidoFinalizacaoRef.current.isVendaBoletoRef) {
                if (pedidoFinalizacaoRef.current.erroImprimirBoletoRef) {
                    return addInfo({ message: "Boleto(s) gerado(s) porém não foi possivel realizar a impressão", persist: true, closeable: true });
                }
                return addError({ message: "Não foi possivel gerar o(s) boleto(s)", persist: true, closeable: true });
            }
            pedidoFinalizacaoRef.current.boletoURLRef = "";
            pedidoFinalizacaoRef.current.erroGerarBoletoRef = false;
            pedidoFinalizacaoRef.current.erroImprimirBoletoRef = false;
            pedidoFinalizacaoRef.current.isVendaBoletoRef = false;
        }, 300);
        //eslint-disable-next-line
    }, []);

    const onCloseDanfe = () => {
        if (!pedidoFinalizacaoRef.current.boletoURLRef) {
            if (pedidoFinalizacaoRef.current.isCrediarioRef && stateLocal.showComprovanteCrediario.pedido?.uuid) {
                vendaService.carregaPedido(stateLocal.showComprovanteCrediario.pedido?.uuid)
                    .then(({ data }) => {
                        setStateLocal(prevState => ({
                            ...prevState, showComprovanteCrediario: {
                                open: true,
                                pedido: data
                            },
                            frameDanfeState: {
                                open: false,
                                url: ""
                            }
                        }));
                    })
                    .catch(err => { });
            } else {
                resetAll(true);
            }
            showBoletoMessage();
        }
        return setStateLocal(prevState => ({
            ...prevState,
            frameDanfeState: {
                open: false,
                url: prevState.frameDanfeState.url
            }
        }));

    };

    const onCloseBoleto = useCallback(() => {
        pedidoFinalizacaoRef.current.boletoURLRef = "";
        pedidoFinalizacaoRef.current.erroGerarBoletoRef = false;
        pedidoFinalizacaoRef.current.erroImprimirBoletoRef = false;

        if (!!stateLocal.showComprovanteCrediario.pedido?.uuid) {
            vendaService.carregaPedido(stateLocal.showComprovanteCrediario.pedido.uuid)
                .then(({ data }) => {
                    return setStateLocal(prevState => ({
                        ...prevState,
                        frameBoletoState: false,
                        showComprovanteCrediario: {
                            open: true,
                            pedido: data
                        }
                    }));
                })
                .catch(err => {

                });
        }
        else {
            setStateLocal(prevState => ({ ...prevState, frameBoletoState: false }));
            resetAll(true);
        }
        //eslint-disable-next-line
    }, [stateLocal.showComprovanteCrediario.pedido]);

    const dialog = useContext(DialogContext);

    const onClickFinalizarVenda = async () => {
        if (troco && troco.valor && !troco.tipoTrocoPagar) {
            return setStateLocal(prevState => ({ ...prevState, showTrocaModal: true }));
        }
        if (troco && troco.valor && troco.tipoTrocoPagar) {
            const result = await getContaTroco();
            if (!result) return;
        }

        if (finalizarVendasQtdRef.current > 0) {
            dialog.confirm({
                title: 'Atenção',
                message: 'Não é possivel finalizar uma venda com pagamento pendente. Deseja informar forma de pagamento manualmente?',
                onConfirm: () => {
                    finalizarVendasQtdRef.current = 0;
                    const findIndex = tableRows.findIndex(e => !e.isPago);
                    const find = tableRows.find(e => !e.isPago);
                    if (find) {
                        setStateLocal(prevState => ({
                            ...prevState, editRow: {
                                index: findIndex,
                                row: find
                            }
                        }));
                    }
                }
            });
        }
        if (tableRows.some(e => e.isPago === false)) {
            finalizarVendasQtdRef.current += 1;
            const areNotPago: Array<FlowPedidoCieloIsPagoTO> = tableRows.filter(e => e.isPago === false).map(e => {
                return {
                    credenciadoraUUID: e.credenciadoraUUID!,
                    uuidOrigem: e.uuidOrigem!
                };
            });
            return registraVenda.cartaoIntegracaoArePago(areNotPago)
                .then(({ data }) => {
                    if (data.itensPagamentoTOS.some(e => e.isPago === false)) {
                        data.itensPagamentoTOS.filter(e => !e.isPago).forEach(e => {
                            addError({
                                message: `Não identificamos o pagamento do ${e.descritivoFormaPagamento} , tente novamente`,
                                closeable: true,
                                preventDuplicate: true
                            });
                        });
                    }
                })
                .catch(() => {
                    addError({
                        message: 'Ocorreu um erro inesperado. Se o erro persistir, entre em contato com o suporte',
                        persist: true,
                        severity: 'error',
                        preventDuplicate: false,
                        closeable: true,
                    });
                });
        }
        if (faltaReceberRef.current > 0) {
            setTimeout(() => {
                isSalvandoPedidoRef.current = false;
            }, 1000);
            return addError({ message: 'Existe saldo a receber, verifique!', closeable: true, persist: false });
        }
        if (cliente && isConsumidor(cliente) && cliente.tipoPessoa !== TipoPessoa.FISICA) return setStateLocal(prevState => ({
            ...prevState,
            showDadosTransportador: true
        }));
        onFinalizarFluxo({
            acao: AcaoFinalizarFluxo.FINALIZAR_VENDA,
            request: () => registraVenda.finalizarVenda(false),
            toCallAfter: openDanfe,
        });
    };

    const showProgress = (fullLoading: boolean, msgFinal: string = 'Transmitindo documento fiscal') => {
        let mensagem = 'Registrando pedido';

        dispatch(loadingWithOpt({
            loading: true,
            tipo: Tipo.LINEAR,
            message: mensagem,
            progress: 0,
        }));

        if (fullLoading) {
            for (let i = 0; i < 88; i++) {
                if (i % 9 === Math.floor(Math.random() * 2)) {
                    // eslint-disable-next-line
                    setTimeout(() => {
                        if (i > 40 && i < 87) {
                            mensagem = 'Registrando pagamento';
                        }
                        dispatch(changeLinearFinalizaVenda({ progress: i, message: mensagem }));
                    }, 55 * i);
                }
            }

            setTimeout(() => {
                mensagem = msgFinal;
                dispatch(changeLinearFinalizaVenda({ message: mensagem, progress: 88 }));
            }, 55 * 89);
        } else {
            dispatch(changeLinearFinalizaVenda({ progress: 50, message: 'Salvando pedido' }));
        }
    };

    const vendaService = VendaService();

    const onFinalizarFluxo = async (params: FinalizarFluxoParam) => {
        canEscape.current = false;
        if (troco?.valor && !troco.tipoTrocoPagar) {
            return setStateLocal(prevState => ({ ...prevState, showTrocaModal: true }));
        }
        if (troco && troco.valor && troco.tipoTrocoPagar) {
            const result = await getContaTroco();
            if (!result) return;
        }

        if (pedido.uuid) {
            vendaService.carregaPedido(pedido.uuid).then(({ data }) => {
                if (data.statusPedido !== StatusPedido.ABERTO.toString().toUpperCase()) {
                    setTimeout(() => {
                        addError({
                            message: `Erro: Pedido já faturado em outra transação`,
                            closeable: true,
                            persist: true
                        });
                    }, 500);
                    resetAll(false);
                    handleNavigate('venda');
                }
            })
                .catch(err => { });
        }


        // verifica existe saldo a receber
        if (faltaReceberRef.current > 0) {
            setTimeout(() => {
                isSalvandoPedidoRef.current = false;
            }, 1000);
            addError({ message: 'Existe saldo a receber, verifique!', closeable: true, persist: false });
            return;
        }

        const { request, toCallAfter, msgFinal, hasFullLoading } = params;
        showProgress(hasFullLoading ?? true, msgFinal);
        request()
            .then(({ data }) => {
                switch (params.acao) {
                    case AcaoFinalizarFluxo.SALVAR_PEDIDO:
                        dispatch(changeLinearFinalizaVenda({ progress: 100 }));
                        resetAll(true);
                        setTimeout(() => {
                            addSuccess({ id: 'success-pedidosalvo', message: 'Pedido Salvo com sucesso', severity: 'success', persist: false, closeable: true });
                        }, 300);
                        break;

                    case AcaoFinalizarFluxo.ATUALIZAR_PEDIDO:
                        dispatch(changeLinearFinalizaVenda({ progress: 100 }));
                        resetAll(true);
                        setTimeout(() => {
                            addSuccess({ id: 'update-succeded', message: 'Pedido atualizado com sucesso', severity: 'success', persist: false, closeable: true });
                        }, 650);
                        break;

                    case AcaoFinalizarFluxo.FINALIZAR_VENDA:
                        if (!toCallAfter) {
                            throw new Error("toCallAfter não pode ser undefined");
                        }
                        dispatch(changeLinearFinalizaVenda({ progress: 100 }));
                        setTimeout(() => {
                            toCallAfter(data);
                        }, 650);
                        break;

                    case AcaoFinalizarFluxo.FINALIZAR_VENDA_NFE:
                        if (!toCallAfter) {
                            throw new Error("toCallAfter não pode ser undefined");
                        }
                        dispatch(changeLinearFinalizaVenda({ progress: 100 }));
                        setTimeout(() => {
                            toCallAfter(data);
                        }, 650);
                        break;

                    case AcaoFinalizarFluxo.FINALIZAR_VENDA_OUTROS:
                        if (!toCallAfter) {
                            throw new Error("toCallAfter não pode ser undefined");
                        }
                        dispatch(changeLinearFinalizaVenda({ progress: 100 }));
                        setTimeout(() => {
                            toCallAfter(data);
                        }, 650);
                        break;
                };
            })
            .catch(err => {
                isSalvandoPedidoRef.current = false;
                dispatch(completeLoading());
                throwErrorMessage(err)
                resetAll(true);
            })
            .finally(() => {
                if (tokenAutorizador) {
                    dispatch(resetDescontoAutorizador());
                }
                setTimeout(() => {
                    dispatch(resetLoading());
                    canEscape.current = true;
                }, 2000);
                dispatch(setDeposito(configuracaoBalcao.depositoPadrao));
                dispatch(setTabelaPreco(configuracaoBalcao.tabelaPrecoPadrao));

            });
    };

    // Evento nota fiscal
    const initialStateEventoNotaDialog: StateEventoNotaDialog = {
        open: false,
        info: {
            eventoType: FlowEventoNotaEmitidaTypeDTO.SEM_EVENTO,
        }
    };

    const [stateEventoNotaDialog, setStateEventoNotaDialog] = useState(initialStateEventoNotaDialog);

    const resetAll = useCallback((navigate: boolean) => {
        dispatch(resetDesconto());
        dispatch(resetState());
        dispatch(resetRows());
        dispatch(completeLoading());
        dispatch(resetPagamento());
        if (navigate) handleNavigate('registra-venda');
        //eslint-disable-next-line
    }, []);

    const onFinishEditRow = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, editRow: null }));
    }, []);

    const onShowModalTroco = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showTrocaModal: !prevState.showTrocaModal }));
    }, []);

    const openEditTroco = () => {
        setStateLocal(prevState => ({ ...prevState, showTrocaModal: true }));
    };

    return (
        <TelaFormasPagamentoComponent
            onInit={onInit}
            refs={{
                faltaReceberRef,
                canEscape,
            }}
            footer={{
                onClickAvancar: onClickFinalizarVenda,
                floatingActions,
            }}
            handleKeyPress={handleKeyPress}
            setFormasPagamentoDefault={setFormasPagmentoInicialDevolucao}
            quantidadeTotal={quantidadeTotal}
            valorTotalLiquido={valorTotalLiquido}
            editRow={stateLocal.editRow}
            onFinishEditRow={onFinishEditRow}
            showModalTroco={onShowModalTroco}
            openEditTroco={openEditTroco}

        >
            <CpfNota onClose={handleClickCloseDialog} open={openCpf} data={null} />

            {stateLocal.frameBoletoState &&
                <CustomIFrame
                    url={pedidoFinalizacaoRef.current.boletoURLRef}
                    onClose={onCloseBoleto}
                />
            }

            {stateLocal.frameDanfeState.open && stateLocal.frameDanfeState.url &&
                <CustomIFrame
                    url={stateLocal.frameDanfeState.url}
                    onClose={onCloseDanfe}
                />
            }

            <EventoNotaFiscal
                state={stateEventoNotaDialog}
                setState={setStateEventoNotaDialog}
                onClose={() => {
                    const numeroPedido = pedidoFinalizacaoRef.current.numero;
                    const rejeitada = possuiRejeicao(stateEventoNotaDialog.info.eventoType);
                    resetAll(false);
                    navigate(
                        rejeitada ? '/registra-venda' : '/venda',
                        rejeitada ? undefined : { state: { numeroPedido } }
                    );
                }}
            />
            {stateLocal.showComprovanteNFOutros.open && stateLocal.showComprovanteNFOutros.pedido &&
                <PdfComprovanteTemplate
                    descritivo={descritivoEstabelecimento}
                    flowVendaTO={stateLocal.showComprovanteNFOutros.pedido}
                    onCloseComprovante={closeComprovante}
                    showPopUp
                    key="pdf-comprovante-template-pagamento"
                />
            }

            {stateLocal.showComprovanteCrediario.open && stateLocal.showComprovanteCrediario.pedido &&
                <PdfComprovanteCrediario
                    flowVendaTO={stateLocal.showComprovanteCrediario.pedido}
                    onCloseComprovante={onCloseComprovanteCrediario}
                    key="pdf-comprovante-crediario-pagamento"
                />
            }

            {stateLocal.showDadosTransportador &&
                <DadosTransportador
                    onClose={closeDadosTransportador}
                    onConfirm={confirmDadosTransportador}
                    key="dados-comprovante-pagamento"
                />
            }
            {stateLocal.showTrocaModal &&
                <ModalTroco
                    onShowModalTroco={onShowModalTroco}
                />
            }
        </TelaFormasPagamentoComponent>
    );
};

export default Pagamento;
