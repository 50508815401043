import { Delete } from "@mui/icons-material";
import { TableRow, TableCell, CircularProgress, TextField, IconButton, Tooltip, Icon, } from "@mui/material";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import DescontoInput from "pages/registra-venda/DescontoInput";
import { headerWidths, IProdutoRow } from "pages/registra-venda/registra-venda.model";
import { memo, useCallback } from "react";
import { setBrinde } from "redux/features/stateSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { globalStyles } from "styles/global";
import useCustomLocation from "utils/useCustomLocation";
import { currencyOf } from "utils/util";

type ItemRegistraVendaProps = {
    row: IProdutoRow;
    onChangeQuantidade: (uuid: string, value: string) => void;
    changeDesconto: (value: number, uuid: string) => void;
    toggleTipoDesconto: (uuid: string) => void;
    onBlurDescontoItem: (value: number, uuid: string) => void;
    saveTempDesconto: (prevDesconto: number, uuid: string) => void;
    onClickDelete: (uuid: string, produto: IProdutoRow) => void;
    isVisualizar: boolean;
};

const ItemRegistraVenda = (props: ItemRegistraVendaProps) => {
    const { addError } = useSnackbarCustom();
    const { itens } = useAppSelector(e => e.state.pedido);
    const dispatch = useAppDispatch();
    const {
        isTelaRegistraVenda
    } = useCustomLocation();

    const { tmfBrinde } = useAppSelector(e => e.configuracoes.configuracaoPedidoBalcao);

    const onClickBrinde = useCallback((uuid: string) => {
        if (!tmfBrinde) {
            return addError({ message: 'Brinde sem configuração de movimentações fiscais.', closeable: true });
        }
        dispatch(setBrinde(uuid));
        //eslint-disable-next-line
    }, []);

    return (
        <TableRow
            key={props.row.produto.codigoSku + props.row.uuid}
            sx={{ borderBottom: 1, borderBottomColor: globalStyles.PRETO }}
        >
            <TableCell sx={{
                width: headerWidths.produtoVenda,
                minWidth: headerWidths.produtoVenda,
                maxWidth: headerWidths.produtoVenda,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                padding: 0,
                paddingLeft: '15px'
            }}>
                {`${props.row.produto.codigoSku} - ${props.row.produto.nome} / ${props.row.produto.cor} / ${props.row.produto.tamanho}`}
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.estoque,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.estoque,
                maxWidth: headerWidths.estoque,
                padding: 0,
                paddingLeft: '10px',
                textAlign: 'center',
            }}>
                {props.row.estoque === -1 ?
                    <CircularProgress
                        size={12}
                        color='warning'
                    />
                    :
                    props.row.estoque
                }
            </TableCell>

            <TableCell sx={{
                width: headerWidths.quantidade,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.quantidade,
                maxWidth: headerWidths.quantidade,
                padding: 0,
                textAlign: 'center',
                paddingBottom: '10px'
            }}
                align="center">
                {props.row.estoque === -1 ?
                    <CircularProgress
                        size={12}
                        color='warning'
                    />
                    :
                    <TextField
                        disabled={props.isVisualizar}
                        error={props.row.quantidade > props.row.estoque}
                        inputProps={{ inputMode: 'numeric', min: 1, style: { textAlign: 'center', color: (props.row.estoque === 0 || (props.row.quantidade > props.row.estoque) ? 'rgba(196, 43, 81, 1)' : "") } }}
                        value={props.row.quantidade}
                        onKeyDown={e => e.key === '.' && e.preventDefault()}
                        onChange={q => { props.onChangeQuantidade(props.row.uuid, q.target.value); }}
                        variant="standard"
                        type="number"
                    />
                }
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.valorUnitario,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.valorUnitario,
                maxWidth: headerWidths.valorUnitario,
                padding: 0,
                textAlign: 'center'
            }}>
                {currencyOf(props.row.produto.preco)}
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.desconto,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.desconto,
                maxWidth: headerWidths.desconto,
                padding: 0,
                textAlign: 'center',
                paddingBottom: '18px'
            }}>
                <DescontoInput
                    item={{
                        tipoDesconto: props.row.itemDesconto.tipoDesconto,
                        valorDesconto: props.row.itemDesconto.valorDesconto,
                        percentualDesconto: props.row.itemDesconto.percentualDesconto,
                        valorTotalBruto: props.row.produto.preco * props.row.quantidade,
                        onChangeDesconto: props.changeDesconto,
                        uuid: props.row.uuid
                    }}
                    inputProps={{ style: { textAlign: 'center', } }}
                    iconButtonProps={{
                        onToggleTipoDesconto: props.toggleTipoDesconto,
                        percentoProps: {
                            color: 'var(--laranja)',
                        },
                        valorProps: {
                            color: 'var(--laranja)',
                        }
                    }}
                    onBlur={props.onBlurDescontoItem}
                    onSaveTempDesconto={props.saveTempDesconto}
                    isVisualizar={props.isVisualizar}
                    origem="ROW"
                />
            </TableCell>

            <TableCell align="center" sx={{
                color: (props.row.isBrinde || props.isVisualizar) ? '#9F9F9F' : globalStyles.PRETO,
                width: headerWidths.total,
                minWidth: headerWidths.total,
                maxWidth: headerWidths.total,
                padding: 0,
                textAlign: 'center'
            }}>
                {currencyOf(props.row.valorTotal)}
            </TableCell>

            <TableCell align="right" sx={{
                color: (props.row.isBrinde || props.isVisualizar) ? '#9F9F9F' : globalStyles.PRETO,
                width: headerWidths.brinde,
                minWidth: headerWidths.brinde,
                maxWidth: headerWidths.brinde,
                textAlign: 'center'
            }}>
                {isTelaRegistraVenda() &&
                    <IconButton
                        disabled={props.isVisualizar}
                        onClick={() => onClickBrinde(props.row.uuid)}
                        style={{ color: itens.find(e => e.uuid === props.row.uuid)?.isBrinde ? "#4BB44B" : "#D7D7D7" }}
                    >
                        <Tooltip title={props.row.isBrinde ? "Clique para inativar o produto de brinde" : "Clique para ativar o produto de brinde"}>
                            <Icon className="fa-solid fa-gifts" sx={{ width: "30px" }} />
                        </Tooltip>
                    </IconButton>
                }
            </TableCell>

            <TableCell align="right" sx={{
                width: headerWidths.lixeira,
                minWidth: headerWidths.lixeira,
                maxWidth: headerWidths.lixeira,
                textAlign: 'center'
            }}>
                <IconButton
                    disabled={props.isVisualizar}
                    onClick={() => props.onClickDelete(props.row.uuid, props.row)}
                    style={{ color: !props.isVisualizar ? "var(--laranja)" : "lightgray", }}
                >
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow >
    );
};

export default memo(ItemRegistraVenda);
