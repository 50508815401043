import { Box, Button, Card, Container, Grid, Icon, Typography } from "@mui/material";
import CashRegister from "components/Icons/CashRegister";
import FileRight from 'components/Icons/FileRight';
import MiniMoneyMinus from 'components/Icons/MiniMoneyMinus';
import MiniMoneyPlus from 'components/Icons/MiniMoneyPlus';
import MoneyMinus from "components/Icons/MoneyMinus";
import MoneyPlus from "components/Icons/MoneyPlus";
import MoneyTriple from "components/Icons/MoneyTriple";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import HeaderOuBannerDependendoDaInterpretacaoH1 from "layout/header-ou-banner-dependendo-da-interpretacao-h1/HeaderOuBannerDependendoDaInterpretacaoH1";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCaixa } from 'redux/features/caixaSlice';
import { setActiveStep } from 'redux/features/stepperSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { currencyOf } from 'utils/util';
import CustomGridOption from './CustomGridOption';
import DialogCaixa from "./DialogCaixa";
import { ModalCaixaProps, TipoDialog } from "./caixa.model";
import CaixaService from './caixa.service';
import './caixa.style.css';
import CancelamentoValePresenteDialog from "./cancelamento-vale-presente/CancelamentoValePresenteDialog";

const cinza = '#666666';
const laranja = '#FF7F01';

const initialState = {
    aberturaCaixa: false,
    reforcaCaixa: false,
    sangraCaixa: false,
    fechamentoCaixa: false,
    transferenciaCaixa: false,
    error: false,
    showFechamentoOptions: false,
    showOutrosMovimentosOptions: false,
    valePresenteDialog: false,
};

const Caixa = () => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const navigate = useNavigate();
    const { caixaAberto, saldo, caixa: caixaState } = useAppSelector(e => e.caixa.caixaAtual);
    const dispatch = useAppDispatch();
    const { addError } = useSnackbarCustom();
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);
    const caixaService = CaixaService();

    useEffect(() => {
        if (caixaState) {
            caixaService.getSaldoConta(caixaState?.uuid)
                .then(({ data }) => {
                    dispatch(setCaixa({
                        caixa: data.caixa,
                        contaSelecionada: null,
                        saldo: data.saldo,
                        dataAbertura: data.dataAbertura,
                        caixaAberto: data.caixaAberto
                    }));
                })
                .catch(() => { });
        }
        // eslint-disable-next-line
    }, []);

    const handleNavigate = () => {
        navigate(`/registra-venda`);
    };

    const handleAbreCaixa = useCallback(() => {
        if (!caixaAberto) {
            setStateLocal(prevState => ({ ...prevState, aberturaCaixa: true, reforcaCaixa: false, sangraCaixa: false, fechamentoCaixa: false, transferenciaCaixa: false, showOutrosMovimentosOptions: false }));
        }
    }, [caixaAberto]);

    const handleReforcaCaixa = useCallback(() => {
        if (caixaAberto) {
            setStateLocal(prevState => ({ ...prevState, aberturaCaixa: false, reforcaCaixa: true, sangraCaixa: false, fechamentoCaixa: false, transferenciaCaixa: false, showOutrosMovimentosOptions: false }));
        }
    }, [caixaAberto]);

    const handleSangraCaixa = useCallback(() => {
        if (caixaAberto) {
            setStateLocal(prevState => ({ ...prevState, aberturaCaixa: false, reforcaCaixa: false, sangraCaixa: true, fechamentoCaixa: false, transferenciaCaixa: false, showOutrosMovimentosOptions: false }));
        }
    }, [caixaAberto]);

    const handleFechaCaixa = useCallback(() => {
        if (caixaAberto) {
            setStateLocal(prevState => ({ ...prevState, aberturaCaixa: false, reforcaCaixa: false, sangraCaixa: false, fechamentoCaixa: true, transferenciaCaixa: false, showOutrosMovimentosOptions: false }));
        }
    }, [caixaAberto]);

    const handleTransferirCaixa = useCallback(() => {
        if (caixaAberto) {
            setStateLocal(prevState => ({ ...prevState, aberturaCaixa: false, reforcaCaixa: false, sangraCaixa: false, fechamentoCaixa: false, transferenciaCaixa: true, showOutrosMovimentosOptions: false }));
        }
    }, [caixaAberto]);


    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        switch (e.key) {
            case 'F1':
                e.preventDefault();
                handleAbreCaixa();
                break;
            case 'F2':
                e.preventDefault();
                handleReforcaCaixa();
                break;
            case 'F3':
                e.preventDefault();
                handleSangraCaixa();
                break;
            case 'F4':
                e.preventDefault();
                handleFechaCaixa();
                break;
        }
    }, [handleAbreCaixa, handleReforcaCaixa, handleSangraCaixa, handleFechaCaixa]);


    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        if (!caixaAberto) {
            dispatch(setActiveStep(0));
        } else {
            dispatch(setActiveStep(1));
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [caixaAberto, dispatch, handleKeyDown, estabelecimento.uuid]);

    const cancel = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, aberturaCaixa: false, reforcaCaixa: false, sangraCaixa: false, fechamentoCaixa: false, transferenciaCaixa: false }));
    }, []);

    const toggleShowFechamento = () => {
        if (stateLocal.showOutrosMovimentosOptions) {
            return setStateLocal(prevState => ({ ...prevState, showFechamentoOptions: !stateLocal.showFechamentoOptions, showOutrosMovimentosOptions: false }));
        }
        return setStateLocal(prevState => ({ ...prevState, showFechamentoOptions: !stateLocal.showFechamentoOptions }));
    };

    const toggleShowOutrosMovimentos = () => {
        if (!caixaAberto) {
            return addError({ message: "Abra um caixa para ter acesso a outros movimentos", closeable: true, id: "no-caixa-aberto-toggle-outros-movimentos" });
        }
        if (stateLocal.showFechamentoOptions) {
            return setStateLocal(prevState => ({ ...prevState, showOutrosMovimentosOptions: !stateLocal.showOutrosMovimentosOptions, showFechamentoOptions: false }));
        }
        return setStateLocal(prevState => ({ ...prevState, showOutrosMovimentosOptions: !stateLocal.showOutrosMovimentosOptions }));
    };

    const handleRelatorioFechamentoCaixa = useCallback(() => {
        navigate("/relatorio/caixa/fechamento");
        //eslint-disable-next-line
    }, []);

    const onCloseCancelarValePresente = () => {
        setStateLocal(prev => ({ ...prev, valePresenteDialog: false }));
    };

    const dialogues: ModalCaixaProps = [
        {
            title: TipoDialog.ABERTURA,
            icon: <CashRegister color='#B45F06' />,
            open: stateLocal.aberturaCaixa,
            caixaTitle: "Selecionar Caixa",
            caixaItens: [],
        },
        {
            title: TipoDialog.REFORCAR,
            icon: (<div style={{ position: 'relative' }}><MoneyPlus color={caixaAberto ? '#2B78E4' : '#ABABAB'} left='3px' /></div>),
            open: stateLocal.reforcaCaixa,
            caixaTitle: "Caixa",
            caixaItens: [],
        },
        {
            title: TipoDialog.SANGRAR,
            icon: (<div style={{ position: 'relative' }}>< MoneyMinus color={caixaAberto ? '#CC0000' : '#ABABAB'} left='3px' /></div>),
            open: stateLocal.sangraCaixa,
            caixaTitle: "Caixa",
            caixaItens: [],
        },
        {
            title: TipoDialog.FECHAMENTO,
            icon: (<i className="fa-solid fa-money-bill-1-wave" style={{ fontSize: 50, color: '#009e0F' }} />),
            open: stateLocal.fechamentoCaixa,
            caixaTitle: "Selecionar Caixa",
            dataFechamento: true,
            caixaItens: [],
        },
        {
            title: TipoDialog.TRANSFERENCIA,
            icon: (<i className="fa-solid fa-exchange-alt" style={{ fontSize: 50, color: '#009e0F' }} />),
            open: stateLocal.transferenciaCaixa,
            caixaTitle: "Origem",
            dataFechamento: true,
            caixaItens: [],
        }
    ];

    return (
        <>
            <Container maxWidth="xl" sx={{ marginTop: '20px' }} >
                <Box sx={{ minHeight: 'calc(100vh - 64px - 76px - 20px - 20px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <HeaderOuBannerDependendoDaInterpretacaoH1
                        id="vendaScreenBox"
                        label={caixaAberto ? (caixaState?.descritivo ?? '') : 'Caixa fechado'}
                        customIcon={<CashRegister color='#666666' fontSize='45px' />}
                    />
                    {caixaState?.descritivo &&
                        <Typography id="caixa_aberto_saldo_em_caixa_titulo" sx={{ fontSize: '45px', color: cinza, textAlign: 'center' }}>Saldo {currencyOf(saldo)}</Typography>
                    }

                    <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <Grid item xl={4} md={6} sm={6} sx={{ justifyContent: 'center' }}>
                            <Card onClick={handleAbreCaixa}
                                variant="outlined"
                                id={!caixaAberto ? 'card_abrir_caixa_habilitado' : 'card_abrir_caixa_desabilitado'}
                                className="card" sx={{
                                    borderWidth: !caixaAberto ? '2px' : 0,
                                    borderColor: !caixaAberto ? laranja : cinza,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '20px',
                                    boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                    cursor: !caixaAberto ? 'pointer' : 'auto',
                                    bgcolor: !caixaAberto ? '' : 'rgba(0, 0,0,0.07)'
                                }}>
                                <CashRegister id="abrir_caixa_icon" color={caixaAberto ? 'rgba(0, 0,0,0.25)' : "#B45F06"} fontSize="60px" />
                                <Typography id="abrir_caixa_titulo" sx={{ fontSize: '30px', color: !caixaAberto ? cinza : 'rgba(0, 0,0,0.25)', textAlign: 'center', padding: '0 10px 0 10px' }}>Abertura de caixa (F1)</Typography>
                            </Card>
                        </Grid>

                        <Grid item xl={4} md={6} sm={6} sx={{
                            position: 'relative'
                        }}>
                            <Card id={caixaAberto ? 'card_fechar_caixa_habilitado' : 'card_fechar_caixa_desabilitado'}
                                onClick={handleFechaCaixa} variant="outlined" className={`card`}
                                sx={{
                                    borderWidth: caixaAberto ? '2px' : 0,
                                    borderColor: caixaAberto ? laranja : cinza,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '20px',
                                    boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                    cursor: caixaAberto ? 'pointer' : 'auto',
                                    bgcolor: caixaAberto ? '' : 'rgba(0, 0,0,0.07)',
                                }}>
                                <MoneyTriple id="fechar_venda_icon" color={caixaAberto ? '#009e0F' : '#ABABAB'} />
                                <Typography id="fechar_venda_title" sx={{ fontSize: '30px', color: caixaAberto ? cinza : '#ABABAB', textAlign: 'center' }}>Fechamento de caixa (F4)</Typography>
                            </Card>
                            <Button onClick={toggleShowFechamento} sx={{ minWidth: 'auto', width: '30px', position: 'absolute', bottom: '10px', right: '20px' }}>
                                <i id={`ellipsis-button-${2}`} style={{ width: '10px', color: '#666666', fontSize: '25px' }} className="fa-solid fa-ellipsis-vertical"></i>
                            </Button>
                            {stateLocal.showFechamentoOptions &&
                                <Box sx={{ position: 'absolute', width: '90%' }} >
                                    <CustomGridOption
                                        onClick={handleRelatorioFechamentoCaixa}
                                        marginTop="5px"
                                        icon={<Icon className="fa-regular fa-file-lines" sx={{ color: '#000', fontSize: '22px' }} />}
                                        title="Relatório de fechamento de caixa"
                                    />
                                </Box>
                            }
                        </Grid>

                        <Grid item xl={4} md={6} sm={6} sx={{
                            position: 'relative'
                        }}>
                            <Card id={caixaAberto ? 'card_fechar_caixa_habilitado' : 'card_fechar_caixa_desabilitado'}
                                onClick={toggleShowOutrosMovimentos} variant="outlined" className={`card`}
                                sx={{
                                    borderWidth: caixaAberto ? '2px' : 0,
                                    borderColor: caixaAberto ? laranja : cinza,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '20px',
                                    boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                    cursor: caixaAberto ? 'pointer' : 'auto',
                                    bgcolor: caixaAberto ? '' : 'rgba(0, 0,0,0.07)',
                                }}>
                                <FileRight id="fechar_venda_icon" color={caixaAberto ? '#2B78E4' : '#ABABAB'} fontSize='80px' />
                                <Typography id="fechar_venda_title" sx={{ fontSize: '30px', color: caixaAberto ? cinza : '#ABABAB', textAlign: 'center' }}>Outros movimentos</Typography>
                            </Card>
                            <Button onClick={toggleShowOutrosMovimentos} sx={{ minWidth: 'auto', width: '30px', position: 'absolute', bottom: '10px', right: '20px' }}>
                                <i id={`ellipsis-button-${2}`} style={{ width: '10px', color: '#666666', fontSize: '25px' }} className="fa-solid fa-ellipsis-vertical"></i>
                            </Button>
                            {stateLocal.showOutrosMovimentosOptions &&
                                <Box sx={{ position: 'absolute', width: '90%', zIndex: 1 }} >
                                    <CustomGridOption
                                        onClick={() => setStateLocal(prev => ({ ...prev, valePresenteDialog: true, showOutrosMovimentosOptions: false }))}
                                        marginTop="5px"
                                        icon={<Icon className="fa-regular fa-file-lines" sx={{ color: '#000', fontSize: '22px', width: '10%' }} />}
                                        title="Cancelar vale presente"
                                    />
                                    <CustomGridOption
                                        onClick={handleReforcaCaixa}
                                        marginTop="5px"
                                        icon={<MiniMoneyPlus fontSize="20px" color="#000" left='0px' />}
                                        title="Reforçar caixa (F2)"
                                    />
                                    <CustomGridOption
                                        onClick={handleSangraCaixa}
                                        marginTop="5px"
                                        icon={<MiniMoneyMinus fontSize="20px" color="#000" left='0px' />}
                                        title="Sangrar caixa (F3)"
                                    />
                                    <CustomGridOption
                                        onClick={handleTransferirCaixa}
                                        marginTop="5px"
                                        icon={<Icon className="fa-solid fa-exchange-alt" sx={{
                                            fontSize: "20px",
                                            color: "#000",
                                        }} />}
                                        title="Transferir"
                                    />
                                </Box>
                            }
                        </Grid>

                    </Grid >

                    <Grid sx={{ alignSelf: 'flex-end', paddingTop: '10px', }}>
                        <CustomButton
                            id={caixaAberto ? "tela_caixa_navigate_caixa_habilitado" : "tela_caixaa_navigate_caixa_desabilitado"}
                            disabled={!caixaAberto || stateLocal.error ? true : false}
                            onClick={handleNavigate}
                            variant="contained"
                            color="success"
                            startIcon={<Icon className='fa-solid fa-arrow-right' />}>
                            Ir para o PDV
                        </CustomButton>
                    </Grid>
                </Box>
                {dialogues.map((e, i) => {
                    return (
                        <DialogCaixa
                            icon={e.icon}
                            open={e.open}
                            title={e.title}
                            caixaTitle={e.caixaTitle}
                            caixaItens={e.caixaItens}
                            value={saldo}
                            key={i}
                            onCancel={e.onCancel ? e.onCancel : cancel}
                            dataFechamento={e.dataFechamento}
                        />
                    );
                })}

                <CancelamentoValePresenteDialog
                    open={stateLocal.valePresenteDialog}
                    onClose={onCloseCancelarValePresente}
                />
            </Container >
        </>
    );
};

export default Caixa;
