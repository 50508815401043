import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotaFiscalVendaReferenciarRowResponseDTO } from "pages/nota-referenciada/troca-referenciada.model";
import { deepReset } from "utils/util";

const initialState = {
  chavesAcesso: [] as NotaFiscalVendaReferenciarRowResponseDTO[],
};

const notaReferenciadaSlice = createSlice({
  name: "notaReferenciada",
  initialState,
  reducers: {
    setChavesReferenciadas: (
      state,
      action: PayloadAction<NotaFiscalVendaReferenciarRowResponseDTO[]>
    ) => {
      state.chavesAcesso = action.payload;
    },
    resetChavesReferenciadas: (state) => {
      deepReset(state, initialState);
    },
  },
});

export const { setChavesReferenciadas, resetChavesReferenciadas } =
  notaReferenciadaSlice.actions;
export const notaReferenciadaReducer = notaReferenciadaSlice.reducer;
