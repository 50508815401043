import {
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { IAbstractModel } from "model/abstract.model";
import { Dispatch, SetStateAction } from "react";
import { currencyOf } from "utils/util";

export type TrocaReferenciadaDilogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type NotaFiscalVendaReferenciarRowResponseDTO = {
  chaveAcesso: string;
  estabelecimento: string;
  serie: string;
  numero: string;
  destinatario: string;
  valorTotal: number;
  emissao: string;
  saida: string;
  modelo: string;
  finalidade: string;
  situacao: string;
  skus: string[];
};

export type NotaFiscalVendaReferenciarFiltroDTO = {
  cliente: string | null;
  estabelecimentos: string[];
  pedidos: string[];
  series: string[];
  numeros: string[];
  dataEmissaoInicio: string;
  dataEmissaoFim: string;
  chaveAcesso: string | null;
};

const getTodayPlusDays = (minusCount: number) => {
  const hoje = new Date();
  const toReturn = new Date();
  toReturn.setDate(hoje.getDate() + minusCount);
  return toReturn;
};

export const initialLocalState = {
  estabelecimentos: [] as IAbstractModel[],
  estabelecimentosAc: [] as IAbstractModel[],
  pedidos: [] as IAbstractModel[],
  pedidosAc: [] as IAbstractModel[],
  series: [] as IAbstractModel[],
  seriesAc: [] as IAbstractModel[],
  numeros: [] as IAbstractModel[],
  numerosAc: [] as IAbstractModel[],
  dataInicio: getTodayPlusDays(-30),
  dataFim: new Date(),
  procurandoPorFiltro: true,
};

export const initialGridState = {
  isLoading: false,
  rows: [] as GridRowsProp,
  selectedRowsChaveAcesso: [] as GridRowSelectionModel,
};

export const columns: GridColDef[] = [
  {
    field: "estabelecimento",
    headerName: "Estabelecimento",
    type: "string",
    flex: 1,
  },

  {
    field: "serie",
    headerName: "Serie",
    type: "string",
    flex: 1,
  },

  {
    field: "numero",
    headerName: "Número",
    type: "string",
    flex: 1,
  },

  {
    field: "destinatario",
    headerName: "Destinatário",
    type: "string",
    flex: 1,
  },

  {
    field: "valorTotal",
    headerName: "Valor total",
    type: "number",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueFormatter: (value) => currencyOf(value.value),
  },

  {
    field: "emissao",
    headerName: "Emissão",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },

  {
    field: "saida",
    headerName: "Saída",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },

  {
    field: "modelo",
    headerName: "Modelo",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },

  {
    field: "finalidade",
    headerName: "Finalidade",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },

  {
    field: "situacao",
    headerName: "Situação",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];
