import { Box, Icon, Typography } from "@mui/material";
import { useEffect } from "react";

type Props = {
    id: string;
    iconClassName?: string;
    label: string;
    customIcon?: JSX.Element;
    small?: boolean,
    maxHeight?: string
};

const HeaderOuBannerDependendoDaInterpretacaoH1 = ({ id, iconClassName, label, customIcon, small, maxHeight }: Props) => {
    useEffect(() => {
        if (!iconClassName && !customIcon) throw new Error('Ícone obrigatório');
        // eslint-disable-next-line
    }, []);

    const fontSize = small ? '25px' : '45px'
    return (
        <Box
            id={`${id}-header-banner-container`}
            sx={{
                width: '100%',
                borderTop: '1px solid rgba(0, 0, 0, .1)',
                borderBottom: '1px solid rgba(0, 0, 0, .1)',
                padding: '15px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: small ? '5px' : '20px',
                maxHeight: maxHeight
            }}>
            {customIcon
                ? customIcon
                : <Icon id={`${id}-header-banner-icon`} className={iconClassName} sx={{ color: '#666666', fontSize }} />
            }
            <Typography
                id={`${id}-header-banner-label`}
                sx={{ paddingLeft: '25px', fontSize, fontWeight: '600', color: '#666666', userSelect: 'none' }}>
                {label}
            </Typography>
        </Box>
    );
};

export default HeaderOuBannerDependendoDaInterpretacaoH1;
