import { IProdutoRow } from "pages/registra-venda/registra-venda.model";
import { TableVirtuoso } from "react-virtuoso"

type InfiniteScrollVirtuosoProps = {
    data: Array<IProdutoRow>,
    renderItem: (item: any, index: number) => JSX.Element;
    height: number;
    isVisualizar: boolean;
    fixedHeaderContent: () => JSX.Element;
    fixedFooterContent: () => JSX.Element
}

const InfiniteScrollVirtuoso = (props: InfiniteScrollVirtuosoProps) => {
    return (
        <TableVirtuoso
            style={{
                height: props.height,
                overflowY: 'scroll',
                overflowX: 'auto',
                width: '100%',
                position: 'relative'
            }}
            className={`color-orange ${props.isVisualizar && "isVisualizar"}`}
            fixedHeaderContent={props.fixedHeaderContent}
            fixedFooterContent={props.data.length > 0 ? props.fixedFooterContent : undefined}
            data={props.data}
            itemContent={(index, row) => props.renderItem(row, index)}
        />
    )
}

export default InfiniteScrollVirtuoso