import { AxiosResponse } from "axios";
import { IAbstractModel } from "model/abstract.model";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import {
  NotaFiscalVendaReferenciarFiltroDTO,
  NotaFiscalVendaReferenciarRowResponseDTO,
} from "./troca-referenciada.model";

export type UltimaNotaPorSkuDTO = {
  skuUUID: string;
  numeroNota: string;
  serie: string;
  chaveAcesso: string;
  saldo: number;
};

export type FlowSkuNotasReferenciadasDTO = {
  skuUUID: string;
  quantidadeSolicitada: number;
  saldoTotal: number;
  notas: UltimaNotaPorSkuDTO[];
};

export type NotasReferenciadasResponse = Record<
  string,
  FlowSkuNotasReferenciadasDTO
>;

const NotaReferenciadaService = () => {
  const path = "/flow/notareferenciada";
  const loginService = LoginService();

  return {
    getNotas(
      uuidCliente: string,
      skusTrocaUUIDs: Record<string, number>
    ): Promise<AxiosResponse<NotasReferenciadasResponse>> {
      return axiosRequest.post(`${path}/notas/${uuidCliente}`, skusTrocaUUIDs, {
        headers: loginService.getHeaders(),
      });
    },

    filtroAvancado(
      filtro: NotaFiscalVendaReferenciarFiltroDTO
    ): Promise<AxiosResponse<NotaFiscalVendaReferenciarRowResponseDTO[]>> {
      return axiosRequest.post(`${path}/filtroavancado`, filtro, {
        headers: loginService.getHeaders(),
      });
    },

    numerosPedidos({
      search,
      uuidsToExclude,
    }: {
      search?: string | null;
      uuidsToExclude?: string[];
    }): Promise<AxiosResponse<IAbstractModel[]>> {
      return axiosRequest.get(`${path}/numerospedidos`, {
        headers: loginService.getHeaders(),
        params: { search, uuidsToExclude: uuidsToExclude?.join(", ") },
      });
    },

    series({
      search,
      uuidsToExclude,
    }: {
      search?: string | null;
      uuidsToExclude?: string[];
    }): Promise<AxiosResponse<IAbstractModel[]>> {
      return axiosRequest.get(`${path}/series`, {
        headers: loginService.getHeaders(),
        params: { search, uuidsToExclude: uuidsToExclude?.join(", ") },
      });
    },

    numerosNotas({
      search,
      uuidsToExclude,
    }: {
      search?: string | null;
      uuidsToExclude?: string[];
    }): Promise<AxiosResponse<IAbstractModel[]>> {
      return axiosRequest.get(`${path}/numerosnotas`, {
        headers: loginService.getHeaders(),
        params: { search, uuidsToExclude: uuidsToExclude?.join(", ") },
      });
    },

    clientePossuiEnderecoPrincipalOuEntregaForaDoEstado(
      uuidCliente: string
    ): Promise<AxiosResponse<{ possuiEnderecoForaDoEstado: boolean }>> {
      return axiosRequest.get(
        `${path}/possuienderecoforaestado/${uuidCliente}`,
        {
          headers: loginService.getHeaders(),
        }
      );
    },
  };
};

export default NotaReferenciadaService;
