import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { useAppSelector } from "redux/hooks";
import { currencyOf } from "utils/util";
import { TipoDialog } from "./caixa.model";

interface IDinheiroCaixaProps {
    saldo: number,
    onSetSaldo: (saldo: number) => void,
    title: string,
    open: boolean;
    dinheiroError?: boolean;
    onSetDinheiroError?: (bool: boolean, message: string) => void,
    message: string,
    min?: number;
}

const DinheiroCaixa = (props: IDinheiroCaixaProps) => {
    const { saldo } = useAppSelector(e => e.caixa.caixaAtual);
    const inputRef = useRef<HTMLInputElement>();
    const valorMin = props.min ?? 0;

    useEffect(() => {
        if (props.title !== TipoDialog.ABERTURA) {
            onFocus();
        }
        onChangeValue(props.title === TipoDialog.TRANSFERENCIA ? 0.01 : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFocus = () => {
        setTimeout(() => {
            inputRef.current?.focus();
            inputRef.current?.setSelectionRange(4, 4);
        },);
    };

    const onChangeValue = (value: number | undefined) => {
        if (value !== undefined && value >= valorMin) {
            props.onSetSaldo(value);
            if ((value > saldo) && props.title === TipoDialog.SANGRAR) {
                if (!props.dinheiroError) {
                    props.onSetDinheiroError!(true, 'O sangramento não pode ser maior que o saldo em caixa');
                }
            }
            else if (value > saldo && props.title === TipoDialog.FECHAMENTO) {
                props.onSetDinheiroError!(true, 'Valor do fechamento não pode ser maior que saldo em caixa');
            }
            else if (value > saldo && props.title === TipoDialog.TRANSFERENCIA) {
                props.onSetDinheiroError!(true, 'Valor da transferência não pode ser maior que saldo em caixa');
            }
            else {
                props.onSetDinheiroError!(false, '');
            }
        }
        else {
            props.onSetDinheiroError!(true, `Valor mínimo de ${currencyOf(valorMin)}`);
        }
    };

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Typography sx={{ fontSize: '16px', color: props.dinheiroError ? '#d32f2f' : 'rgba(0, 0, 0, 0.60)' }}>Dinheiro *</Typography>
            <NumericFormat
                onFocus={onFocus}
                style={{ width: '100%' }}
                inputRef={inputRef}
                id="saldo_caixa"
                error={props.dinheiroError}
                decimalScale={2}
                fixedDecimalScale
                allowedDecimalSeparators={[',']}
                customInput={TextField}
                decimalSeparator=","
                prefix={'R$ '}
                thousandsGroupStyle="thousand"
                thousandSeparator="."
                variant="standard"
                allowNegative={false}
                inputProps={{ style: { textAlign: 'left', fontSize: '24px', color: props.dinheiroError ? '#d32f2f' : '#df6e0b', fontWeight: 700, fontFamily: 'Work Sans, sans-serif' } }}
                //isAllowed={({ floatValue }) => !floatValue || floatValue <= valorTotalBruto!}

                value={props.saldo}
                onValueChange={({ floatValue }) => onChangeValue(floatValue)}
            />
            {props.dinheiroError &&
                <FormHelperText
                    sx={{
                        whiteSpace: 'nowrap',
                        color: props.dinheiroError ? '#d32f2f' : null,
                        fontSize: '16px'
                    }}
                >
                    {props.message}
                </FormHelperText>
            }
        </Box>
    );
};

export default DinheiroCaixa;
