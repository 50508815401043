import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAbstractModel } from "model/abstract.model";
import { ICaixasDisponivel } from "pages/Caixa/caixa.model";
import { deepReset } from "utils/util";

export interface ICaixaSlice {
  caixa: ICaixasDisponivel | null;
  caixaDestino?: ICaixasDisponivel | null;
  saldo: number;
  contaSelecionada: IAbstractModel | null;
  motivoObservacao?: string;
  dataAbertura?: string;
  dataFechamento?: string;
  operadorAtual?: string | null;
  caixaAberto?: boolean;
  dinheiroError?: boolean;
}

const initialState: { caixaAtual: ICaixaSlice } = {
  caixaAtual: {
    caixa: {
      uuid: "",
      descritivo: "",
      saldo: 0,
    },
    saldo: 0,
    contaSelecionada: null,
    operadorAtual: null,
    dataAbertura: "",
    caixaAberto: false,
  },
};

const caixaSlice = createSlice({
  name: "caixa",
  initialState,
  reducers: {
    setCaixa: (s, { payload }: PayloadAction<ICaixaSlice>) => {
      s.caixaAtual = payload;
    },
    resetCaixa: (s) => {
      deepReset(s, initialState);
    },
  },
});

export const { setCaixa, resetCaixa } = caixaSlice.actions;

export const caixaReducer = caixaSlice.reducer;
