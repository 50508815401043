import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, IconButton } from "@mui/material";
import { useGenerateUuid } from "hooks/useGenerateUuid";

const DialogRoot = styled(Dialog)(({ theme }) => ({
    '.MuiPaper-root': {
        padding: '40px',
    },
}));

type CustomDialogProps = {
    hideCloseButton?: boolean;
} & DialogProps;

const CustomDialog = (props: CustomDialogProps) => {
    const { hideCloseButton, ...dialogProps } = props;
    const { onClose, children, id } = dialogProps;
    const [randomUuid] = useGenerateUuid();

    const getId = () => {
        const sufix = '-dialog';
        return id ? id + sufix : randomUuid({ sufix });
    };

    return (
        <DialogRoot
            {...dialogProps}
            id={getId()}
        >
            {(onClose && !hideCloseButton) &&
                <IconButton
                    onClick={(e) => { onClose?.(e, 'escapeKeyDown'); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            }
            {children}

        </DialogRoot>
    );
};

export default CustomDialog;
