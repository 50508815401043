import { Autocomplete, DialogActions, DialogContent, DialogTitle, Grid, Icon, TextField, Typography } from "@mui/material";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { IAbstractModel } from 'model/abstract.model';
import RegistraVendaService from 'pages/registra-venda/registra-venda.service';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { currencyOf } from 'utils/util';
import { ItemRelatorioVendas } from "../vendas.model";
import useCatchApiError from "hooks/useCatchApiError";


type EditaVendedorProps = {
    itemRef: React.MutableRefObject<ItemRelatorioVendas | null>;
    onClose: () => void;
    onEditaVendedor: (vendedor: IAbstractModel) => void;
}

type EditaVendedorInitialState = {
    vendedores: Array<IAbstractModel>,
    vendedor: IAbstractModel | null,
    vendedoresFiltro: string,
}

const initialState: EditaVendedorInitialState = {
    vendedores: [],
    vendedor: null,
    vendedoresFiltro: "",
}

const EditaVendedor = (props: EditaVendedorProps) => {
    const service = RegistraVendaService();
    const [stateLocal, setStateLocal] = useState(initialState);
    const { closeAllSnacks } = useSnackbarCustom();
    const { throwErrorMessage } = useCatchApiError()
    const navigate = useNavigate();
    const vendedoresRef = useRef<boolean>(false);
    const vendedorRef = useRef<HTMLInputElement>(null);

    const getVendedoresAc = (toSearch: string = '') => {
        closeAllSnacks()
        service.getVendedoresAc(toSearch)
            .then(({ data }) => {
                setStateLocal(prevState => ({ ...prevState, vendedores: data }));
            }).catch(error => {
                throwErrorMessage(error)
                navigate('/caixa');
            })
    };

    const vendedoresOptions = () => {
        const vendedores = stateLocal.vendedores;
        const filtro = stateLocal.vendedoresFiltro;

        if (!filtro) { return vendedores; }

        const toReturn = vendedores.filter(e =>
            e.descritivo.toLowerCase().includes(filtro.toLowerCase())
        );

        if (!toReturn.length && filtro) { vendedoresRef.current = true; }

        return toReturn;
    };

    const onInputChangeVendedoresAc = useCallback((e: SyntheticEvent<Element, Event>, value: string) => {
        setStateLocal(prevState => ({ ...prevState, vendedoresFiltro: value }));
    }, []);

    const onSelectVendedor = useCallback((e: SyntheticEvent<Element, Event>, value: IAbstractModel | null) => {
        setStateLocal(prevState => ({ ...prevState, vendedor: value }))
    }, []);


    useEffect(() => {
        if (vendedoresRef.current || !stateLocal.vendedoresFiltro.length) {
            vendedoresRef.current = false;
            getVendedoresAc(stateLocal.vendedoresFiltro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendedoresRef.current, stateLocal.vendedoresFiltro]);

    return (
        <CustomDialog
            open
            onClose={props.onClose}
            fullWidth
            maxWidth={'md'}

        >
            <Grid xs={12} sm={6} lg={6} style={{
                minHeight: '150px'
            }}>
                <DialogTitle id="dialog-title" textAlign={'center'}>
                    <Typography fontSize={30} paddingTop={0}>Selecione um Vendedor</Typography>
                </DialogTitle>

                <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography>Pedido:</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography>{props.itemRef.current?.numeroPedido}</Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography>Cliente:</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography>{props.itemRef.current?.cliente}</Typography>

                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography>Total do Pedido:</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography>{currencyOf(props.itemRef.current?.valorPedido)}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>


                <Autocomplete
                    onBlur={() => getVendedoresAc("")}
                    id="acVendedor"
                    value={stateLocal.vendedor}
                    options={vendedoresOptions()}
                    getOptionLabel={(option) => option.descritivo}
                    onInputChange={onInputChangeVendedoresAc}
                    onChange={onSelectVendedor}
                    filterOptions={(x) => x}
                    noOptionsText={'Nenhum resultado encontrado'}
                    renderInput={
                        (params) => <TextField {...params}
                            label="Vendedor"
                            variant="standard"
                            inputRef={vendedorRef}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <h4 className="h4-formatado">(F6)</h4>
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />}
                />
            </Grid>
            <DialogActions
                sx={{
                    justifyContent: 'space-between',
                    marginTop: '40px'
                }}>
                <CustomButton onClick={props.onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                    Fechar
                </CustomButton>
                <CustomButton
                    type="submit"
                    onClick={() => props.onEditaVendedor(stateLocal.vendedor!)}
                    disabled={!stateLocal.vendedor}
                    variant="contained"
                    color="success"
                    startIcon={<Icon className='fa-solid fa-check' />}
                >
                    Confirmar
                </CustomButton>
            </DialogActions>
        </CustomDialog>
    )
}

export default EditaVendedor;
