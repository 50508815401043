import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowEditStopReasons,
    ptBR
} from '@mui/x-data-grid';

import { Grid, Icon, TableCell } from '@mui/material';
import ConfiguracaoDescontoModal from './ConfiguracaoDescontoModal';
import { clamp, deepCopy } from 'utils/util';
import ConfiguracaoDescontoService from './Configuracoes.service';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { ConfigurarDescontosInitialState, UsuarioTO, rowGrid } from './Configuracoes.model';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import CustomPercent from 'pages/Venda/CustomPercent';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import HeaderOuBannerDependendoDaInterpretacaoH1 from 'layout/header-ou-banner-dependendo-da-interpretacao-h1/HeaderOuBannerDependendoDaInterpretacaoH1';

const initialState: ConfigurarDescontosInitialState = {
    showAddConfiguracao: false,
    rows: [],
    usuarios: [],
    usuariosSelecionados: [],
    descontoMaximoNoItem: 0,
    descontoMaximoPedido: 0,
    autorizaDesconto: false
};

const ConfigurarDescontos = (props: { onGoBack: () => void; }) => {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [stateLocal, setStateLocal] = useState(initialState);
    const configuracaoDescontoService = ConfiguracaoDescontoService();
    const firstRenderRef = useRef(true);
    const navigate = useNavigate();
    const { addError, addSuccess, closeAllSnacks } = useSnackbarCustom();
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);
    const { caixaAberto } = useAppSelector(e => e.caixa.caixaAtual);


    const getDados = () => {
        let usuarios: Array<UsuarioTO> = [];
        let rows: Array<rowGrid> = [];

        configuracaoDescontoService.getUsuarios()
            .then(({ data }: { data: Array<UsuarioTO>; }) => {
                usuarios = data;
                return configuracaoDescontoService.getConfiguracoes();
            })
            .then(({ data }: { data: Array<rowGrid>; }) => {
                data.map((e, i) => e["id"] = (i + 1));
                rows = data;

                rows.forEach(e => {
                    e.descontoMaximoItem = Number(e.descontoMaximoItem.toFixed(2));
                    e.descontoMaximoPedido = Number(e.descontoMaximoPedido.toFixed(2));
                });

                usuarios.forEach((e, i) => {
                    const find = rows.find(el => el.usuario === e.username);
                    if (find) {
                        usuarios.splice(i, 1);
                    }
                });
                setStateLocal(prevState => ({ ...prevState, rows, usuarios }));
            })
            .catch(err => {
                addError({ id: "getconfiguracoes-error", message: "Não foi possivel carregar suas configurações, tente novamente. Se o erro persistir, contate o suporte", closeable: true });
            });
    };

    useEffect(() => {
        if (firstRenderRef.current) {
            getDados();
        }
        firstRenderRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        getDados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estabelecimento.uuid]);



    const openAddConfiguracao = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showAddConfiguracao: true }));
    }, []);

    const closeAddConfiguracao = useCallback(() => {
        setStateLocal(prevState => ({
            ...prevState,
            showAddConfiguracao: false,
            usuariosSelecionados: initialState.usuariosSelecionados,
            descontoMaximoNoItem: initialState.descontoMaximoNoItem,
            descontoMaximoPedido: initialState.descontoMaximoPedido,
            autorizaDesconto: initialState.autorizaDesconto
        }));
    }, []);

    const addUsuario = useCallback((usuariosSelecionados: Array<UsuarioTO>) => {
        setStateLocal(prevState => ({ ...prevState, usuariosSelecionados }));
    }, []);

    const addAll = () => {
        const toReturn: Array<UsuarioTO> = [];
        const copiaDeUsuarios = [...stateLocal.usuarios];

        copiaDeUsuarios.forEach(e => {
            toReturn.push(e);
        });

        setStateLocal(prevState => ({ ...prevState, usuariosSelecionados: toReturn }));
    };

    const removeAll = () => {
        setStateLocal(prevState => ({ ...prevState, usuariosSelecionados: [] }));
    };


    const EditToolbar = () => {
        return (
            <GridToolbarContainer>
                <Button color="warning" startIcon={<AddIcon />} onClick={openAddConfiguracao}>
                    Adicionar configuração
                </Button>
            </GridToolbarContainer>
        );
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId, row: rowGrid) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const canDeleteRef = useRef<boolean>(true);

    const handleDeleteClick = (id: GridRowId) => () => {
        if (canDeleteRef.current) {
            canDeleteRef.current = false;
            const rowToDelete = stateLocal.rows.find(e => e.id === id);
            if (rowToDelete) {
                configuracaoDescontoService.deleteConfiguracao(rowToDelete.uuid!)
                    .then(() => {
                        closeAllSnacks();
                        canDeleteRef.current = true;
                        setStateLocal(prevState => ({ ...prevState, rows: stateLocal.rows.filter((row) => row.id !== id) }));
                        configuracaoDescontoService.getUsuarios()
                            .then(({ data }: { data: Array<UsuarioTO>; }) => {
                                setStateLocal(prevState => ({ ...prevState, usuarios: data }));
                                addSuccess({ message: "Configuração excluida com sucesso", closeable: true, hideDuration: 2000, id: "user-delete-configuracao-desconto", persist: false, preventDuplicate: false });
                            })
                            .catch(error => {
                                addError({ id: "configurar-desconto-atualizar-error", message: "Não foi possivel atualizar suas configurações, tente novamente. Se o erro persistir, contate o suporte", closeable: true });
                                canDeleteRef.current = true;
                            });
                    })
                    .catch(error => {
                        addError({ id: "configurar-desconto-deletar-error", message: `Não foi possivel deletar a configuração do ${rowToDelete.usuario}, tente novamente. Se o erro persistir, contate o suporte`, closeable: true });
                        canDeleteRef.current = true;
                    });

            }
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

    };

    const processRowUpdate = (newRow: rowGrid) => {
        newRow.descontoMaximoItem = parseFloat(clamp(parseFloat(newRow.descontoMaximoItem.toString().replace(",", ".")), 0, 100).toFixed(2));
        newRow.descontoMaximoPedido = parseFloat(clamp(parseFloat(newRow.descontoMaximoPedido.toString().replace(",", ".")), 0, 100).toFixed(2));

        const updatedRow: rowGrid = { ...newRow };
        const copy = deepCopy(stateLocal.rows);

        copy.forEach((e: rowGrid) => {
            if (e.id === updatedRow.id) {
                e.descontoMaximoItem = Number(updatedRow.descontoMaximoItem.toFixed(2));
                e.descontoMaximoPedido = Number(updatedRow.descontoMaximoPedido.toFixed(2));
                e.autorizaDesconto = updatedRow.autorizaDesconto;
            }
        });

        configuracaoDescontoService.updateConfiguracao(newRow)
            .then(() => {
                setStateLocal(prevState => ({ ...prevState, rows: copy }));
                addSuccess({ message: "Configuração do usuário atualizada com sucesso", closeable: true, hideDuration: 2000, id: "user-update-configuracao-desconto" });
            })
            .catch(err => {
                addError({ id: 'update-configuracao-error', message: 'Erro ao salvar, tente novamente. Se o erro persistir, entre em contato com o suporte', persist: false, closeable: true });
            });


        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const setDescontoItem = useCallback((maxDesc: number) => {
        setStateLocal(prevState => ({ ...prevState, descontoMaximoNoItem: maxDesc }));
    }, []);

    const setDescontoMaxPedido = useCallback((maxDesc: number) => {
        setStateLocal(prevState => ({ ...prevState, descontoMaximoPedido: maxDesc }));
    }, []);

    const setAutorizaDesconto = useCallback((autoriza: string) => {
        if (autoriza.match("SIM")) {
            return setStateLocal(prevState => ({ ...prevState, autorizaDesconto: true }));
        }
        return setStateLocal(prevState => ({ ...prevState, autorizaDesconto: false }));
    }, []);


    const onConfirmModal = () => {
        let rowsAtual: Array<rowGrid> = deepCopy(stateLocal.rows);
        let newRows: Array<rowGrid> = [];
        let lastIndex: number = rowsAtual.length > 0 ? rowsAtual[rowsAtual.length - 1].id : 0;
        let newId: number = (lastIndex) + 1;

        const novoUsuario: Array<UsuarioTO> = deepCopy(stateLocal.usuariosSelecionados);
        const usuarios: Array<UsuarioTO> = deepCopy(stateLocal.usuarios);
        const descontoMaximoItem = deepCopy(stateLocal.descontoMaximoNoItem);
        const descontoMaximoPedido = deepCopy(stateLocal.descontoMaximoPedido);
        const autorizaDesconto = deepCopy(stateLocal.autorizaDesconto);


        novoUsuario.forEach((e: UsuarioTO) => {
            newRows.push({
                id: newId,
                usuario: e.username,
                descontoMaximoItem: descontoMaximoItem,
                descontoMaximoPedido: descontoMaximoPedido,
                autorizaDesconto: autorizaDesconto,
                uuid: e.uuid
            });
            newId++;
        });

        if (newRows) {
            configuracaoDescontoService.saveAllConfiguracoes(newRows)
                .then(({ data }: { data: Array<rowGrid>; }) => {
                    data.forEach(el => {
                        rowsAtual.push({
                            autorizaDesconto: el.autorizaDesconto,
                            descontoMaximoItem: el.descontoMaximoItem,
                            descontoMaximoPedido: el.descontoMaximoPedido,
                            usuario: el.usuario,
                            uuid: el.uuid,
                            id: newRows.find(e => e.usuario === el.usuario)?.id ?? 0
                        });
                    });
                    addSuccess({ id: "users-added-sucess", message: "Configurações criadas com sucesso", closeable: true, hideDuration: 2000, persist: false });
                    return setStateLocal((prevState) => ({
                        ...prevState,
                        rows: rowsAtual,
                        showAddConfiguracao: false,
                        usuarios,
                        usuariosSelecionados: initialState.usuariosSelecionados,
                        descontoMaximoNoItem: initialState.descontoMaximoNoItem,
                        descontoMaximoPedido: initialState.descontoMaximoPedido,
                        autorizaDesconto: initialState.autorizaDesconto
                    }));
                })
                .catch(() => {
                    return addError({ id: 'update-configuracoes-desconto-error', message: 'Não foi possivel atualizar as configuraçoes. Tente novamente, se o erro persistir, contate o suporte', persist: false, closeable: true });
                });
        }
    };


    const columns: GridColDef[] = [
        {
            field: 'usuario',
            headerName: 'Usuário',
            type: 'string',
            width: 765,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        },
        {
            field: 'descontoMaximoItem',
            headerName: 'Desconto máximo no item',
            type: 'string',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: ({ row }: { row: rowGrid; }) => { // row}: {row: rowGrid}
                return (
                    <div key={`desconto_max_item_${row.uuid}`}>
                        <TableCell sx={{ fontSize: '14px' }}>{`${row.descontoMaximoItem}%`}</TableCell>
                    </div>
                );
            }
        },
        {
            field: 'descontoMaximoPedido',
            headerName: 'Desconto máximo no rodapé',
            type: 'string',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: ({ row }: { row: rowGrid; }) => {
                return (
                    <div key={`desconto_max_item_${row.uuid}`}>
                        <TableCell sx={{ fontSize: '14px' }}>{`${row.descontoMaximoPedido}%`}</TableCell>
                    </div>
                );
            }
        },
        {
            field: 'autorizaDesconto',
            headerName: 'Autoriza desconto',
            type: 'singleSelect',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            valueOptions: [{
                value: false,
                label: "NÃO"
            }, {
                value: true,
                label: "SIM"
            }]
        },
        {
            field: 'acoes',
            type: 'actions',
            headerName: 'Ações',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id, row)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }


                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 520,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <HeaderOuBannerDependendoDaInterpretacaoH1
                id='configuracoes-desconto'
                customIcon={
                    <CustomPercent id='vendaShoppingCarIcon' color='#666666' fontSize='45px' />
                }
                label="Configurações desconto"
            />

            <DataGrid
                rows={stateLocal.rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setStateLocal, setRowModesModel },
                }}
                localeText={{
                    ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                    noRowsLabel: "Nenhuma configuração encontrada"
                }}

            />
            {stateLocal.showAddConfiguracao &&
                <ConfiguracaoDescontoModal
                    onConfirm={onConfirmModal}
                    closeAddConfiguracao={closeAddConfiguracao}
                    usuarios={stateLocal.usuarios}
                    usuariosSelecionados={stateLocal.usuariosSelecionados}
                    onAddUsuario={addUsuario}
                    onAddAll={addAll}
                    onRemoveAll={removeAll}
                    descontoMaximoNoItem={stateLocal.descontoMaximoNoItem}
                    onSetMaxDescItem={setDescontoItem}
                    descontoMaximoPedido={stateLocal.descontoMaximoPedido}
                    onSetDescontoMaximoPedido={setDescontoMaxPedido}
                    autorizaDesconto={stateLocal.autorizaDesconto}
                    autorizaDescontoOptions={[false, true]}
                    onChangeAutorizaDesconto={setAutorizaDesconto}
                    rows={stateLocal.rows}
                />
            }
            <Grid item
                sx={{
                    position: 'absolute',
                    bottom: '25px',
                    width: '80%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                <CustomButton
                    sx={{ minWidth: '80px' }}
                    id="voltar-configuracao-vale-presente-btn"
                    onClick={props.onGoBack}
                    variant="contained"
                    color="error"
                    startIcon={<Icon className='fa-solid fa-arrow-left' />}>
                    Voltar
                </CustomButton>

                <CustomButton
                    sx={{ minWidth: '80px' }}
                    id="voltar-configuracao-vale-presente-btn"
                    onClick={() => navigate("/registra-venda")}
                    disabled={!caixaAberto ? true : false}
                    variant="contained"
                    color="success"
                    startIcon={<Icon className='fa-solid fa-check' />}>
                    Ir para o pdv
                </CustomButton>
            </Grid>
        </Box>

    );
};

export default ConfigurarDescontos;
