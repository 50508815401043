import { Autocomplete, Box, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import DialogContext from "components/confirm-dialog-context";
import CustomDialog from "components/custom/CustomDialog";
import { CustomButton } from "components/roundedbutton/CustomButton";
import dayjs from "dayjs";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import HeaderOuBannerDependendoDaInterpretacaoH1 from "layout/header-ou-banner-dependendo-da-interpretacao-h1/HeaderOuBannerDependendoDaInterpretacaoH1";
import { BEEPSOUND } from "pages/registra-venda/registra-venda";
import { FormEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { setChavesReferenciadas } from "redux/features/notaReferenciadaSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { globalStyles } from "styles/global";
import { includesStringInOrder, toLocalDateBackEndFriendly } from "utils/util";
import NotaReferenciadaService from "./NotaReferenciada.service";
import { columns, initialGridState, initialLocalState, NotaFiscalVendaReferenciarFiltroDTO, NotaFiscalVendaReferenciarRowResponseDTO, TrocaReferenciadaDilogProps } from "./troca-referenciada.model";

const TrocaReferenciadaDialog = (props: TrocaReferenciadaDilogProps) => {

    const isFirstRenderRef = useRef(true);
    const [gridState, setGridState] = useState(initialGridState);
    const [localState, setLocalState] = useState(initialLocalState);
    const [isAddingChaveAcesso, setIsAddingChaveAcesso] = useState(true);
    const dialog = useContext(DialogContext);
    const service = NotaReferenciadaService();
    const { addError } = useSnackbarCustom();
    const dispatch = useAppDispatch();
    const consumidorGlobalState = useAppSelector(s => s.state.pedido.cliente);
    const estabelecimentosGlobalState = useAppSelector(s => s.token.ESTABELECIMENTOS);
    const itensTroca = useAppSelector(s => s.state.pedido.itensTroca);

    const estabelecimentoRef = useRef<HTMLInputElement>(null);
    const pedidosRef = useRef<HTMLInputElement>(null);
    const seriesRef = useRef<HTMLInputElement>(null);
    const numerosRef = useRef<HTMLInputElement>(null);
    const chaveAcessoRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isFirstRenderRef.current) return;
        getEstabelecimentosAC();
        getPedidosAC();
        getSeriesAC();
        getNumerosAC();

        isFirstRenderRef.current = false;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { procurandoPorFiltro } = localState;
        const procurandoProNotaMasSemNota = !procurandoPorFiltro && !chaveAcessoRef.current?.value;
        if (!consumidorGlobalState || !props.open || procurandoProNotaMasSemNota || isFirstRenderRef.current || !isAddingChaveAcesso) return;
        getNotas();
        // eslint-disable-next-line
    }, [localState, consumidorGlobalState, props.open]);

    const getNotas = () => {
        setGridState(prev => ({ ...prev, isLoading: true }));
        const { estabelecimentos, pedidos, series, numeros, dataInicio, dataFim } = localState;
        const chaveAcesso = chaveAcessoRef.current?.value;
        if (chaveAcessoRef.current) chaveAcessoRef.current.value = '';
        const filtro: NotaFiscalVendaReferenciarFiltroDTO = {
            cliente: consumidorGlobalState!.uuid,
            estabelecimentos: estabelecimentos.map(s => s.uuid),
            pedidos: pedidos.map(s => s.uuid),
            series: series.map(s => s.uuid),
            numeros: numeros.map(s => s.uuid),
            dataEmissaoInicio: toLocalDateBackEndFriendly(dataInicio),
            dataEmissaoFim: toLocalDateBackEndFriendly(dataFim),
            chaveAcesso: !chaveAcesso ? null : chaveAcesso.trim()
        };

        service.filtroAvancado(filtro)
            .then(({ data }) => {
                setGridState(prev => {
                    if (!localState.procurandoPorFiltro && !data.length) {
                        dialog.confirm({
                            error: true,
                            title: 'Atenção',
                            message: `NOTA FISCAL DE VENDAS COM CÓDIGO DE BARRAS ${chaveAcesso} NÃO ENCONTRADO PARA ESSE CLIENTE`,
                            onConfirm: () => { }
                        });
                    }
                    return {
                        ...prev,
                        rows: data,
                        selectedRowsChaveAcesso: localState.procurandoPorFiltro ? prev.selectedRowsChaveAcesso : data.map(s => s.chaveAcesso)
                    };
                });
            })
            .catch(() => {
                if (!localState.procurandoPorFiltro) {
                    BEEPSOUND.play();
                    dialog.confirm({
                        error: true,
                        title: 'Atenção',
                        message: `NOTA FISCAL DE VENDAS COM CÓDIGO DE BARRAS ${chaveAcesso} NÃO ENCONTRADO PARA ESSE CLIENTE`,
                        onConfirm: () => { }
                    });
                }
            })
            .finally(() => {
                setGridState(prev => ({ ...prev, isLoading: false }));
            });
    };

    const onClose = () => {
        props.setOpen(false);
        setTimeout(() => {
            resetLocalState(true);
        },);
    };

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();
        const chavesSelecionadas = gridState.selectedRowsChaveAcesso;
        if (!chavesSelecionadas.length) return addError({ message: 'Selecione ao menos uma nota fiscal para prosseguir.', closeable: true });

        if (!itensTroca.length) return finalizar();
        confirmarNotasSemSkuPresente();
    };

    const confirmarNotasSemSkuPresente = () => {
        const notas = getNotasState();

        const skusUUIDTroca = itensTroca.map(s => s.produto.uuid);
        const notasSemSkuPresente: NotaFiscalVendaReferenciarRowResponseDTO[] = [];
        notas.forEach(nota => {
            const skusDaNota = nota.skus;
            const existeSkuNaNota = skusDaNota.some(skuNota => skusUUIDTroca.some(skuTroca => skuNota === skuTroca));
            if (!existeSkuNaNota) notasSemSkuPresente.push(nota);
        });

        if (notasSemSkuPresente.length) {
            dialog.confirm({
                error: false,
                title: 'Atenção',
                message: <div>
                    <Box sx={{ textAlign: 'center' }}>
                        <Box sx={{ mb: '10px' }}>AS NOTAS FISCAIS A SEGUIR NÃO POSSUEM NENHUM DOS SKUS SELECIONADOS PARA TROCA:</Box>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Série</TableCell>
                                    <TableCell align="center">Número</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notasSemSkuPresente.map((n, i) =>
                                    <TableRow key={i}>
                                        <TableCell align="center">{n.serie}</TableCell>
                                        <TableCell align="center">{n.numero}</TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>

                        <Box sx={{ mt: '10px' }}>DESEJA REFERENCIAR AS NOTAS FISCAIS MESMO ASSIM?</Box>
                    </Box>
                </div>,
                onConfirm: () => { confirmarSobrescreverNotas(); }
            });
        } else {
            confirmarSobrescreverNotas();
        }
    };

    const confirmarSobrescreverNotas = () => {
        dialog.confirm({
            error: false,
            title: 'Atenção',
            message: 'Ao confirmar, todas as notas referenciadas automáticamente ou referenciadas anteriormente serão substituídas. Deseja continuar?',
            onConfirm: () => { finalizar(); }
        });
    };

    const finalizar = () => {
        props.setOpen(false);
        const notas = getNotasState();
        dispatch(setChavesReferenciadas(notas));
        resetLocalState(true);
    };

    const getNotasState = () => {
        return gridState.rows.filter(row => !!gridState.selectedRowsChaveAcesso.find(s => s === row.chaveAcesso)) as NotaFiscalVendaReferenciarRowResponseDTO[];
    };

    const onInputChangeEstabelecimento = (e: unknown, value: string = '') => {
        getEstabelecimentosAC(value);
    };

    const getEstabelecimentosAC = (toSearch: string = '') => {
        setLocalState(prev => ({
            ...prev,
            estabelecimentosAc: estabelecimentosGlobalState.filter(estab => includesStringInOrder(estab.descritivo, toSearch))
        }));

    };

    const onInputChangePedidos = (e: unknown, value: string = '') => {
        getPedidosAC(value);
    };

    const getPedidosAC = (toSearch: string = '') => {
        service.numerosPedidos({
            search: toSearch,
        }).then(({ data }) => {
            setLocalState(prev => ({ ...prev, pedidosAc: data }));
        }).catch(() => { });
    };

    const onInputChangeSeries = (e: unknown, value: string = '') => {
        getSeriesAC(value);
    };

    const getSeriesAC = (toSearch: string = '') => {
        service.series({
            search: toSearch,
        }).then(({ data }) => {
            setLocalState(prev => ({ ...prev, seriesAc: data }));
        }).catch(() => { });
    };

    const onInputChangeNumeros = (e: unknown, value: string = '') => {
        getNumerosAC(value);
    };

    const getNumerosAC = (toSearch: string = '') => {
        service.numerosNotas({
            search: toSearch,
        }).then(({ data }) => {
            setLocalState(prev => ({ ...prev, numerosAc: data }));
        }).catch(() => { });
    };

    const onSelect = <T extends typeof initialLocalState, K extends keyof T>(key: K, value: T[K]): void => {
        setLocalState(prev => ({ ...prev, [key]: value }));
    };

    const handleAddOrRemove = useCallback(() => {
        setIsAddingChaveAcesso(prev => !prev);
    }, []);

    const resetLocalState = (resetarTipoFiltro = false) => {
        setLocalState(prev => ({
            ...prev,
            dataFim: new Date(),
            dataInicio: new Date(),
            estabelecimentos: [],
            numeros: [],
            pedidos: [],
            series: [],
            procurandoPorFiltro: resetarTipoFiltro ? true : prev.procurandoPorFiltro
        }));
    };

    const addOrRemoveChaveAcesso = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const inputValue = (chaveAcessoRef.current?.value ?? '').trim();
        if (e.key !== 'Enter' || !inputValue) return;
        if (inputValue.length !== 44) return addError({ message: 'Chave acesso deve possuir 44 caractéres', closeable: true, id: 'chave-acesso-error' });
        if (!isAddingChaveAcesso) {
            chaveAcessoRef.current!.value = '';
            return setGridState(prev => ({ ...prev, rows: prev.rows.filter(s => s.chaveAcesso !== inputValue) }));
        };
        resetLocalState();
    };

    const handleToggleTipoFiltro = (): void => {
        setLocalState(prev => {
            const valorAtualizado = !prev.procurandoPorFiltro;
            if (valorAtualizado) setIsAddingChaveAcesso(true);
            return {
                ...prev,
                cliente: null,
                dataFim: new Date(),
                dataInicio: new Date(),
                estabelecimentos: [],
                numeros: [],
                pedidos: [],
                series: [],
                procurandoPorFiltro: valorAtualizado,
            };
        });
        setGridState(initialGridState);
    };

    return (
        <CustomDialog
            open={props.open}
            maxWidth={'xl'}
            fullWidth
            onClose={onClose}
        >

            <form style={{ gap: 0 }}>
                <DialogTitle id="dialog-title" textAlign={'center'}>
                    <HeaderOuBannerDependendoDaInterpretacaoH1
                        id='troca-referenciada'
                        iconClassName='fa-regular fa-file-lines'
                        label='Referenciar nota'
                    />
                </DialogTitle>

                <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>

                    <Grid container spacing={2}>
                        {localState.procurandoPorFiltro &&
                            <>
                                <Grid item xs={12} md={2.5} >
                                    <Autocomplete
                                        value={localState.estabelecimentos}
                                        multiple
                                        id="estab"
                                        options={localState.estabelecimentosAc}
                                        getOptionLabel={(option) => option.descritivo}
                                        filterOptions={(x) => x}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        onInputChange={onInputChangeEstabelecimento}
                                        onChange={(_, value) => onSelect('estabelecimentos', value)}
                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.uuid}>
                                                    {option.descritivo}
                                                </li>
                                            );
                                        }}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                label="Estabelecimento"
                                                variant="standard"
                                                inputRef={estabelecimentoRef}
                                            />}
                                    />
                                </Grid>

                                <Grid item xs={12} md={1.8} >
                                    <Autocomplete
                                        value={localState.pedidos}
                                        multiple
                                        id="pedidos"
                                        options={localState.pedidosAc}
                                        getOptionLabel={(option) => option.descritivo}
                                        filterOptions={(x) => x}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        onInputChange={onInputChangePedidos}
                                        onChange={(_, value) => onSelect('pedidos', value)}
                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                label="Pedidos"
                                                variant="standard"
                                                inputRef={pedidosRef}
                                            />}
                                    />
                                </Grid>

                                <Grid item xs={12} md={1.8} >
                                    <Autocomplete
                                        value={localState.series}
                                        multiple
                                        id="series"
                                        options={localState.seriesAc}
                                        getOptionLabel={(option) => option.descritivo}
                                        filterOptions={(x) => x}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        onInputChange={onInputChangeSeries}
                                        onChange={(_, value) => onSelect('series', value)}
                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                label="Series"
                                                variant="standard"
                                                inputRef={seriesRef}
                                            />}
                                    />
                                </Grid>

                                <Grid item xs={12} md={1.8} >
                                    <Autocomplete
                                        value={localState.numeros}
                                        multiple
                                        id="numeros"
                                        options={localState.numerosAc}
                                        getOptionLabel={(option) => option.descritivo}
                                        filterOptions={(x) => x}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        onInputChange={onInputChangeNumeros}
                                        onChange={(_, value) => onSelect('numeros', value)}
                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                label="Números"
                                                variant="standard"
                                                inputRef={numerosRef}
                                            />}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={1.8} >
                                    <DatePicker
                                        maxDate={dayjs(localState.dataFim)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                id: `dataInicio`,
                                                variant: 'standard',
                                                onKeyDown: (e: any) => { e.preventDefault(); },
                                            }
                                        }}
                                        label='Data início'
                                        format='DD/MM/YYYY'
                                        sx={{
                                            width: '100%',
                                        }}
                                        value={dayjs(localState.dataInicio)}
                                        onChange={(e: any) => onSelect('dataInicio', e.$d)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={1.8} >
                                    <DatePicker
                                        maxDate={dayjs(new Date())}
                                        minDate={dayjs(localState.dataInicio)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                id: `dataFim`,
                                                variant: 'standard',
                                                onKeyDown: (e: any) => { e.preventDefault(); },
                                            }
                                        }}
                                        label='Data fim'
                                        format='DD/MM/YYYY'
                                        sx={{
                                            width: '100%',
                                        }}
                                        value={dayjs(localState.dataFim)}
                                        onChange={(e: any) => onSelect('dataFim', e.$d)}
                                    />
                                </Grid>
                            </>
                        }


                        {!localState.procurandoPorFiltro &&
                            <Grid item xs={10.5} sm={11} md={11.5}>
                                <TextField
                                    fullWidth={true}
                                    id="chaveAcesso"
                                    label="Chave acesso"
                                    variant="standard"
                                    inputProps={{
                                        maxLength: 44
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <Tooltip
                                                    title={`${!isAddingChaveAcesso ? "Adicionar item ( Alt + '+' )" : "Remover item ( Alt + '-' )"}`}>
                                                    <IconButton
                                                        onClick={handleAddOrRemove}
                                                        sx={{ height: 0, padding: 0, paddingRight: '5px' }}
                                                    >
                                                        <Icon className={`${isAddingChaveAcesso ? "fa-solid fa-plus" : "fa-solid fa-minus"}`}
                                                            sx={{
                                                                color: globalStyles.LARANJA,
                                                                paddingBottom: '5px'
                                                            }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ),
                                        endAdornment: (
                                            <IconButton sx={{ height: 0, padding: 0, paddingLeft: '5px' }}>
                                                <i className="fa-solid fa-barcode" />
                                            </IconButton>
                                        )
                                    }}
                                    onKeyUp={addOrRemoveChaveAcesso}
                                    inputRef={chaveAcessoRef}
                                />
                            </Grid>
                        }

                        <Grid item xs={1.5} sm={1} md={0.5} sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }} >
                            <CustomButton
                                onClick={handleToggleTipoFiltro}
                                sx={{
                                    minWidth: 'unset',
                                    padding: '5px',
                                    color: 'var(--laranja)'
                                }}
                            >
                                <Tooltip title={localState.procurandoPorFiltro ? 'Filtro por chave eletrônica' : 'Filtro avançado'}>
                                    <Icon className={localState.procurandoPorFiltro ? 'fa-solid fa-barcode' : 'fa-solid fa-filter'} sx={{ color: 'var(--laranja)', fontSize: '25px' }} />
                                </Tooltip>
                            </CustomButton>
                        </Grid>

                        <Grid item xs={12} sx={{ height: 'calc(100vh - 430px)', minHeight: '350px', width: '100%' }}>
                            <DataGrid
                                localeText={{
                                    ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                                    noRowsLabel: "Nenhuma nota fiscal de venda encontrada"
                                }}
                                rows={gridState.rows as any[]}
                                columns={columns}
                                getRowId={(row: NotaFiscalVendaReferenciarRowResponseDTO) => row.chaveAcesso}
                                disableColumnSelector
                                loading={gridState.isLoading}
                                checkboxSelection
                                isRowSelectable={() => localState.procurandoPorFiltro}
                                onRowSelectionModelChange={selectedRowsChaveAcesso => setGridState(prev => ({ ...prev, selectedRowsChaveAcesso }))}
                                rowSelectionModel={gridState.selectedRowsChaveAcesso}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <CustomButton onClick={onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Cancelar
                    </CustomButton>
                    <CustomButton onClick={onConfirm} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                        Confirmar
                    </CustomButton>
                </DialogActions>
            </form>
        </CustomDialog>
    );
};

export default TrocaReferenciadaDialog;
