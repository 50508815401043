import { AxiosResponse } from "axios";
import { axiosRequest } from "../../axios/axios.config";

import { FlowEventoNotaEmitidaTypeDTO } from "pages/evento-nota-fiscal/evento-nota-fiscal.model";
import LoginService from "pages/login/login.service";
import { EventoNotaFiscalEmitida } from "pages/pagamento/pagamento.model";

const DfeService = () => {
  const path = "/flow/dfe";
  const loginService = LoginService();

  return {
    atualizarSituacao(chaveAcesso: string): Promise<
      AxiosResponse<{
        eventoType: FlowEventoNotaEmitidaTypeDTO;
        eventoSaida: EventoNotaFiscalEmitida;
        eventoEntrada?: EventoNotaFiscalEmitida;
      }>
    > {
      return axiosRequest.post(`${path}/atualizarsituacao`, chaveAcesso, {
        headers: loginService.getHeaders(),
      });
    },
  };
};

export default DfeService;
