import { Box, Card, Grid, Icon, TextField } from '@mui/material';
import CustomToolTips from 'components/CustomToolTips';
import FloatingActions, { FloatingActionsProps } from 'components/floatingactions/FloatingActions';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { getFormaPagamentoAbstracModel } from 'components/telaformaspagamento/TelaFormasPagamentoComponent';
import { useGenerateUuid } from 'hooks/useGenerateUuid';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { IToken } from 'model/token.model';
import AutorizaDesconto from 'pages/Configuracoes/AutorizaDesconto';
import AutorizaDescontoItemError from 'pages/Configuracoes/AutorizaDescontoItemError';
import { ConfiguracaoDescontoTO, DescontoMaximo } from 'pages/Configuracoes/Configuracoes.model';
import ConfiguracaoDescontoService from 'pages/Configuracoes/Configuracoes.service';
import CustomLoading from 'pages/Venda/Header/CustomLoading';
import LoginService from 'pages/login/login.service';
import TrocaReferenciadaDialog from 'pages/nota-referenciada/TrocaReferenciadaDialog';
import { TipoInformacaoAdicional } from 'pages/pagamento/pagamento.model';
import PagamentoService from 'pages/pagamento/pagamento.service';
import DescontoInput from 'pages/registra-venda/DescontoInput';
import PreVendaPdf from 'pages/registra-venda/PreVendaPdf';
import RegistraVendaService from 'pages/registra-venda/registra-venda.service';
import ValePresenteDialog from 'pages/vale-presente/ValePresenteDialog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { SourceType } from 'react-number-format/types/types';
import { useLocation } from 'react-router-dom';
import { changeLinearFinalizaVenda, loadingWithOpt, Tipo } from 'redux/features/blockuiSlice';
import { setConfiguracaoDescontoAutorizador } from 'redux/features/configuracoes';
import { resetDesconto } from 'redux/features/descontoFixoSlice';
import { addRow, changeTotalLiquido, resetPagamento, resetRows, setvalorDescontoPagamento } from 'redux/features/pagamentoSlice';
import { addPedidoDesconto, resetDescontoFooter, resetState, setDescontoFooter, setValorLiquido, toogleTipoDescontFooter } from 'redux/features/stateSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import useCustomLocation from 'utils/useCustomLocation';
import { currencyOf, isLayoutXs, roundHalfToEven, roundToTwoDecimalPlaces, toFixed } from 'utils/util';
import "./footer.css";
import { resetChavesReferenciadas } from 'redux/features/notaReferenciadaSlice';
import useCatchApiError from 'hooks/useCatchApiError';

type Props = {
    onClickAvancar: () => void;
    onClickCancelar: () => void;
    onSwitchOptions?: () => void;
    troca?: {
        isVisible: boolean;
    };
    isDialogVisible?: boolean;
    floatingActionsProps?: FloatingActionsProps;
    brinde?: boolean;
    onClickBrinde?: () => void;
    isVisualizar?: boolean;
    showModalTroco?: () => void;
};

const initialState = {
    showModalDescontoItem: false,
    showModalDescontoNaoAutorizado: false,
    showLoading: false,
    showToolTipTroca: false,
    showToolTipValePresente: false,
};

type TPrevendaInitialState = {
    show: boolean;
    numero: string;
    cliente: string;
    vendedor: string,
    emissao: Date | string,
    valorTotalLiquido: string;

};

const initialStatePreVenda: TPrevendaInitialState = {
    show: false,
    numero: "",
    cliente: "",
    vendedor: "",
    emissao: "",
    valorTotalLiquido: ""
};

const Footer = (props: Props) => {
    const { addError, closeAllSnacks, addSuccess } = useSnackbarCustom();
    const { throwErrorMessage } = useCatchApiError()
    const { state, pagamento, valePresente } = useAppSelector(s => s);
    const { faltaReceber, tableRows, troco } = pagamento;
    const configuracaoDescontoUsuario = useAppSelector(e => e.configuracoes.configuracaoDesconto);
    const [openStateValePresenteDialog, setOpenStateValePresenteDialog] = useState(false);
    const [openStateTrocaReferenciadaDialog, setOpenStateTrocaReferenciadaDialog] = useState(false);
    const location = useLocation();
    const [randomUuid] = useGenerateUuid();
    const totalPedidoRef = useRef<HTMLInputElement>(null);
    const pagamentoService = PagamentoService();
    const registraVendaService = RegistraVendaService();
    const {
        isTelaRegistraVenda,
        isTelaPagamento,
        isTelaValePresente,
        isTelaPreVenda
    } = useCustomLocation();
    const {
        quantidadeTotal,
        quantidadeTotalTroca,
        valorTotalLiquidoTroca,
        valorTotalBruto,
        valorTotalLiquido,
        itensTroca,
        itens
    } = state.pedido;
    const {
        tipoDesconto,
        valorDesconto,
        percentualDesconto,
    } = state.pedido.pedidoDesconto;
    const { descontoMaximoPedido, uuid: userConfiguracaoDescontoUUID } = useAppSelector(e => e.configuracoes.configuracaoDesconto);
    const {
        descontoMaximoPedido: descontoMaximoPedidoAutorizador,
        descontoMaximoItem: descontoMaximoItemAutorizador,
        uuid: uuidAutorizador
    } = useAppSelector(e => e.configuracoes.configuracaoDescontoAutorizador.configuracaoDesconto);
    const configuracaoDescontoService = ConfiguracaoDescontoService();
    const tempDescontoMaximoPedido = useRef<number>(0);
    const descontoSolicitadoRef = useRef<number | null>(null);
    const token = useAppSelector(e => e.token);
    const loginService = LoginService();
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);
    const [stateLocal, setStateLocal] = useState(initialState);
    const { pedido } = useAppSelector(e => e.state);
    const [stateLocalPreVenda, setStateLocalPreVenda] = useState(initialStatePreVenda);
    const finalizarPreVendaRef = useRef<boolean>(false);

    const valorTableRows = useMemo(() => {
        return tableRows.filter(e => e.formaPagamento.descritivo !== 'Nota de crédito')
            .filter(e => e.formaPagamento.descritivo !== "OUTROS DEVOLUÇÃO")
            .filter(e => e.descritivoFormaPagamento !== "TROCO")
            .filter(e => e.isPago)
            .reduce((prev, current) => prev += current.valor, 0);
    }, [tableRows]);

    const getShowTrocoFooter = () => {
        if (!troco) return true;
        if (troco && !troco.tipoTrocoPagar) return false;
        return true;
    };

    const valorTotalBrindes = useMemo(() => {
        return itens.filter(e => e.isBrinde).reduce((prev, act) => prev + act.valorTotal, 0);
    }, [itens]);

    useEffect(() => {
        if (isTelaRegistraVenda()) {
            dispatch(setvalorDescontoPagamento({ valorDesconto, valorTotalBruto }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valorDesconto, valorTotalLiquido]);

    useEffect(() => {
        if (isTelaRegistraVenda()) {
            dispatch(setvalorDescontoPagamento({ valorDesconto: 0, valorTotalBruto: valePresente.valePresente?.valor ?? 0 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valePresente.valePresente?.valor]);

    useEffect(() => {
        if (state.pedido.itens.length > 0 && state.pedido.itensTroca.length > 0) {
            const qtdItens = state.pedido.itens.map(e => e.quantidade).reduce((prev, value) => prev + value, 0);
            const qtdItensTroca = state.pedido.itensTroca.map(e => e.quantidade).reduce((prev, value) => prev + value, 0);
            const valorTotalItens = state.pedido.itens.map(e => e.valorTotal).reduce((prev, value) => prev + value, 0);
            const valorTotalItensTroca = state.pedido.itensTroca.map(e => e.valorTotal).reduce((prev, value) => prev + value, 0);

            if ((qtdItens === qtdItensTroca) && (valorTotalItens === valorTotalItensTroca)) {
                dispatch(resetDescontoFooter());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pedido.itens, state.pedido.itensTroca]);

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            switch (e.key) {
                case 'F9':
                    e.preventDefault();
                    totalPedidoRef.current && totalPedidoRef.current.focus();
                    break;
                case 'v':
                    if (e.altKey && isTelaRegistraVenda()) {
                        e.preventDefault();
                        setOpenStateValePresenteDialog(prev => true);
                    }
                    break;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (faltaReceber < 0 && !tableRows.length) {
            addError({ id: 'erro-faltarecebermenorquevenda', message: 'Valor das formas de pagamento não pode ser maior que o total da venda', persist: false });
        } else {
            closeAllSnacks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faltaReceber]);

    const isTrocaVisible = () => {
        return props.troca?.isVisible ?? false;
        //eslint-disable-next-line
    };

    const dispatch = useAppDispatch();

    const toggleTipoDescontoFooter = () => {
        dispatch(toogleTipoDescontFooter());
    };

    const saveTempDesconto = useCallback((prevDesconto: number, uuid: string) => {
        tempDescontoMaximoPedido.current = prevDesconto;
    }, []);

    const onBlurDescontoPedido = (value: number) => {
        if (configuracaoDescontoUsuario.uuid === null) return;

        descontoSolicitadoRef.current = value;
        if (value <= descontoMaximoPedido) {
            dispatch(addPedidoDesconto({
                itemDesconto: {
                    uuidAutorizador: userConfiguracaoDescontoUUID,
                    percentualDesconto: descontoSolicitadoRef.current!,
                    tipoDesconto: tipoDesconto,
                    valorDesconto: valorDesconto
                }
            }));
        }
        if (descontoMaximoPedidoAutorizador && value <= descontoMaximoPedidoAutorizador) {
            return dispatch(addPedidoDesconto({
                itemDesconto: {
                    uuidAutorizador: uuidAutorizador,
                    percentualDesconto: descontoSolicitadoRef.current!,
                    tipoDesconto: tipoDesconto,
                    valorDesconto: valorDesconto
                }
            }));
        }
        if (value <= tempDescontoMaximoPedido.current) return tempDescontoMaximoPedido.current = 0;

        if (value > descontoMaximoPedido) {
            configuracaoDescontoService.validaDescontoSolicitado(value, DescontoMaximo.PEDIDO)
                .then(({ data }: { data: boolean; }) => {
                    if (!data) {
                        return setStateLocal(prevState => ({ ...prevState, showModalDescontoItem: false, showModalDescontoNaoAutorizado: true }));
                    }
                    return setStateLocal(prevState => ({ ...prevState, showModalDescontoItem: true }));
                })
                .catch(() => { });
        }
    };

    const onChangeDescontoFooter = useCallback((value: number = 0) => {
        dispatch(setDescontoFooter({ value }));
        dispatch(setvalorDescontoPagamento({ valorDesconto: value, valorTotalBruto }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValorLiquido = useCallback((value: number = 0, sourceEvent: SourceType) => {
        if (!isTelaValePresente()) {
            dispatch(setValorLiquido({ value, sourceEvent }));
        }
        if (sourceEvent === "event") {
            dispatch(changeTotalLiquido(value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFocusTotalizador = () => {
        if (valorTotalLiquido === 0) return totalPedidoRef.current?.setSelectionRange(4, 4);
    };

    const handleAutorizaDesconto = (username: string, password: string, switchBoolean: boolean) => {
        closeAllSnacks()
        if (username === token.USER_INFO?.username) {
            return addError({ id: "same-user-exception", message: "Seu usuário não permite este desconto, solicite à um responsável", closeable: true, persist: false });
        }

        setStateLocal(prevState => ({ ...prevState, showLoading: true }));
        let tokenAutorizador = "";
        loginService.loga({ user: username, password: password })
            .then(({ data }: { data: IToken; }) => {
                tokenAutorizador = data.access_token!;
                return configuracaoDescontoService.getConfiguracaoDescontoAutorizador(username, tokenAutorizador, estabelecimento.uuid);
            })
            .then(({ data }: { data: ConfiguracaoDescontoTO; }) => {
                dispatch(addPedidoDesconto({
                    itemDesconto: {
                        uuidAutorizador: data.uuid,
                        percentualDesconto: descontoSolicitadoRef.current!,
                        tipoDesconto: tipoDesconto,
                        valorDesconto: valorDesconto
                    }
                }));
                dispatch(setConfiguracaoDescontoAutorizador({
                    token: tokenAutorizador,
                    configuracaoDesconto: {
                        uuid: data.uuid,
                        usuario: data.usuario,
                        descontoMaximoItem: descontoMaximoItemAutorizador ?? data.descontoMaximoItem,
                        descontoMaximoPedido: descontoSolicitadoRef.current ?? descontoMaximoPedidoAutorizador,
                        autorizaDesconto: data.autorizaDesconto,
                    }
                }));
                setStateLocal(prevState => ({ ...prevState, showLoading: false, showModalDescontoItem: false }));
                addSuccess({ id: "desconto-aplicado-com-sucesso", message: "Desconto aplicado", closeable: true, persist: false, hideDuration: 2000 });
            })
            .catch((err) => {
                if (err.response.data.description === "SemConfiguracaoDesconto") {
                    throwErrorMessage(err)
                } else {
                    addError({ id: "usuario_senha_invalido", message: "Usuário ou senha inválidos", closeable: true, persist: false });
                }
                setStateLocal(prevState => ({ ...prevState, showLoading: false }));
            });
        //eslint-disable-next-line;
    };

    const getTotalValue = () => {
        if (props.isVisualizar) return 0;
        if (!isTelaValePresente()) {
            return roundToTwoDecimalPlaces(
                roundHalfToEven(valorTotalLiquido - valorTotalBrindes - valorTotalLiquidoTroca - valorTableRows, 2)
            );
        }
        return roundHalfToEven((valePresente.valePresente?.valor ?? 0) - faltaReceber, 2);
    };

    const closeAutorizaDesconto = useCallback(() => {
        dispatch(resetDescontoFooter());
        setStateLocal(prevState => ({ ...prevState, showModalDescontoNaoAutorizado: false, showModalDescontoItem: false }));
        //eslint-disable-next-line
    }, []);

    const screenVariables = {
        'registra-venda': {
            btnRed: {
                id: 'cancelar-btn',
                icon: 'fa-xmark',
                label: 'Cancelar',
            },
            btnGreen: {
                id: 'avancar-btn',
                icon: 'fa-money-bill-1-wave',
                label: 'Avançar',
            },
            labelTotalPagar: 'Total à pagar (F9)'
        },
        'pre-venda': {
            btnRed: {
                id: 'cancelar-btn',
                icon: 'fa-xmark',
                label: 'Cancelar',
            },
            btnGreen: {
                id: 'avancar-btn',
                icon: 'fa-money-bill-1-wave',
                label: 'Avançar',
            },
            labelTotalPagar: 'Total à pagar (F9)'
        },
        'pagamento': {
            btnRed: {
                id: 'voltar-btn',
                icon: 'fa-arrow-left',
                label: 'Voltar',
            },
            btnGreen: {
                id: 'finalizar-btn',
                icon: 'fa-handshake',
                label: isLayoutXs() ? 'Finalizar' : 'Finalizar Venda',
            },
            labelTotalPagar: 'Total à pagar'
        },
        'vale-presente': {
            btnRed: {
                id: 'cancelar-btn',
                icon: 'fa-xmark',
                label: 'Cancelar',
            },
            btnGreen: {
                id: 'confirmar-btn',
                icon: 'fa-thumbs-up',
                label: 'Confirmar',
            },
            labelTotalPagar: 'Total pago'
        }
    };

    const getScreenVariables = (): typeof screenVariables[keyof typeof screenVariables] => {
        return (screenVariables as any)[location.pathname.replace('/', '')];
    };

    const resetAll = useCallback(() => {
        dispatch(resetDesconto());
        dispatch(resetState());
        dispatch(resetRows());
        dispatch(resetPagamento());
        dispatch(resetChavesReferenciadas());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isTelaPreVenda() && finalizarPreVendaRef.current) {
            finalizarPreVendaRef.current = false;
            dispatch(changeLinearFinalizaVenda({ progress: 50 }));
            setTimeout(() => {
                registraVendaService.salvarVenda()
                    .then(({ data }) => {
                        dispatch(changeLinearFinalizaVenda({ progress: 100 }));
                        setStateLocalPreVenda(prevState => ({
                            ...prevState,
                            show: true,
                            cliente: data.cliente?.descritivo?.split(" - ")[1] ?? "CONSUMIDOR",
                            numero: data.numero,
                            vendedor: data.vendedorPrincipal?.descritivo?.split(" - ")[1] ?? "",
                            valorTotalLiquido: currencyOf(data.valorTotalLiquido),
                            emissao: data.emissao,
                        }));
                    }).catch(() => {
                        addError({
                            message: 'Ocorreu um erro inesperado. Se o erro persistir, entre em contato com o suporte',
                            persist: true,
                            severity: 'error',
                            preventDuplicate: false,
                            closeable: true,
                        });
                    }).finally(() => {
                        setTimeout(() => {
                            dispatch(loadingWithOpt({
                                loading: false,
                                tipo: Tipo.CIRCULAR,
                                message: "Salvando pedido",
                                progress: 0,
                            }));
                            resetAll();
                        }, 1000);
                    });
            }, 500);
        }
        //eslint-disable-next-line
    }, [tableRows]);

    const onFinalizarPreVenda = () => {
        if (!pedido.itens.length) {
            return addError({ id: 'erro-semproduto-pre-venda', message: 'Selecione ao menos um produto para continuar', closeable: true, preventDuplicate: true });
        }
        dispatch(loadingWithOpt({
            loading: true,
            tipo: Tipo.LINEAR,
            message: "Salvando pedido",
            progress: 0,
        }));
        pagamentoService.getFormasPagamento().then(({ data }) => {
            dispatch(addRow({
                name: 'OUTROS',
                descritivoFormaPagamento: 'Outros',
                formaPagamento: {
                    descritivo: 'OUTROS DEVOLUÇÃO',
                    uuid: getFormaPagamentoAbstracModel('OUTROS', data).uuid,
                },
                infoAdicional: {
                    descritivo: 'VENDA',
                    numeroParcelas: 1,
                    tipo: TipoInformacaoAdicional.OUTROS,
                },
                valor: valorTotalLiquido,
                isEditavel: true,
                isPago: true
            }));
            finalizarPreVendaRef.current = true;
        }).catch(() => {
            addError({
                message: 'Ocorreu um erro inesperado. Se o erro persistir, entre em contato com o suporte',
                persist: true,
                severity: 'error',
                preventDuplicate: false,
                closeable: true,
            });
        });
    };

    const handleApplyAnimationBtn = (troca: boolean, animation: 'shrink' | 'grow', duration: number) => {
        const map = {
            vale: 'vale-presente-btn',
            troca: 'troca-referenciada-btn',
        };
        const button = document.getElementById(map[troca ? 'troca' : 'vale']);
        button?.classList.add(animation);
        setTimeout(() => {
            button?.classList.remove(animation);
        }, duration);
    };

    const onSwitchOptions = () => {
        const troca = isTrocaVisible();
        const duration = 250;

        handleApplyAnimationBtn(troca, 'shrink', duration);
        setTimeout(() => {
            props.onSwitchOptions?.();
            setTimeout(() => {
                handleApplyAnimationBtn(!troca, 'grow', duration);
            },);
        }, duration);
    };

    return (
        <>
            <Box
                sx={{
                    gridColumn: '1 / -1'
                }}
            >
                <Grid container spacing={2} sx={{ textAlign: "center" }}>

                    {(isTelaRegistraVenda() || isTelaPreVenda()) &&
                        <Grid id="footer_totalizador_grid" item={true} xs={12} sm={6} md={itensTroca.length > 0 ? 3 : 4} lg={itensTroca.length > 0 ? 3 : 4}>
                            <Card id="footer_totalizador_card" sx={{ borderRadius: 5, paddingLeft: '10px', paddingRight: '10px' }} className={`card-totalizador-pedido ${props.isVisualizar && 'isVisualizar'}`}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '50%' }}>
                                    <h4 id="titulo_totalizador" className="label-totalizador" style={{ textAlign: 'left' }}>Quantidade da venda:</h4>
                                    <h2 id="valor_totalizador" className="valor-totalizador" style={{ textAlign: 'center', minWidth: '100px', color: props.isVisualizar ? '#9F9F9F' : "" }}>{quantidadeTotal}</h2>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '50%' }}>
                                    <h4 id="titulo_totalizador" className="label-totalizador" style={{ textAlign: 'left' }}>Total da venda:</h4>
                                    <h2 id="valor_totalizador" className="valor-totalizador" style={{ minWidth: '150px', textAlign: 'left', color: props.isVisualizar ? '#9F9F9F' : "" }}>{currencyOf(valorTotalBruto - valorTotalBrindes)}</h2>
                                </Box>
                            </Card>
                        </Grid>
                    }
                    {(isTelaPagamento() || isTelaValePresente()) && getShowTrocoFooter() &&
                        <Grid item={true} xs={12} sm={6} md={itensTroca.length > 0 ? 3 : 4} lg={itensTroca.length > 0 ? 3 : 4}>
                            <Card
                                sx={{
                                    borderRadius: 5,
                                    border: props.isVisualizar ? "3px solid #9F9F9F" : `2px solid var(--${faltaReceber >= 0 ? 'laranja' : 'error'})`,
                                }}
                                className="card-totalizador-pedido"
                            >
                                <h2
                                    id="valor_tolalizador_falta_receber"
                                    className="valor-totalizador"
                                    style={{
                                        color: props.isVisualizar ? " #9F9F9F" : faltaReceber > 0 ? '#8d00d1' : faltaReceber === 0 ? '#df6e0b' : 'var(--error)'
                                    }}
                                >
                                    {currencyOf(faltaReceber === 0 ? 0 : faltaReceber)}
                                </h2>

                                <h4
                                    id="footer_falta_receber"
                                    className="label-totalizador"
                                >
                                    Falta à receber
                                </h4>
                            </Card>
                        </Grid>}

                    {(isTelaPagamento() || isTelaValePresente()) && troco?.valor && !troco?.tipoTrocoPagar &&
                        <Grid item={true} xs={12} sm={6} md={itensTroca.length > 0 ? 3 : 4} lg={itensTroca.length > 0 ? 3 : 4}
                            sx={{ cursor: 'pointer' }}
                            onClick={props.showModalTroco}>
                            <Card
                                sx={{
                                    borderRadius: 5,
                                    border: props.isVisualizar ? "3px solid #9F9F9F" : `2px solid var(--error)`,
                                }}
                                className="card-totalizador-pedido"
                            >
                                <h2
                                    id="valor_tolalizador_falta_receber"
                                    className="valor-totalizador"
                                    style={{
                                        color: props.isVisualizar ? " #9F9F9F" : 'var(--error)'
                                    }}
                                >
                                    {currencyOf(troco.valor)}
                                </h2>

                                <h4
                                    id="footer_falta_receber"
                                    className="label-totalizador"
                                >
                                    Troco à devolver
                                </h4>
                            </Card>
                        </Grid>}

                    {!isTelaValePresente() ?
                        <Grid item={true} xs={12} sm={6} md={itensTroca.length > 0 ? 3 : 4} lg={itensTroca.length > 0 ? 3 : 4}>
                            <Card sx={{ borderRadius: 5 }} className={`card-totalizador-pedido ${props.isVisualizar && 'isVisualizar'}`}>
                                <h2 className="valor-totalizador">
                                    <DescontoInput
                                        isVisualizar={props.isVisualizar}
                                        hotKey='F8'
                                        onBlur={onBlurDescontoPedido.bind(null, percentualDesconto)}
                                        onSaveTempDesconto={saveTempDesconto}
                                        style={{ width: '13rem', height: '1.4rem' }}
                                        item={{
                                            tipoDesconto,
                                            valorDesconto,
                                            percentualDesconto,
                                            valorTotalBruto,
                                            onChangeDesconto: onChangeDescontoFooter,
                                            uuid: randomUuid()
                                        }}
                                        inputProps={{ style: { textAlign: 'center', fontSize: '24px', color: tableRows.length === 0 ? "#df6e0b" : 'rgba(0,0,0,0.2)', fontWeight: 700, fontFamily: 'Work Sans, sans-serif' } }}
                                        iconButtonProps={{
                                            disabled: tableRows.length > 0 || ((percentualDesconto === 0 && valorDesconto === 0) && getTotalValue() <= 0),
                                            onToggleTipoDesconto: toggleTipoDescontoFooter,
                                            percentoProps: {
                                                color: tableRows.length === 0 ? "#df6e0b" : 'rgba(0,0,0,0.2)',
                                            },
                                            valorProps: {
                                                color: tableRows.length === 0 ? "#df6e0b" : 'rgba(0,0,0,0.2)',
                                            }
                                        }}
                                        origem='FOOTER'
                                    />

                                </h2>
                                <h4 id="footer_desconto_titulo" className={'label-totalizador'} style={{ color: props.isVisualizar ? "#9F9F9F" : tableRows.length > 0 ? 'rgba(0, 0,0,0.2)' : '' }}>Desconto (F8)</h4>
                            </Card>
                        </Grid>
                        :
                        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
                            <Card
                                sx={{
                                    borderRadius: 5,
                                    border: `2px solid var(--laranja)`,
                                }}
                                className="card-totalizador-pedido"
                            >
                                <h2
                                    id="valor-total-vale-presente"
                                    className="valor-totalizador"
                                    style={{
                                        color: '#df6e0b',
                                    }}
                                >
                                    {currencyOf(valePresente.valePresente?.valor ?? 0)}
                                </h2>

                                <h4
                                    id="footer-valor-total-vale-presente"
                                    className="label-totalizador"
                                >
                                    Vale presente
                                </h4>
                            </Card>
                        </Grid>}

                    {itensTroca.length > 0 && <Grid id="footer_desconto_grid" item={true} xs={12} sm={6} md={3} lg={3}>
                        <Card id="footer_totalizador_card" sx={{ borderRadius: 5, paddingLeft: '10px', paddingRight: '10px', }} className={`card-totalizador-pedido ${props.isVisualizar && "isVisualizar"}`}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '50%' }}>
                                <h4 id="titulo_totalizador" className="label-totalizador" style={{ textAlign: 'left' }}>Quantidade da troca:</h4>
                                <h2 id="valor_totalizador" className="valor-totalizador" style={{ textAlign: 'center', minWidth: '100px', color: props.isVisualizar ? "#9F9F9F" : '#8d00d1' }}>{quantidadeTotalTroca}</h2>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '50%' }}>
                                <h4 id="titulo_totalizador" className="label-totalizador" style={{ textAlign: 'left' }}>Total da troca:</h4>
                                <h2 id="valor_totalizador" className="valor-totalizador" style={{ minWidth: '150px', textAlign: 'left', color: props.isVisualizar ? "#9F9F9F" : '#8d00d1' }}>{currencyOf(valorTotalLiquidoTroca)}</h2>
                            </Box>
                        </Card>
                    </Grid>}

                    <Grid id="footer_total_grid" item={true} xs={12} sm={itensTroca.length > 0 ? 6 : 12} md={itensTroca.length > 0 ? 3 : 4} lg={itensTroca.length > 0 ? 3 : 4} >
                        <Card id="footer_total_card" sx={{ borderRadius: 5 }} className={`card-totalizador-pedido ${props.isVisualizar && 'isVisualizar'}`}>
                            <h2 className="valor-totalizador">
                                <NumericFormat
                                    id="footer_total_input"
                                    onFocus={onFocusTotalizador}
                                    disabled={getTotalValue() <= 0 || isTelaPagamento() || isTelaValePresente() || props.isVisualizar}
                                    style={{ height: '1.4rem', width: '13rem' }}
                                    decimalScale={2}
                                    inputRef={totalPedidoRef}
                                    fixedDecimalScale
                                    defaultValue={0}
                                    allowedDecimalSeparators={[',']}
                                    customInput={TextField}
                                    decimalSeparator=","
                                    prefix={'R$ '}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator="."
                                    variant="standard"
                                    inputProps={{ style: { textAlign: 'center', fontSize: '24px', color: '#df6e0b', fontWeight: 700, fontFamily: 'Work Sans, sans-serif' } }}
                                    value={getTotalValue()}
                                    isAllowed={({ floatValue }) => {
                                        if (!floatValue) { return true; }
                                        if (floatValue > 0) { return floatValue <= toFixed(valorTotalBruto - valorTotalLiquidoTroca); }
                                        return false;
                                    }}
                                    allowNegative={true}
                                    onValueChange={({ floatValue }, { source }) => onChangeValorLiquido(floatValue, source)}
                                />

                            </h2>
                            <h4 id="footer_total_titulo" className="label-totalizador" style={{ color: props.isVisualizar ? "#9F9F9F" : tableRows.length > 0 ? 'rgba(0, 0,0,0.2)' : '' }}>{
                                getScreenVariables().labelTotalPagar
                            }</h4>
                        </Card>
                    </Grid >
                </Grid >
            </Box >

            <Box
                sx={{
                    gridColumn: '1 / -1',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <CustomButton id={getScreenVariables().btnRed.id}
                    disabled={(!quantidadeTotal && !itens.every(e => e.isBrinde)) && !isTelaValePresente()}
                    onClick={props.onClickCancelar}
                    variant="contained"
                    color="error"
                    startIcon={
                        <Icon
                            className={`fa-solid ${getScreenVariables().btnRed.icon}`}
                        />}
                >
                    {getScreenVariables().btnRed.label}
                </CustomButton>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    maxHeight: '50px',
                    position: 'relative',
                    gap: '10px'
                }}>
                    {props.brinde &&
                        <CustomButton
                            id='brinde-btn'
                            variant="contained"
                            sx={{
                                color: '#FFF',
                                backgroundColor: 'var(--laranja)',
                                '&:hover': { backgroundColor: 'var(--laranja);' },
                            }}
                            startIcon={<Icon className='fa-solid fa-gifts' sx={{ width: '25px' }} />}
                            animated
                            containerStyle={{
                                boxShadow: "2px 2px 10px rgba(0,0,0,0.35)"
                            }}
                            onClick={props.onClickBrinde}
                        >
                            Brinde ativo
                        </CustomButton>
                    }

                    {!isTelaPreVenda() && [
                        <>
                            {
                                !isTrocaVisible() && <Box key="vale-presente-btn" position={'relative'}>
                                    {stateLocal.showToolTipValePresente &&
                                        <CustomToolTips
                                            id={`alt-escape-id`}
                                            position='absolute'
                                            bottom='50px'
                                            left='0'
                                            bgColor='#808080'
                                            color='#FFF'
                                            height='30px'
                                            width={'100%'}
                                            padding='10px 0'
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            borderRadius='10px'
                                            isVisible
                                            trueText='ALT + V'
                                            falseText='ALT + V'
                                            autoCapitalize={true}
                                        />
                                    }
                                    {(!props.isVisualizar && !isTelaPagamento() && !isTelaValePresente()) &&
                                        <CustomButton
                                            id='vale-presente-btn'
                                            onMouseEnter={() => { setStateLocal(prev => ({ ...prev, showToolTipValePresente: true })); }}
                                            onMouseLeave={() => { setStateLocal(prev => ({ ...prev, showToolTipValePresente: false })); }}
                                            onClick={() => setOpenStateValePresenteDialog(true)}
                                            variant="contained"
                                            disabled={!!state.pedido.itens.length || !!state.pedido.itensTroca.length}
                                            sx={{
                                                color: '#FFF',
                                                backgroundColor: 'rgb(75, 210, 75);',
                                                '&:hover': { backgroundColor: 'rgb(75, 180, 75);' },
                                            }}
                                            startIcon={<Icon className='fa-solid fa-gift' />}
                                        >
                                            Vale presente
                                        </CustomButton>
                                    }
                                </Box>
                            }

                            {isTrocaVisible() && <Box key="troca-referenciada-btn" position={'relative'}>
                                {(!props.isVisualizar && !isTelaPagamento()) &&
                                    <CustomButton
                                        id='troca-referenciada-btn'
                                        onClick={() => setOpenStateTrocaReferenciadaDialog(true)}
                                        variant="contained"
                                        disabled={!state.pedido.cliente || !state.pedido.itensTroca.length}
                                        sx={{
                                            color: '#FFF',
                                            backgroundColor: '#8d00d1',
                                            '&:hover': { backgroundColor: '#7100aa;' },
                                            zIndex: 100
                                        }}
                                        startIcon={<Icon sx={{ width: '24px', marginLeft: '-1px' }} className='fa-solid fa-link' />}
                                    >
                                        Referenciar nota
                                    </CustomButton>
                                }
                            </Box>}
                        </>,

                        <Box key="troca-btn" position={'relative'} zIndex={isTrocaVisible() ? 100 : 1}>
                            {stateLocal.showToolTipTroca &&
                                <CustomToolTips
                                    id={`alt-escape-id`}
                                    position='absolute'
                                    bottom='50px'
                                    left='0'
                                    bgColor='#808080'
                                    color='#FFF'
                                    height='30px'
                                    width={props.troca?.isVisible ? '130px' : '95px'}
                                    zIndex={100}
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    borderRadius='10px'
                                    isVisible={isTrocaVisible()}
                                    trueText='ESC'
                                    falseText='ALT + T'
                                    autoCapitalize={true}
                                />
                            }
                            {(!props.isVisualizar && !isTelaPagamento() && !isTelaValePresente()) &&
                                <CustomButton
                                    onMouseEnter={() => { setStateLocal(prev => ({ ...prev, showToolTipTroca: true })); }}
                                    onMouseLeave={() => { setStateLocal(prev => ({ ...prev, showToolTipTroca: false })); }}
                                    id='troca-venda-btn'
                                    onClick={onSwitchOptions} variant="contained"
                                    sx={{
                                        color: '#FFF',
                                        backgroundColor: 'rgb(75, 210, 75);',
                                        '&:hover': { backgroundColor: 'rgb(75, 180, 75);' },
                                    }}
                                    startIcon={<Icon className='fa-solid fa-retweet' />}
                                    animated={isTrocaVisible()}
                                >
                                    {isTrocaVisible() ? 'Confirmar' : 'Troca'}
                                </CustomButton>
                            }
                        </Box>
                    ]}
                    {!isTelaPreVenda() &&
                        <CustomButton
                            disabled={props.isVisualizar && isTelaPagamento()}
                            id={getScreenVariables().btnGreen.id}
                            onClick={props.onClickAvancar}
                            variant="contained"
                            color="success"
                            startIcon={
                                <Icon
                                    className={`fa-solid ${getScreenVariables().btnGreen.icon}`}
                                    sx={{ width: 'fit-content' }}
                                />
                            }>
                            {getScreenVariables().btnGreen.label}
                        </CustomButton>
                    }
                    {isTelaPreVenda() &&
                        <CustomButton
                            id="pre-venda-finalizar-button"
                            onClick={onFinalizarPreVenda}
                            variant="contained"
                            color="success"
                            startIcon={
                                <Icon
                                    className={`fa-solid ${getScreenVariables().btnGreen.icon}`}
                                    sx={{ width: 'fit-content' }}
                                />
                            }>
                            Finalizar pré-venda
                        </CustomButton>
                    }

                    {(props.floatingActionsProps && !props.isVisualizar) && <FloatingActions   {...props.floatingActionsProps} isVisualizar={props.isVisualizar ?? false} />}
                </Box>

                {stateLocal.showModalDescontoItem &&
                    <AutorizaDesconto
                        onClose={closeAutorizaDesconto}
                        descontoSolicitado={descontoSolicitadoRef}
                        onAutorizaDesconto={handleAutorizaDesconto}
                        descontoMaximo={DescontoMaximo.PEDIDO}

                    />
                }
                {stateLocal.showLoading &&
                    <CustomLoading />
                }
                {stateLocal.showModalDescontoNaoAutorizado &&
                    <AutorizaDescontoItemError
                        descontoSolicitado={descontoSolicitadoRef}
                        closeModalDescontoItemError={closeAutorizaDesconto}
                        descontoMaximo={DescontoMaximo.PEDIDO}
                    />
                }
                {stateLocalPreVenda.show &&
                    <PreVendaPdf
                        cliente={stateLocalPreVenda.cliente}
                        emissao={stateLocalPreVenda.emissao}
                        numero={stateLocalPreVenda.numero}
                        onClose={() => setStateLocalPreVenda({ ...initialStatePreVenda })}
                        valorTotalLiquido={stateLocalPreVenda.valorTotalLiquido}
                        vendedor={stateLocalPreVenda.vendedor}
                        key="pre-venda-comprovante-footer"
                    />
                }
            </Box >

            <ValePresenteDialog
                open={openStateValePresenteDialog}
                setOpen={setOpenStateValePresenteDialog}
            />

            <TrocaReferenciadaDialog
                open={openStateTrocaReferenciadaDialog}
                setOpen={setOpenStateTrocaReferenciadaDialog}
            />
        </>
    );

};

export default Footer;
