import { FileDownload } from "@mui/icons-material";
import { Icon, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useCallback, useState } from "react";
import { StatusNotaFiscal, TMenuItemRelatorioProps } from "../vendas.model";
import "./MenuItems.css";

const initialState = {
    showImprimir: false as boolean
};

const MenuItemFaturado = (props: TMenuItemRelatorioProps) => {
    const [stateLocal, setStateLocal] = useState(initialState);

    const getConditionExcluirNota = useCallback(() => {
        const current = props.itemRef.current;
        const statusNF = current?.statusNotaFiscal;
        return statusNF === StatusNotaFiscal.PENDENTE ||
            statusNF === StatusNotaFiscal.REJEITADA ||
            statusNF === StatusNotaFiscal.DENEGADA;
    }, [props.itemRef]);

    const isAutorizada = useCallback(() => {
        return props.itemRef.current?.statusNotaFiscal === "AUTORIZADA";
        //eslint-disable-next-line
    }, [props.itemRef]);

    const isEmProcessamento = useCallback(() => {
        return props.itemRef.current?.statusNotaFiscal === "EM PROCESSAMENTO";
        //eslint-disable-next-line
    }, [props.itemRef]);

    const isRejeitada = useCallback(() => {
        return props.itemRef.current?.statusNotaFiscal === "REJEITADA";
        //eslint-disable-next-line
    }, [props.itemRef]);

    const hasCrediario = useCallback(() => {
        return props.itemRef.current?.isCrediario;
        //eslint-disable-next-line
    }, []);

    return (
        <MenuList>
            {
                (!isEmProcessamento() && !isRejeitada()) && [

                    <MenuItem
                        key="menu-item-aberto-cancelar-pedido"
                        id="menu-item-aberto-cancelar-pedido"
                        onClick={props.onEditarVendedor}>
                        <ListItemIcon>
                            <Icon className="fa-solid fa-pen" sx={{ fontSize: "16px" }} />
                        </ListItemIcon>
                        <ListItemText>Editar vendedor</ListItemText>
                    </MenuItem>,

                    <MenuItem
                        key="menu-item-faturado-imprimir-dropdown"
                        id="menu-item-faturado-imprimir-dropdown"
                        onClick={() => setStateLocal(prevState => ({ ...prevState, showImprimir: !prevState.showImprimir }))}
                    >
                        <ListItemIcon>
                            <Icon className="fa-solid fa-print" sx={{ fontSize: "18px" }} />
                        </ListItemIcon>
                        <ListItemText>Imprimir</ListItemText>
                        <Icon
                            className={!stateLocal.showImprimir ? "fa-solid fa-angle-down" : "fa-solid fa-angle-up"}
                            sx={{ fontSize: "18px" }}
                        />
                    </MenuItem>,

                    <div key={'menu-items-container'} className={`menu-items-container ${stateLocal.showImprimir ? 'opened' : 'closed'}`}
                        id="dropdown-imprimir-menu-item-faturado"
                    >
                        <MenuItem
                            key="menu-item-faturado-imprimir-pedido"
                            id="menu-item-faturado-imprimir-pedido"
                            onClick={() => props.onImprimirPedido!(props.itemRef.current!.uuidPedido)}>
                            <ListItemIcon />
                            <ListItemText>Pedido</ListItemText>
                        </MenuItem>

                        <MenuItem
                            key="menu-item-faturado-imprimirA4"
                            id="menu-item-faturado-imprimirA4"
                            onClick={() => props.onImprimirPedidoA4!(props.itemRef.current!.uuidPedido)}>
                            <ListItemIcon />
                            <ListItemText>Pedido A4</ListItemText>
                        </MenuItem>

                        {hasCrediario() &&
                            <MenuItem
                                key="menu-item-faturado-imprimir-crediario"
                                id="menu-item-faturado-imprimir-crediario"
                                onClick={() => props.onImprimirCrediario!(props.itemRef.current!.uuidPedido, props.itemRef.current?.emissaoNota)}>
                                <ListItemIcon />
                                <ListItemText>Crediário</ListItemText>
                            </MenuItem>
                        }

                        {isAutorizada() &&
                            <MenuItem
                                key="menu-item-faturado-imprimir-dfe"
                                id="menu-item-faturado-imprimir-dfe"
                                onClick={() => props.onImprimirDFE!(props.itemRef.current!.uuidNotaFiscal)}>
                                <ListItemIcon />
                                <ListItemText>DF-e</ListItemText>
                            </MenuItem>
                        }
                    </div>
                ]

            }

            <MenuItem
                key="menu-item-aberto-visualizar-pedido"
                id="menu-item-aberto-visualizar-pedido"
                onClick={() => props.onVisualizar(props.itemRef.current!.uuidPedido, props.itemRef.current!.numeroPedido)}
            >
                <ListItemIcon>
                    <Icon className="fa-solid fa-eye" sx={{ fontSize: "18px", width: '20px' }} />
                </ListItemIcon>
                <ListItemText>Visualizar</ListItemText>
            </MenuItem>

            {isEmProcessamento() &&
                <MenuItem
                    key="menu-item-atualizar-situacao"
                    id="menu-item-atualizar-situacao"
                    onClick={() => props.onAtualizarSituacao!(props.itemRef.current!.chaveAcesso)}
                >
                    <ListItemIcon>
                        <Icon className="fa-solid fa-rotate" sx={{ fontSize: "18px", width: '20px' }} />
                    </ListItemIcon>
                    <ListItemText>Atualizar situação</ListItemText>
                </MenuItem>
            }

            {
                isAutorizada() &&
                <MenuItem
                    key="menu-item-faturado-baixar-xml"
                    id="menu-item-faturado-baixar-xml"
                    onClick={() => { props.onDownloadXML!(props.itemRef.current?.chaveAcesso ?? ''); }}>
                    <ListItemIcon>
                        <FileDownload fontSize="small" />
                    </ListItemIcon>

                    <ListItemText>Baixar Xml</ListItemText>
                </MenuItem>
            }


            {
                isAutorizada() &&
                <MenuItem
                    key="menu-item-faturado-cancelar-dfe"
                    id="menu-item-faturado-cancelar-dfe"
                    onClick={props.onOpenCancelarDFe}>
                    <ListItemIcon>
                        <Icon className="fa-solid fa-xmark" sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Cancelar DF-e</ListItemText>
                </MenuItem>
            }


            {/* {
                isRejeitada() &&
                <MenuItem
                    key="menu-item-retransmitir"
                    id="menu-item-retransmitir"
                    onClick={undefined}
                    disabled
                >
                    <ListItemIcon>
                        <Icon className="fa-solid fa-xmark" sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Retransmitir</ListItemText>
                </MenuItem>
            } */}

            {getConditionExcluirNota() &&
                <MenuItem
                    key="menu-item-faturado-cancelar-dfe"
                    id="menu-item-faturado-cancelar-dfe"
                    onClick={() => props.onExcluirNota!(props.itemRef.current?.chaveAcesso ?? '')}>
                    <ListItemIcon>
                        <Icon className="fa-solid fa-xmark" sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Excluir nota</ListItemText>
                </MenuItem>
            }

        </MenuList>
    );
};

export default MenuItemFaturado;
