import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Typography } from "@mui/material";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { IAbstractModel } from "model/abstract.model";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ICaixaSlice, resetCaixa, setCaixa } from "redux/features/caixaSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { currencyOf } from "utils/util";
import ContaAutoComplete from "./ContaAutoComplete";
import DataAbertura from "./DataAbertura";
import DataFechamento from "./DataFechamento";
import DialogUser from "./DialogUser";
import DinheiroCaixa from "./DinheiroCaixa";
import MotivoObservacao from "./MotivoObservacao";
import SelectCaixa from "./SelectCaixa";
import CaixaTransferenciaService, { FlowTransferenciaCaixaPayload } from "./caixa-transferencia.service";
import { ICaixasDisponivel, IModalCaixa, Operacao, TipoDialog } from "./caixa.model";
import CaixaService from "./caixa.service";
import RelatorioCaixa from "./relatorio-caixa/RelatorioCaixa";
import { StateRelatorioCaixa, initialSateRelatorioCaixa } from "./relatorio-caixa/relatorio-caixa.model";
import useCatchApiError from "hooks/useCatchApiError";


const initialLoading = {
    loading: false
};

const DialogCaixa = (props: IModalCaixa) => {

    const initialState: ICaixaSlice = {
        caixa: null,
        caixaDestino: null,
        saldo: props.title === TipoDialog.TRANSFERENCIA ? 0.01 : 0,
        contaSelecionada: null,
        motivoObservacao: props.title === TipoDialog.TRANSFERENCIA ? 'Transferência entre caixas' : '',
        dataAbertura: new Date().toString(),
        dataFechamento: new Date().toString(),
        dinheiroError: false,
    };

    const [stateLocal, setStateLocal] = useState(initialState);
    const [stateLoading, setStateLoading] = useState(initialLoading);
    const userInfo = useAppSelector(e => e.token.USER_INFO);
    const dispatch = useAppDispatch();
    const { saldo, caixaAberto, dataAbertura, caixa } = useAppSelector(e => e.caixa.caixaAtual);
    const messageRef = useRef('');
    const service = CaixaService();
    const transferenciaService = CaixaTransferenciaService();
    const { addError, addSuccess, closeAllSnacks } = useSnackbarCustom();
    const { throwErrorMessage } = useCatchApiError()

    useEffect(() => {
        return () => {
            setStateLocal(initialState);
        };
        //eslint-disable-next-line
    }, [props.open]);

    const abrirCaixa = () => {
        closeAllSnacks()
        setStateLoading(prevState => ({ ...prevState, loading: true }));
        service.movimentoCaixa({
            saldo: stateLocal.saldo,
            caixaAberto: caixaAberto,
            planoConta: stateLocal.contaSelecionada,
            caixa: {
                uuid: stateLocal.caixa!.uuid,
                descritivo: stateLocal.caixa!.descritivo,
                saldo: stateLocal.caixa!.saldo
            },
            observacao: stateLocal.motivoObservacao,
            tipoOperacao: Operacao.ABERTURA
        }).then(({ data }) => {
            dispatch(setCaixa({
                caixa: {
                    uuid: data.caixa.uuid,
                    descritivo: data.caixa.descritivo,
                    saldo: data.caixa.saldo
                },
                saldo: data.saldo,
                contaSelecionada: data.planoConta,
                caixaAberto: data.caixaAberto,
                dataAbertura: data.dataAbertura
            }));
            props.onCancel!();
        }).catch(error => {
            throwErrorMessage(error)
        }).finally(() => {
            setStateLoading(prevState => ({ ...prevState, loading: false }));
        });
    };

    const reforcarCaixa = () => {
        closeAllSnacks()
        setStateLoading(prevState => ({ ...prevState, loading: true }));
        service.movimentoCaixa({
            saldo: stateLocal.saldo,
            caixaAberto: caixaAberto,
            planoConta: {
                descritivo: stateLocal.contaSelecionada!.descritivo,
                uuid: stateLocal.contaSelecionada!.uuid
            },
            caixa: {
                uuid: stateLocal.caixa!.uuid,
                descritivo: stateLocal.caixa!.descritivo,
                saldo: stateLocal.caixa!.saldo
            },
            observacao: stateLocal.motivoObservacao,
            tipoOperacao: Operacao.REFORCAR
        }).then(({ data }) => {
            dispatch(setCaixa({
                caixa: {
                    uuid: data.caixa.uuid,
                    descritivo: data.caixa.descritivo,
                    saldo: data.caixa.saldo
                },
                saldo: data.saldo,
                contaSelecionada: data.planoConta,
                dataAbertura,
                caixaAberto
            }));
            props.onCancel!();
        }).catch(error => {
            throwErrorMessage(error)
        }).finally(() => {
            setStateLoading(prevState => ({ ...prevState, loading: false }));
        });
    };

    const sangrarCaixa = () => {
        closeAllSnacks()
        setStateLoading(prevState => ({ ...prevState, loading: true }));
        service.movimentoCaixa({
            saldo: stateLocal.saldo,
            caixaAberto,
            planoConta: {
                descritivo: stateLocal.contaSelecionada!.descritivo,
                uuid: stateLocal.contaSelecionada!.uuid
            },
            caixa: {
                uuid: stateLocal.caixa!.uuid,
                descritivo: stateLocal.caixa!.descritivo,
                saldo: stateLocal.caixa!.saldo
            },
            observacao: stateLocal.motivoObservacao,
            tipoOperacao: Operacao.SANGRAR
        }).then(({ data }) => {
            dispatch(setCaixa({
                caixa: {
                    uuid: data.caixa.uuid,
                    descritivo: data.caixa.descritivo,
                    saldo: data.caixa.saldo
                },
                saldo: data.saldo,
                contaSelecionada: data.planoConta,
                dataAbertura,
                caixaAberto
            }));
            props.onCancel!();
        }).catch(error => {
            throwErrorMessage(error)
        }).finally(() => {
            setStateLoading(prevState => ({ ...prevState, loading: false }));
        });
    };

    const fecharCaixa = () => {
        closeAllSnacks()
        setStateLoading(prevState => ({ ...prevState, loading: true }));
        service.movimentoCaixa({
            saldo: stateLocal.saldo,
            caixaAberto,
            planoConta: null,
            caixa: {
                uuid: caixa!.uuid,
                descritivo: caixa!.descritivo,
                saldo: caixa!.saldo
            },
            observacao: stateLocal.motivoObservacao,
            tipoOperacao: Operacao.FECHAMENTO
        }).then(({ data }) => {
            props.onCancel!();
        }).catch(error => {
            throwErrorMessage(error)
        }).finally(() => {
            setStateLoading(prevState => ({ ...prevState, loading: false }));
            dispatch(resetCaixa());
        });
    };

    const transferir = () => {
        closeAllSnacks()
        if (!stateLocal.caixaDestino) {
            return addError({ message: 'Selecione o caixa de destino para prosseguir.' });
        }

        setStateLoading(prevState => ({ ...prevState, loading: true }));
        const { caixaDestino, saldo, motivoObservacao } = stateLocal;
        const payload: FlowTransferenciaCaixaPayload = {
            caixaDestino: caixaDestino?.uuid,
            valor: saldo,
            descricao: motivoObservacao,
        };

        transferenciaService.transferir(payload)
            .then(({ data }) => {
                dispatch(setCaixa({
                    caixa: {
                        uuid: data.caixa.uuid,
                        descritivo: data.caixa.descritivo,
                        saldo: data.caixa.saldo
                    },
                    saldo: data.saldo,
                    contaSelecionada: data.planoConta,
                    caixaAberto: data.caixaAberto,
                    dataAbertura: data.dataAbertura
                }));
                props.onCancel!();
                addSuccess({ message: 'Transferência realizada com sucesso.', closeable: true, id: 'trasferencia-sucesso' });
            })
            .catch(error => {
                throwErrorMessage(error)
            })
            .finally(() => {
                setStateLoading(prevState => ({ ...prevState, loading: false }));
            });
    };

    const onConfirm = (event: FormEvent) => {
        event.preventDefault();
        if (props.title === TipoDialog.ABERTURA && props.open) {
            abrirCaixa();
        }
        else if (props.open && props.title === TipoDialog.REFORCAR) {
            reforcarCaixa();
        }
        else if (props.open && props.title === TipoDialog.SANGRAR) {
            sangrarCaixa();
        }
        else if (props.open && props.title === TipoDialog.FECHAMENTO) {
            fecharCaixa();
        }
        else {
            transferir();
        }
    };

    const [relatoriCaixaState, setRelatoriCaixaState] = useState<StateRelatorioCaixa>(initialSateRelatorioCaixa);

    const handleImprimir = () => {
        setRelatoriCaixaState(prev => ({ ...prev, open: true, loading: true }));
        service.getRelatorioCaixa()
            .then(({ data }) => {
                setRelatoriCaixaState(prev => ({ ...prev, info: data, loading: false }));
            })
            .catch(error => {
                setRelatoriCaixaState(prev => ({ ...prev, open: false }));
                addError({ message: 'Não foi possível gerar o relatório', closeable: true, hideDuration: 7000 });
            });
    };

    const setSelecionaCaixa = (caixa: ICaixasDisponivel) => {
        setStateLocal(prevState => ({ ...prevState, caixa: caixa }));
    };

    const setSelecionaCaixaDestino = (caixa: ICaixasDisponivel) => {
        setStateLocal(prevState => ({ ...prevState, caixaDestino: caixa }));
    };

    const setSaldo = (saldo: number) => {
        setStateLocal(prevState => ({ ...prevState, saldo }));
    };

    const setContaSelecionada = (contaSelecionada: IAbstractModel | null) => {
        setStateLocal(prevState => ({ ...prevState, contaSelecionada }));
    };

    const setMotivoObservacao = (motivoObservacao: string) => {
        setStateLocal(prevState => ({ ...prevState, motivoObservacao }));
    };

    const setDinheiroError = (bool: boolean, message: string) => {
        messageRef.current = message;
        setStateLocal(prevState => ({ ...prevState, dinheiroError: bool }));
    };

    const setDataFechamento = (dataFechamento: string) => {
        setStateLocal(prevState => ({ ...prevState, dataFechamento }));
    };

    const handleDisabledConfirmar = () => {
        if (stateLoading.loading) {
            return true;
        }
        if (props.title === TipoDialog.ABERTURA && props.open) {
            return (!stateLocal.caixa);
        }
        //else if (props.open && stateLocal.saldoCaixa)
        else if (props.open && (props.title === TipoDialog.REFORCAR || props.title === TipoDialog.SANGRAR)) {
            return (!stateLocal.caixa || !stateLocal.saldo || !stateLocal.contaSelecionada || stateLocal.dinheiroError);
        }
        else if (props.title === TipoDialog.FECHAMENTO && props.open) {
            return (!stateLocal.caixa || (stateLocal.saldo > saldo) || stateLocal.dinheiroError);
        }
        else if (props.title === TipoDialog.TRANSFERENCIA && props.open) {
            return (!stateLocal.caixa || (stateLocal.saldo > saldo) || !stateLocal.caixaDestino || stateLocal.dinheiroError);
        }
    };

    return (
        <CustomDialog open={props.open}
            onClose={props.onCancel}
            transitionDuration={0}
        >
            <form onSubmit={onConfirm} style={{ minWidth: '600px' }} >
                <DialogTitle id="alert-dialog-title" sx={{
                    fontSize: '45px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}  >
                    {props.icon}
                    {props.title}
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'flex-start' }}>
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        {stateLocal.caixa &&
                            <Typography sx={{ color: '#666666' }}>Saldo em caixa {currencyOf(saldo > 0 ? saldo : stateLocal.caixa!.saldo)}</Typography>
                        }
                    </div>
                    <SelectCaixa
                        caixaTitle={props.caixaTitle}
                        caixa={stateLocal.caixa}
                        onSetSelecionaCaixa={setSelecionaCaixa}
                        open={props.open}
                        title={props.title}
                        shouldGetTodosCaixas={false}
                    />
                    {props.title === TipoDialog.TRANSFERENCIA &&
                        <SelectCaixa
                            caixaTitle={"Destino *"}
                            caixa={stateLocal.caixaDestino ?? null}
                            onSetSelecionaCaixa={setSelecionaCaixaDestino}
                            open={props.open}
                            title={props.title}
                            shouldGetTodosCaixas
                        />
                    }
                    <DinheiroCaixa
                        saldo={stateLocal.saldo}
                        onSetSaldo={setSaldo}
                        title={props.title}
                        open={props.open}
                        dinheiroError={stateLocal.dinheiroError}
                        onSetDinheiroError={setDinheiroError}
                        message={messageRef.current}
                        min={props.title === TipoDialog.TRANSFERENCIA ? 0.01 : 0}
                    />
                    {(props.title !== TipoDialog.FECHAMENTO
                        && props.title !== TipoDialog.ABERTURA
                        && props.title !== TipoDialog.TRANSFERENCIA)
                        && <>
                            <ContaAutoComplete
                                onsetContaSelecionada={setContaSelecionada}
                                contaSelecionada={stateLocal.contaSelecionada}
                                title={props.title}
                            />
                            <MotivoObservacao
                                title="Motivo"
                                motivoObservacao={stateLocal.motivoObservacao}
                                onSetMotivoObservacao={setMotivoObservacao}
                            />
                        </>
                    }
                    {[TipoDialog.FECHAMENTO.toString(), TipoDialog.TRANSFERENCIA.toString()].includes(props.title) &&
                        <MotivoObservacao
                            title="Observação"
                            motivoObservacao={stateLocal.motivoObservacao}
                            onSetMotivoObservacao={setMotivoObservacao}
                        />
                    }

                    {props.title !== TipoDialog.TRANSFERENCIA &&
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', columnGap: '1rem', rowGap: '1rem' }} >
                            <DialogUser user={userInfo?.nome} style={{
                                gridColumnStart: 1,
                                gridColumnEnd: props.dataFechamento ? -1 : 2,
                                width: props.dataFechamento ? 'calc(50% - 0.5rem)' : '100%' // Ajuste Técnico necessario
                            }} />
                            <DataAbertura date={!!dataAbertura ? dataAbertura : stateLocal.dataAbertura} />
                            {props.dataFechamento &&
                                <DataFechamento date={stateLocal.dataFechamento!} onSetDataFechamento={setDataFechamento} />
                            }
                        </Box>
                    }
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                        marginTop: '20px'
                    }}>
                    <CustomButton onClick={props.onCancel} id="cancel_button" variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Cancelar
                    </CustomButton>
                    {props.title === TipoDialog.FECHAMENTO &&
                        <IconButton onClick={handleImprimir}>
                            <Icon className="fa-solid fa-print" sx={{ fontSize: "32px" }} />
                        </IconButton>
                    }
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '4px',
                        position: 'relative',
                        marginTop: { xs: '50px', sm: 0 }
                    }}>
                        <CustomButton disabled={handleDisabledConfirmar()} id="confirm_button" type="submit" variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                            Confirmar
                        </CustomButton>
                        {stateLoading.loading && (
                            <CircularProgress
                                size={24}
                                color='warning'
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </form>

            <RelatorioCaixa
                state={relatoriCaixaState}
                setState={setRelatoriCaixaState}
            />
        </CustomDialog >
    );
};

export default DialogCaixa;
