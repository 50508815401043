import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useContext } from "react";
import DialogContext from './confirm-dialog-context';


export default function MessageDialog() {
  const confirmCtx = useContext(DialogContext);
  const { title, message, onConfirm, onCancel, error } = confirmCtx.config || {};

  const handleCancel = () => {
    onCancel && onCancel();
    confirmCtx.setOpen(false);
  };

  const handleOk = () => {
    confirmCtx.setOpen(false);
    onConfirm();
  };

  const onClose = () => {
    confirmCtx.setOpen(false);
  };

  return (
    (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 'calc(100vh - 200px)', maxWidth: 550 } }}
        maxWidth="sm"
        onClose={onClose}
        open={confirmCtx.open}
      >
        <DialogTitle sx={{ textAlign: 'center', fontSize: '24px', fontWeight: '600' }}>
          <i style={{ paddingRight: '10px', }} className="fa-solid fa-triangle-exclamation"></i>
          {title}
        </DialogTitle>

        <DialogContent sx={{ textAlign: error ? 'center' : 'justify' }}>
          {message}
        </DialogContent>
        <DialogActions>
          {!error &&
            <Button autoFocus onClick={handleCancel}>
              Cancelar
            </Button>
          }
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    )
  );
}
