import CampaignIcon from '@mui/icons-material/Campaign';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import { EventoNotaFiscalEmitida } from 'pages/pagamento/pagamento.model';
import React from 'react';
import { FlowEventoNotaEmitidaTypeDTO, isQualquerTipoSaidaEmProcessamento, possuiRejeicao } from './evento-nota-fiscal.model';

export type StateEventoNotaDialog = {
    open: boolean;
    info: {
        eventoType: FlowEventoNotaEmitidaTypeDTO;
        eventoEntrada?: EventoNotaFiscalEmitida;
        eventoSaida?: EventoNotaFiscalEmitida;
    };
};
type Props = {
    state: StateEventoNotaDialog;
    setState: React.Dispatch<React.SetStateAction<StateEventoNotaDialog>>;
    onClose: () => void;
};

const EventoNotaFiscal = (props: Props) => {
    const { eventoType, eventoEntrada, eventoSaida } = props.state.info;
    const eventoToShow = eventoSaida ?? eventoEntrada!;
    const onClose = () => {
        props.onClose();
        props.setState(prev => ({ ...prev, open: false }));
    };

    const getTitle = () => {
        if (possuiRejeicao(eventoType)) {
            return `Venda concluída com rejeição do documento fiscal de ${!!eventoEntrada ? 'entrada' : 'saída'}`;
        }

        if (isQualquerTipoSaidaEmProcessamento(eventoType)) {
            return 'Venda concluída com documento fiscal de saída em processamento';
        }
    };
    if (!eventoToShow) return <></>;
    else
        return (
            <CustomDialog
                id="desconto-fixo-dialog"
                open={props.state.open}
                fullWidth
                maxWidth={'lg'}
                onClose={onClose}
            >

                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: possuiRejeicao(eventoType) ? '#e06665' : '#405C80',
                        gap: '15px',
                        borderRadius: '15px',
                        marginBottom: '30px'
                    }}
                >
                    <CampaignIcon sx={{ fontSize: '64px', color: '#fff' }} />
                    <Typography
                        component={'h1'}
                        sx={{
                            fontSize: 'clamp(20px, 3vw, 32px)',
                        }}
                        textAlign={'center'}
                        color={'#fff'}
                        fontWeight={700}
                    >
                        {getTitle()}
                    </Typography>
                </DialogTitle>

                <DialogContent sx={{ height: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'flex-start' }}>
                    <Typography fontWeight={700} color={'#555'}>Evento: {eventoToShow.eventoDescricao}</Typography>
                    <Typography>Serie: {eventoToShow.serie}</Typography>
                    <Typography>Número: {eventoToShow.numero}</Typography>
                    <Typography>Data de emissão: {new Date(eventoToShow.emissao).toLocaleString('pt-BR')}</Typography>
                    <Typography>Situação na SEFAZ: {eventoToShow.situacaoSefaz}</Typography>
                    <Typography>Chave de acesso: {eventoToShow.chaveAcesso}</Typography>
                </DialogContent>
            </CustomDialog>
        );
};

export default EventoNotaFiscal;
